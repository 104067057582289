import {gql} from 'apollo-angular'
import {Injectable} from '@angular/core'
import * as Apollo from 'apollo-angular'

export type Maybe<T> = T | undefined
export type InputMaybe<T> = T | undefined
export type Exact<T extends Record<string, unknown>> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {[_ in K]?: never}
export type Incremental<T> = T | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
  /** Represents `true` or `false` values. */
  BooleanType: {input: any; output: any}
  CustomData: {input: any; output: any}
  /** A ISO 8601 compliant datetime value */
  DateTime: {input: any; output: any}
  /** Represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point). */
  FloatType: {input: any; output: any}
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  IntType: {input: any; output: any}
  ItemId: {input: any; output: any}
  JsonField: {input: any; output: any}
  MetaTagAttributes: {input: any; output: any}
  UploadId: {input: any; output: any}
}

export type AnnouncementBannerV2ModelModelFilter = {
  AND?: InputMaybe<InputMaybe<AnnouncementBannerV2ModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<AnnouncementBannerV2ModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  link?: InputMaybe<StringFilter>
  linkText?: InputMaybe<StringFilter>
  location?: InputMaybe<StringFilter>
  textMessage?: InputMaybe<StringFilter>
}

export enum AnnouncementBannerV2ModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkTextAsc = 'linkText_ASC',
  LinkTextDesc = 'linkText_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  TextMessageAsc = 'textMessage_ASC',
  TextMessageDesc = 'textMessage_DESC',
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecord = RecordInterface & {
  __typename?: 'AnnouncementBannerV2ModelRecord'
  _allLinkLocales?: Maybe<StringMultiLocaleField[]>
  _allLinkTextLocales?: Maybe<StringMultiLocaleField[]>
  _allTextMessageLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  link?: Maybe<Scalars['String']['output']>
  linkText?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  textMessage?: Maybe<Scalars['String']['output']>
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecord_AllLinkLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecord_AllLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecord_AllTextMessageLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecordLinkArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecordLinkTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Announcement Banner (announcement_banner_v2_model) */
export type AnnouncementBannerV2ModelRecordTextMessageArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type AnswerModelModelFilter = {
  AND?: InputMaybe<InputMaybe<AnswerModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<AnswerModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  label?: InputMaybe<StringFilter>
  requireAdditionalInfo?: InputMaybe<BooleanFilter>
  technicalId?: InputMaybe<StringFilter>
}

export enum AnswerModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RequireAdditionalInfoAsc = 'requireAdditionalInfo_ASC',
  RequireAdditionalInfoDesc = 'requireAdditionalInfo_DESC',
  TechnicalIdAsc = 'technicalId_ASC',
  TechnicalIdDesc = 'technicalId_DESC',
}

/** Record of type Answer (answer_model) */
export type AnswerModelRecord = RecordInterface & {
  __typename?: 'AnswerModelRecord'
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  label?: Maybe<Scalars['String']['output']>
  requireAdditionalInfo: Scalars['BooleanType']['output']
  technicalId?: Maybe<Scalars['String']['output']>
}

/** Record of type Answer (answer_model) */
export type AnswerModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Answer (answer_model) */
export type AnswerModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Answer (answer_model) */
export type AnswerModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ArticleCtaCardItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ArticleCtaCardItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ArticleCtaCardItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  path?: InputMaybe<StringFilter>
  text?: InputMaybe<StringFilter>
}

export enum ArticleCtaCardItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
}

/** Record of type [Field] Article CTA Card Item (article_cta_card_item_model) */
export type ArticleCtaCardItemModelRecord = RecordInterface & {
  __typename?: 'ArticleCtaCardItemModelRecord'
  _allTextLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  path?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Article CTA Card Item (article_cta_card_item_model) */
export type ArticleCtaCardItemModelRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Article CTA Card Item (article_cta_card_item_model) */
export type ArticleCtaCardItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Article CTA Card Item (article_cta_card_item_model) */
export type ArticleCtaCardItemModelRecordTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ArticleCtaCardModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ArticleCtaCardModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ArticleCtaCardModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  buttonText?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  options?: InputMaybe<LinksFilter>
  title?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum ArticleCtaCardModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type [Field] Article CTA Card (article_cta_card_model) */
export type ArticleCtaCardModelRecord = RecordInterface & {
  __typename?: 'ArticleCtaCardModelRecord'
  _allButtonTextLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  buttonText?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  options: ArticleCtaCardItemModelRecord[]
  title?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Article CTA Card (article_cta_card_model) */
export type ArticleCtaCardModelRecord_AllButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Article CTA Card (article_cta_card_model) */
export type ArticleCtaCardModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Article CTA Card (article_cta_card_model) */
export type ArticleCtaCardModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Article CTA Card (article_cta_card_model) */
export type ArticleCtaCardModelRecordButtonTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Article CTA Card (article_cta_card_model) */
export type ArticleCtaCardModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ArticleModelModelContentBlocksField = StructuredTextAssetRecord | StructuredTextPlotRecord | TableRecord

export type ArticleModelModelContentField = {
  __typename?: 'ArticleModelModelContentField'
  blocks: ArticleModelModelContentBlocksField[]
  links: ArticleModelModelContentLinksField[]
  value: Scalars['JsonField']['output']
}

export type ArticleModelModelContentFieldMultiLocaleField = {
  __typename?: 'ArticleModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<ArticleModelModelContentField>
}

export type ArticleModelModelContentLinksField =
  | ComponentAccordionModelRecord
  | ComponentBulletPointModelRecord
  | ComponentButtonModelRecord
  | FieldSignupSectionRecord
  | PromotionBannerModelRecord
  | VideoModelRecord

export type ArticleModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ArticleModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ArticleModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  author?: InputMaybe<LinksFilter>
  breadcrumbTitle?: InputMaybe<StringFilter>
  content?: InputMaybe<StructuredTextFilter>
  headerImage?: InputMaybe<FileFilter>
  id?: InputMaybe<ItemIdFilter>
  mainCallToAction?: InputMaybe<LinkFilter>
  parentField?: InputMaybe<LinkFilter>
  preview?: InputMaybe<StringFilter>
  readingTime?: InputMaybe<IntegerFilter>
  release?: InputMaybe<DateTimeFilter>
  secondaryCallToAction?: InputMaybe<LinkFilter>
  seo?: InputMaybe<LinkFilter>
  showInOverview?: InputMaybe<BooleanFilter>
  slug?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
  topic?: InputMaybe<LinkFilter>
}

export enum ArticleModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BreadcrumbTitleAsc = 'breadcrumbTitle_ASC',
  BreadcrumbTitleDesc = 'breadcrumbTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PreviewAsc = 'preview_ASC',
  PreviewDesc = 'preview_DESC',
  ReadingTimeAsc = 'readingTime_ASC',
  ReadingTimeDesc = 'readingTime_DESC',
  ReleaseAsc = 'release_ASC',
  ReleaseDesc = 'release_DESC',
  ShowInOverviewAsc = 'showInOverview_ASC',
  ShowInOverviewDesc = 'showInOverview_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecord = RecordInterface & {
  __typename?: 'ArticleModelRecord'
  _allBreadcrumbTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allContentLocales?: Maybe<ArticleModelModelContentFieldMultiLocaleField[]>
  _allPreviewLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  author: AuthorModelRecord[]
  breadcrumbTitle?: Maybe<Scalars['String']['output']>
  content?: Maybe<ArticleModelModelContentField>
  headerImage?: Maybe<FileField>
  id: Scalars['ItemId']['output']
  mainCallToAction?: Maybe<FieldCallToActionModelRecord>
  parentField?: Maybe<ServiceOverviewPageModelRecord>
  preview?: Maybe<Scalars['String']['output']>
  readingTime?: Maybe<Scalars['IntType']['output']>
  release?: Maybe<Scalars['DateTime']['output']>
  secondaryCallToAction?: Maybe<FieldCallToActionModelRecord>
  seo?: Maybe<SeoModelRecord>
  showInOverview: Scalars['BooleanType']['output']
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  topic?: Maybe<TopicOverviewPageModelRecord>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecord_AllBreadcrumbTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecord_AllPreviewLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecordBreadcrumbTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecordPreviewArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Article Page (article_model) */
export type ArticleModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type AuthorModelModelFilter = {
  AND?: InputMaybe<InputMaybe<AuthorModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<AuthorModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  cta?: InputMaybe<LinkFilter>
  description?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  name?: InputMaybe<StringFilter>
  partnerLogo?: InputMaybe<FileFilter>
  partnerLogoCta?: InputMaybe<LinkFilter>
  portrait?: InputMaybe<FileFilter>
}

export enum AuthorModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

/** Record of type [Field] Author (author_model) */
export type AuthorModelRecord = RecordInterface & {
  __typename?: 'AuthorModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  cta?: Maybe<FieldCallToActionModelRecord>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  name?: Maybe<Scalars['String']['output']>
  partnerLogo?: Maybe<FileField>
  partnerLogoCta?: Maybe<FieldCallToActionModelRecord>
  portrait?: Maybe<FileField>
}

/** Record of type [Field] Author (author_model) */
export type AuthorModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Author (author_model) */
export type AuthorModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Author (author_model) */
export type AuthorModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type AutomaticArticleSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<AutomaticArticleSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<AutomaticArticleSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  condition?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  industries?: InputMaybe<LinksFilter>
  name?: InputMaybe<StringFilter>
  offerings?: InputMaybe<LinksFilter>
  topic?: InputMaybe<LinkFilter>
}

export enum AutomaticArticleSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ConditionAsc = 'condition_ASC',
  ConditionDesc = 'condition_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

/** Record of type Automatic Article Section (automatic_article_section_model) */
export type AutomaticArticleSectionModelRecord = RecordInterface & {
  __typename?: 'AutomaticArticleSectionModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  condition?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  industries: IndustryOverviewPageModelRecord[]
  name?: Maybe<Scalars['String']['output']>
  offerings: OfferingDataModelRecord[]
  topic?: Maybe<TopicOverviewPageModelRecord>
}

/** Record of type Automatic Article Section (automatic_article_section_model) */
export type AutomaticArticleSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['BooleanType']['input']>
}

export type BooleanTypeNonNullMultiLocaleField = {
  __typename?: 'BooleanTypeNonNullMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value: Scalars['BooleanType']['output']
}

export type CalculatorModelModelFilter = {
  AND?: InputMaybe<InputMaybe<CalculatorModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<CalculatorModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  slug?: InputMaybe<StringFilter>
}

export enum CalculatorModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
}

/** Record of type [Field] Calculator (calculator_model) */
export type CalculatorModelRecord = RecordInterface & {
  __typename?: 'CalculatorModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  slug?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Calculator (calculator_model) */
export type CalculatorModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type CategoryLandingPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<CategoryLandingPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<CategoryLandingPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  bannerImage?: InputMaybe<FileFilter>
  description?: InputMaybe<TextFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  partnershipImage?: InputMaybe<FileFilter>
  phoneNumber?: InputMaybe<StringFilter>
  primaryCallToAction?: InputMaybe<LinkFilter>
  secondaryCallToAction?: InputMaybe<LinkFilter>
  sections?: InputMaybe<LinksFilter>
  seo?: InputMaybe<LinkFilter>
  showPartnershipSection?: InputMaybe<BooleanFilter>
  showPhoneNumber?: InputMaybe<BooleanFilter>
  slug?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum CategoryLandingPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  ShowPartnershipSectionAsc = 'showPartnershipSection_ASC',
  ShowPartnershipSectionDesc = 'showPartnershipSection_DESC',
  ShowPhoneNumberAsc = 'showPhoneNumber_ASC',
  ShowPhoneNumberDesc = 'showPhoneNumber_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CategoryLandingPageModelModelSectionsField =
  | FieldContentSectionModelRecord
  | FieldDynamicProDirectorySectionModelRecord
  | FieldSignupSectionRecord
  | HowItWorksSectionModelRecord
  | IndustrySectionModelRecord
  | InspirationSectionModelRecord
  | KnowledgeSectionModelRecord
  | OfferingSectionModelRecord
  | PartnerSectionModelRecord
  | PresentationSectionModelRecord
  | PromotionBannerModelRecord
  | ReviewSectionModelRecord
  | ServicesSectionModelRecord
  | SupportSectionModelRecord

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecord = RecordInterface & {
  __typename?: 'CategoryLandingPageModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allPhoneNumberLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  bannerImage?: Maybe<FileField>
  description?: Maybe<Scalars['String']['output']>
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  partnershipImage?: Maybe<FileField>
  phoneNumber?: Maybe<Scalars['String']['output']>
  primaryCallToAction?: Maybe<FieldCallToActionModelRecord>
  secondaryCallToAction?: Maybe<FieldCallToActionModelRecord>
  sections: CategoryLandingPageModelModelSectionsField[]
  seo?: Maybe<SeoModelRecord>
  showPartnershipSection: Scalars['BooleanType']['output']
  showPhoneNumber: Scalars['BooleanType']['output']
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecord_AllPhoneNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecordPhoneNumberArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Category Landing Page (category_landing_page_model) */
export type CategoryLandingPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ClientReviewModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ClientReviewModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ClientReviewModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  author?: InputMaybe<StringFilter>
  comment?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  platform?: InputMaybe<StringFilter>
  rating?: InputMaybe<IntegerFilter>
}

export enum ClientReviewModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlatformAsc = 'platform_ASC',
  PlatformDesc = 'platform_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
}

/** Record of type [Field] Client Review (client_review_model) */
export type ClientReviewModelRecord = RecordInterface & {
  __typename?: 'ClientReviewModelRecord'
  _allCommentLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  author?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  platform?: Maybe<Scalars['String']['output']>
  rating?: Maybe<Scalars['IntType']['output']>
}

/** Record of type [Field] Client Review (client_review_model) */
export type ClientReviewModelRecord_AllCommentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Client Review (client_review_model) */
export type ClientReviewModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Client Review (client_review_model) */
export type ClientReviewModelRecordCommentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata'
  count: Scalars['IntType']['output']
}

export enum ColorBucketType {
  Black = 'black',
  Blue = 'blue',
  Brown = 'brown',
  Cyan = 'cyan',
  Green = 'green',
  Grey = 'grey',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow',
}

export type ColorField = {
  __typename?: 'ColorField'
  alpha: Scalars['IntType']['output']
  blue: Scalars['IntType']['output']
  cssRgb: Scalars['String']['output']
  green: Scalars['IntType']['output']
  hex: Scalars['String']['output']
  red: Scalars['IntType']['output']
}

export type ComponentAccordionElementModelModelContentField = {
  __typename?: 'ComponentAccordionElementModelModelContentField'
  blocks: StructuredTextAssetRecord[]
  links: ComponentAccordionElementModelModelContentLinksField[]
  value: Scalars['JsonField']['output']
}

export type ComponentAccordionElementModelModelContentFieldMultiLocaleField = {
  __typename?: 'ComponentAccordionElementModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<ComponentAccordionElementModelModelContentField>
}

export type ComponentAccordionElementModelModelContentLinksField =
  | ComponentBulletPointModelRecord
  | ComponentButtonModelRecord
  | PromotionBannerModelRecord
  | VideoModelRecord

export type ComponentAccordionElementModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentAccordionElementModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentAccordionElementModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<StructuredTextFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
}

export enum ComponentAccordionElementModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Component] Accordion Element (component_accordion_element_model) */
export type ComponentAccordionElementModelRecord = RecordInterface & {
  __typename?: 'ComponentAccordionElementModelRecord'
  _allContentLocales?: Maybe<ComponentAccordionElementModelModelContentFieldMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content?: Maybe<ComponentAccordionElementModelModelContentField>
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Accordion Element (component_accordion_element_model) */
export type ComponentAccordionElementModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Accordion Element (component_accordion_element_model) */
export type ComponentAccordionElementModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Accordion Element (component_accordion_element_model) */
export type ComponentAccordionElementModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Accordion Element (component_accordion_element_model) */
export type ComponentAccordionElementModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Accordion Element (component_accordion_element_model) */
export type ComponentAccordionElementModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ComponentAccordionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentAccordionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentAccordionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  elements?: InputMaybe<LinksFilter>
  id?: InputMaybe<ItemIdFilter>
  name?: InputMaybe<StringFilter>
}

export enum ComponentAccordionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

/** Record of type [Component] Accordion (component_accordion_model) */
export type ComponentAccordionModelRecord = RecordInterface & {
  __typename?: 'ComponentAccordionModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  elements: ComponentAccordionElementModelRecord[]
  id: Scalars['ItemId']['output']
  name?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Accordion (component_accordion_model) */
export type ComponentAccordionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type ComponentBulletPointModelModelContentField = {
  __typename?: 'ComponentBulletPointModelModelContentField'
  blocks: StructuredTextAssetRecord[]
  links: Scalars['String']['output'][]
  value: Scalars['JsonField']['output']
}

export type ComponentBulletPointModelModelContentFieldMultiLocaleField = {
  __typename?: 'ComponentBulletPointModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<ComponentBulletPointModelModelContentField>
}

export type ComponentBulletPointModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentBulletPointModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentBulletPointModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<StructuredTextFilter>
  icon?: InputMaybe<LinkFilter>
  iconColor?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  lead?: InputMaybe<StringFilter>
  text?: InputMaybe<TextFilter>
}

export enum ComponentBulletPointModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IconColorAsc = 'iconColor_ASC',
  IconColorDesc = 'iconColor_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LeadAsc = 'lead_ASC',
  LeadDesc = 'lead_DESC',
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecord = RecordInterface & {
  __typename?: 'ComponentBulletPointModelRecord'
  _allContentLocales?: Maybe<ComponentBulletPointModelModelContentFieldMultiLocaleField[]>
  _allLeadLocales?: Maybe<StringMultiLocaleField[]>
  _allTextLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content?: Maybe<ComponentBulletPointModelModelContentField>
  icon?: Maybe<FontAwesomeIconModelRecord>
  iconColor?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  lead?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecord_AllLeadLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecordLeadArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Bullet point (component_bullet_point_model) */
export type ComponentBulletPointModelRecordTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

export type ComponentButtonModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentButtonModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentButtonModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  label?: InputMaybe<StringFilter>
  targetPath?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum ComponentButtonModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  TargetPathAsc = 'targetPath_ASC',
  TargetPathDesc = 'targetPath_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type [Component] Button (component_button_model) */
export type ComponentButtonModelRecord = RecordInterface & {
  __typename?: 'ComponentButtonModelRecord'
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allTargetPathLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  label?: Maybe<Scalars['String']['output']>
  targetPath?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Button (component_button_model) */
export type ComponentButtonModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Button (component_button_model) */
export type ComponentButtonModelRecord_AllTargetPathLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Button (component_button_model) */
export type ComponentButtonModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Button (component_button_model) */
export type ComponentButtonModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Button (component_button_model) */
export type ComponentButtonModelRecordTargetPathArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ComponentDropdownMenuItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentDropdownMenuItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentDropdownMenuItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  childrenField?: InputMaybe<LinksFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  link?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum ComponentDropdownMenuItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Component] Dropdown Menu Item (component_dropdown_menu_item_model) */
export type ComponentDropdownMenuItemModelRecord = RecordInterface & {
  __typename?: 'ComponentDropdownMenuItemModelRecord'
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  childrenField: ComponentDropdownMenuItemModelRecord[]
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  link?: Maybe<FieldCallToActionModelRecord>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Dropdown Menu Item (component_dropdown_menu_item_model) */
export type ComponentDropdownMenuItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Dropdown Menu Item (component_dropdown_menu_item_model) */
export type ComponentDropdownMenuItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Dropdown Menu Item (component_dropdown_menu_item_model) */
export type ComponentDropdownMenuItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ComponentDropdownMenuModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentDropdownMenuModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentDropdownMenuModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  childrenField?: InputMaybe<LinksFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  slug?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum ComponentDropdownMenuModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Component] Dropdown Menu (component_dropdown_menu_model) */
export type ComponentDropdownMenuModelRecord = RecordInterface & {
  __typename?: 'ComponentDropdownMenuModelRecord'
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  childrenField: ComponentDropdownMenuItemModelRecord[]
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Dropdown Menu (component_dropdown_menu_model) */
export type ComponentDropdownMenuModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Dropdown Menu (component_dropdown_menu_model) */
export type ComponentDropdownMenuModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Dropdown Menu (component_dropdown_menu_model) */
export type ComponentDropdownMenuModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ComponentTabElementModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentTabElementModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentTabElementModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  callToAction?: InputMaybe<LinkFilter>
  callToAction2?: InputMaybe<LinkFilter>
  headline?: InputMaybe<StringFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  image?: InputMaybe<FileFilter>
  text?: InputMaybe<TextFilter>
  title?: InputMaybe<StringFilter>
}

export enum ComponentTabElementModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecord = RecordInterface & {
  __typename?: 'ComponentTabElementModelRecord'
  _allHeadlineLocales?: Maybe<StringMultiLocaleField[]>
  _allTextLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  callToAction?: Maybe<FieldCallToActionModelRecord>
  callToAction2?: Maybe<FieldCallToActionModelRecord>
  headline?: Maybe<Scalars['String']['output']>
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  image?: Maybe<FileField>
  text?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecord_AllHeadlineLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecordHeadlineArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecordTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Component] Tab Element (component_tab_element_model) */
export type ComponentTabElementModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ComponentTabModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ComponentTabModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ComponentTabModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  elements?: InputMaybe<LinksFilter>
  id?: InputMaybe<ItemIdFilter>
  slug?: InputMaybe<StringFilter>
}

export enum ComponentTabModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
}

/** Record of type [Component] Tab (component_tab_model) */
export type ComponentTabModelRecord = RecordInterface & {
  __typename?: 'ComponentTabModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  elements: ComponentTabElementModelRecord[]
  id: Scalars['ItemId']['output']
  slug?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Tab (component_tab_model) */
export type ComponentTabModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type CraftsmenRegionDirectoryPageModelContentField =
  | FieldContentSectionModelRecord
  | FieldSignupSectionRecord
  | InspirationSectionModelRecord
  | KnowledgeSectionModelRecord
  | PromotionBannerModelRecord
  | ReviewSectionModelRecord

/** Linking fields */
export enum CraftsmenRegionDirectoryPageModelFieldsReferencingRegionModel {
  CraftsmenRegionDirectoryPageTopRegions = 'craftsmenRegionDirectoryPage_topRegions',
}

export type CraftsmenRegionDirectoryPageModelFilter = {
  AND?: InputMaybe<InputMaybe<CraftsmenRegionDirectoryPageModelFilter>[]>
  OR?: InputMaybe<InputMaybe<CraftsmenRegionDirectoryPageModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<LinksFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  excludedIndustries?: InputMaybe<LinksFilter>
  id?: InputMaybe<ItemIdFilter>
  topRegions?: InputMaybe<LinksFilter>
}

export enum CraftsmenRegionDirectoryPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

/** Record of type Craftsmen Region Directory Page (craftsmen_region_directory_page) */
export type CraftsmenRegionDirectoryPageRecord = RecordInterface & {
  __typename?: 'CraftsmenRegionDirectoryPageRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content: CraftsmenRegionDirectoryPageModelContentField[]
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  excludedIndustries: IndustryModelRecord[]
  id: Scalars['ItemId']['output']
  topRegions: RegionRecord[]
}

/** Record of type Craftsmen Region Directory Page (craftsmen_region_directory_page) */
export type CraftsmenRegionDirectoryPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>
}

/** Specifies how to filter DateTime fields */
export type DateTimeFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>
}

export type EmergencyLandingPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<EmergencyLandingPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<EmergencyLandingPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<TextFilter>
  hint?: InputMaybe<TextFilter>
  howItWorksSection?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  offering?: InputMaybe<LinkFilter>
  offeringId?: InputMaybe<IntegerFilter>
  offeringSection?: InputMaybe<LinkFilter>
  phoneNumber?: InputMaybe<StringFilter>
  reviews?: InputMaybe<LinksFilter>
  reviewsAverageRating?: InputMaybe<FloatFilter>
  reviewsSectionCallToAction?: InputMaybe<StringFilter>
  sections?: InputMaybe<LinksFilter>
  seo?: InputMaybe<LinkFilter>
  showPhoneNumber?: InputMaybe<BooleanFilter>
  showSupportSection?: InputMaybe<BooleanFilter>
  slug?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum EmergencyLandingPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OfferingIdAsc = 'offeringId_ASC',
  OfferingIdDesc = 'offeringId_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  ReviewsAverageRatingAsc = 'reviewsAverageRating_ASC',
  ReviewsAverageRatingDesc = 'reviewsAverageRating_DESC',
  ReviewsSectionCallToActionAsc = 'reviewsSectionCallToAction_ASC',
  ReviewsSectionCallToActionDesc = 'reviewsSectionCallToAction_DESC',
  ShowPhoneNumberAsc = 'showPhoneNumber_ASC',
  ShowPhoneNumberDesc = 'showPhoneNumber_DESC',
  ShowSupportSectionAsc = 'showSupportSection_ASC',
  ShowSupportSectionDesc = 'showSupportSection_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EmergencyLandingPageModelModelSectionsField =
  | EmergencyOfferingSectionModelRecord
  | FieldContentSectionModelRecord
  | FieldDynamicProDirectorySectionModelRecord
  | FieldSignupSectionRecord
  | HowItWorksSectionModelRecord
  | IndustrySectionModelRecord
  | InspirationSectionModelRecord
  | KnowledgeSectionModelRecord
  | OfferingSectionModelRecord
  | PartnerSectionModelRecord
  | PresentationSectionModelRecord
  | PromotionBannerModelRecord
  | ReviewSectionModelRecord
  | ServicesSectionModelRecord
  | SupportSectionModelRecord

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecord = RecordInterface & {
  __typename?: 'EmergencyLandingPageModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allHintLocales?: Maybe<StringMultiLocaleField[]>
  _allPhoneNumberLocales?: Maybe<StringMultiLocaleField[]>
  _allReviewsSectionCallToActionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  hint?: Maybe<Scalars['String']['output']>
  howItWorksSection?: Maybe<HowItWorksSectionModelRecord>
  id: Scalars['ItemId']['output']
  offering?: Maybe<OfferingDataModelRecord>
  offeringId?: Maybe<Scalars['IntType']['output']>
  offeringSection?: Maybe<EmergencyOfferingSectionModelRecord>
  phoneNumber?: Maybe<Scalars['String']['output']>
  reviews: ClientReviewModelRecord[]
  reviewsAverageRating?: Maybe<Scalars['FloatType']['output']>
  reviewsSectionCallToAction?: Maybe<Scalars['String']['output']>
  sections: EmergencyLandingPageModelModelSectionsField[]
  seo?: Maybe<SeoModelRecord>
  showPhoneNumber: Scalars['BooleanType']['output']
  showSupportSection: Scalars['BooleanType']['output']
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecord_AllHintLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecord_AllPhoneNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecord_AllReviewsSectionCallToActionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecordHintArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecordPhoneNumberArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecordReviewsSectionCallToActionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Emergency Landing Page (emergency_landing_page_model) */
export type EmergencyLandingPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type EmergencyOfferingModelModelFilter = {
  AND?: InputMaybe<InputMaybe<EmergencyOfferingModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<EmergencyOfferingModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  collection?: InputMaybe<StringFilter>
  icon?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
}

export enum EmergencyOfferingModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CollectionAsc = 'collection_ASC',
  CollectionDesc = 'collection_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Emergency Offering (emergency_offering_model) */
export type EmergencyOfferingModelRecord = RecordInterface & {
  __typename?: 'EmergencyOfferingModelRecord'
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  collection?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Emergency Offering (emergency_offering_model) */
export type EmergencyOfferingModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Emergency Offering (emergency_offering_model) */
export type EmergencyOfferingModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Emergency Offering (emergency_offering_model) */
export type EmergencyOfferingModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type EmergencyOfferingSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<EmergencyOfferingSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<EmergencyOfferingSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  actionButtonText?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  formDescription?: InputMaybe<TextFilter>
  formTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  offerings?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
  typeInputLabel?: InputMaybe<StringFilter>
}

export enum EmergencyOfferingSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ActionButtonTextAsc = 'actionButtonText_ASC',
  ActionButtonTextDesc = 'actionButtonText_DESC',
  FormTitleAsc = 'formTitle_ASC',
  FormTitleDesc = 'formTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeInputLabelAsc = 'typeInputLabel_ASC',
  TypeInputLabelDesc = 'typeInputLabel_DESC',
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord = RecordInterface & {
  __typename?: 'EmergencyOfferingSectionModelRecord'
  _allActionButtonTextLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allFormDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allFormTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allTypeInputLabelLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  actionButtonText?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  formDescription?: Maybe<Scalars['String']['output']>
  formTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  offerings: EmergencyOfferingModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  typeInputLabel?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_AllActionButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_AllFormDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_AllFormTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_AllTypeInputLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecordActionButtonTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecordFormDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecordFormTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Emergency Offering Section (emergency_offering_section_model) */
export type EmergencyOfferingSectionModelRecordTypeInputLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export enum FaviconType {
  AppleTouchIcon = 'appleTouchIcon',
  Icon = 'icon',
  MsApplication = 'msApplication',
}

export type FieldCallToActionModelModelAnchorField =
  | FieldContentSectionModelRecord
  | FieldDynamicProDirectorySectionModelRecord
  | FieldProMapSectionRecord
  | FieldSignupSectionRecord
  | HowItWorksSectionModelRecord
  | IndustrySectionModelRecord
  | InspirationSectionModelRecord
  | KnowledgeSectionModelRecord
  | OfferingSectionModelRecord
  | PartnerSectionModelRecord
  | PresentationSectionModelRecord
  | PromotionBannerModelRecord
  | ReviewSectionModelRecord
  | ServicesSectionModelRecord
  | SupportSectionModelRecord

export type FieldCallToActionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldCallToActionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldCallToActionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  actionType?: InputMaybe<StringFilter>
  actionValue?: InputMaybe<StringFilter>
  anchor?: InputMaybe<LinkFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
}

export enum FieldCallToActionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ActionTypeAsc = 'actionType_ASC',
  ActionTypeDesc = 'actionType_DESC',
  ActionValueAsc = 'actionValue_ASC',
  ActionValueDesc = 'actionValue_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Call to action (field_call_to_action_model) */
export type FieldCallToActionModelRecord = RecordInterface & {
  __typename?: 'FieldCallToActionModelRecord'
  _allActionValueLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  actionType?: Maybe<Scalars['String']['output']>
  actionValue?: Maybe<Scalars['String']['output']>
  anchor?: Maybe<FieldCallToActionModelModelAnchorField>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Call to action (field_call_to_action_model) */
export type FieldCallToActionModelRecord_AllActionValueLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Call to action (field_call_to_action_model) */
export type FieldCallToActionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Call to action (field_call_to_action_model) */
export type FieldCallToActionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Call to action (field_call_to_action_model) */
export type FieldCallToActionModelRecordActionValueArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Call to action (field_call_to_action_model) */
export type FieldCallToActionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FieldCallToActionModelRecordMultiLocaleField = {
  __typename?: 'FieldCallToActionModelRecordMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<FieldCallToActionModelRecord>
}

export type FieldContactFormModelModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldContactFormModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldContactFormModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  alternativeCallToActionTitle?: InputMaybe<StringFilter>
  callToAction1?: InputMaybe<LinkFilter>
  callToAction2?: InputMaybe<LinkFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
}

export enum FieldContactFormModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  AlternativeCallToActionTitleAsc = 'alternativeCallToActionTitle_ASC',
  AlternativeCallToActionTitleDesc = 'alternativeCallToActionTitle_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

/** Record of type [Field] Contact form (field_contact_form_model) */
export type FieldContactFormModelRecord = RecordInterface & {
  __typename?: 'FieldContactFormModelRecord'
  _allAlternativeCallToActionTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  alternativeCallToActionTitle?: Maybe<Scalars['String']['output']>
  callToAction1?: Maybe<FieldCallToActionModelRecord>
  callToAction2?: Maybe<FieldCallToActionModelRecord>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
}

/** Record of type [Field] Contact form (field_contact_form_model) */
export type FieldContactFormModelRecord_AllAlternativeCallToActionTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Contact form (field_contact_form_model) */
export type FieldContactFormModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Contact form (field_contact_form_model) */
export type FieldContactFormModelRecordAlternativeCallToActionTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FieldContentSectionModelModelContentBlocksField = StructuredTextAssetRecord | TableRecord

export type FieldContentSectionModelModelContentField = {
  __typename?: 'FieldContentSectionModelModelContentField'
  blocks: FieldContentSectionModelModelContentBlocksField[]
  links: FieldContentSectionModelModelContentLinksField[]
  value: Scalars['JsonField']['output']
}

export type FieldContentSectionModelModelContentFieldMultiLocaleField = {
  __typename?: 'FieldContentSectionModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<FieldContentSectionModelModelContentField>
}

export type FieldContentSectionModelModelContentLinksField =
  | ComponentAccordionModelRecord
  | ComponentBulletPointModelRecord
  | ComponentButtonModelRecord
  | FieldSignupSectionRecord
  | PromotionBannerModelRecord
  | VideoModelRecord

export type FieldContentSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldContentSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldContentSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<StructuredTextFilter>
  id?: InputMaybe<ItemIdFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum FieldContentSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecord = RecordInterface & {
  __typename?: 'FieldContentSectionModelRecord'
  _allContentLocales?: Maybe<FieldContentSectionModelModelContentFieldMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content?: Maybe<FieldContentSectionModelModelContentField>
  id: Scalars['ItemId']['output']
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Content Section (field_content_section_model) */
export type FieldContentSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FieldDynamicProDirectorySectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldDynamicProDirectorySectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldDynamicProDirectorySectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<TextFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  distance?: InputMaybe<IntegerFilter>
  id?: InputMaybe<ItemIdFilter>
  industries?: InputMaybe<StringFilter>
  location?: InputMaybe<StringFilter>
  offerings?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum FieldDynamicProDirectorySectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  DistanceAsc = 'distance_ASC',
  DistanceDesc = 'distance_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IndustriesAsc = 'industries_ASC',
  IndustriesDesc = 'industries_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecord = RecordInterface & {
  __typename?: 'FieldDynamicProDirectorySectionModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  distance?: Maybe<Scalars['IntType']['output']>
  id: Scalars['ItemId']['output']
  industries?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  offerings: OfferingDataModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Pro Directory Section (field_dynamic_pro_directory_section_model) */
export type FieldDynamicProDirectorySectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FieldHomePageIndustryItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldHomePageIndustryItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldHomePageIndustryItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  collection?: InputMaybe<StringFilter>
  icon?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  target?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum FieldHomePageIndustryItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CollectionAsc = 'collection_ASC',
  CollectionDesc = 'collection_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FieldHomePageIndustryItemModelModelTargetField =
  | CategoryLandingPageModelRecord
  | EmergencyLandingPageModelRecord
  | IndustryOverviewPageModelRecord
  | StaticPageModelRecord

/** Record of type [Field] Industry Item (field_home_page_industry_item_model) */
export type FieldHomePageIndustryItemModelRecord = RecordInterface & {
  __typename?: 'FieldHomePageIndustryItemModelRecord'
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  collection?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  target?: Maybe<FieldHomePageIndustryItemModelModelTargetField>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Industry Item (field_home_page_industry_item_model) */
export type FieldHomePageIndustryItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Industry Item (field_home_page_industry_item_model) */
export type FieldHomePageIndustryItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Industry Item (field_home_page_industry_item_model) */
export type FieldHomePageIndustryItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FieldMarketingTopicModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldMarketingTopicModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldMarketingTopicModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  topic?: InputMaybe<StringFilter>
}

export enum FieldMarketingTopicModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TopicAsc = 'topic_ASC',
  TopicDesc = 'topic_DESC',
}

/** Record of type [Field] Marketing Topic (field_marketing_topic) */
export type FieldMarketingTopicRecord = RecordInterface & {
  __typename?: 'FieldMarketingTopicRecord'
  _allSendgridListIdsLocales?: Maybe<SingleLineStringRecordListListNonNullMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  sendgridListIds: SingleLineStringRecord[]
  topic?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Marketing Topic (field_marketing_topic) */
export type FieldMarketingTopicRecord_AllSendgridListIdsLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Marketing Topic (field_marketing_topic) */
export type FieldMarketingTopicRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Marketing Topic (field_marketing_topic) */
export type FieldMarketingTopicRecordSendgridListIdsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FieldProDirectorySectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldProDirectorySectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldProDirectorySectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  name?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum FieldProDirectorySectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type [Field] Offering Page Pro Directory (field_pro_directory_section_model) */
export type FieldProDirectorySectionModelRecord = RecordInterface & {
  __typename?: 'FieldProDirectorySectionModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  name?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Offering Page Pro Directory (field_pro_directory_section_model) */
export type FieldProDirectorySectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type FieldProMapSectionModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldProMapSectionModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldProMapSectionModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  descriptiveName?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
}

export enum FieldProMapSectionModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveNameAsc = 'descriptiveName_ASC',
  DescriptiveNameDesc = 'descriptiveName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Pro Map Section (field_pro_map_section) */
export type FieldProMapSectionRecord = RecordInterface & {
  __typename?: 'FieldProMapSectionRecord'
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  descriptiveName?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Pro Map Section (field_pro_map_section) */
export type FieldProMapSectionRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Pro Map Section (field_pro_map_section) */
export type FieldProMapSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Pro Map Section (field_pro_map_section) */
export type FieldProMapSectionRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FieldRegionPageProDirectoryModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldRegionPageProDirectoryModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldRegionPageProDirectoryModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  descriptiveName?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
}

export enum FieldRegionPageProDirectoryModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveNameAsc = 'descriptiveName_ASC',
  DescriptiveNameDesc = 'descriptiveName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

/** Record of type [Field] Region Page Pro Directory (field_region_page_pro_directory) */
export type FieldRegionPageProDirectoryRecord = RecordInterface & {
  __typename?: 'FieldRegionPageProDirectoryRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  descriptiveName?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
}

/** Record of type [Field] Region Page Pro Directory (field_region_page_pro_directory) */
export type FieldRegionPageProDirectoryRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Linking fields */
export enum FieldRegionPageProMapModelFieldsReferencingRegionModel {
  FieldRegionPageProMapRegions = 'fieldRegionPageProMap_regions',
}

export type FieldRegionPageProMapModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldRegionPageProMapModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldRegionPageProMapModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  descriptiveName?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  regions?: InputMaybe<LinksFilter>
}

export enum FieldRegionPageProMapModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveNameAsc = 'descriptiveName_ASC',
  DescriptiveNameDesc = 'descriptiveName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

/** Record of type [Field] Region Page Pro Map (field_region_page_pro_map) */
export type FieldRegionPageProMapRecord = RecordInterface & {
  __typename?: 'FieldRegionPageProMapRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  descriptiveName?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  regions: RegionRecord[]
}

/** Record of type [Field] Region Page Pro Map (field_region_page_pro_map) */
export type FieldRegionPageProMapRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type FieldSignupSectionModelFilter = {
  AND?: InputMaybe<InputMaybe<FieldSignupSectionModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FieldSignupSectionModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<TextFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  formButtonLabel?: InputMaybe<StringFilter>
  formTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  image?: InputMaybe<FileFilter>
  title?: InputMaybe<StringFilter>
  topic?: InputMaybe<LinkFilter>
}

export enum FieldSignupSectionModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  FormButtonLabelAsc = 'formButtonLabel_ASC',
  FormButtonLabelDesc = 'formButtonLabel_DESC',
  FormTitleAsc = 'formTitle_ASC',
  FormTitleDesc = 'formTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecord = RecordInterface & {
  __typename?: 'FieldSignupSectionRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allFormButtonLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allFormTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  formButtonLabel?: Maybe<Scalars['String']['output']>
  formTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  image?: Maybe<FileField>
  title?: Maybe<Scalars['String']['output']>
  topic?: Maybe<FieldMarketingTopicRecord>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecord_AllFormButtonLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecord_AllFormTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecordFormButtonLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecordFormTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Signup Section (field_signup_section) */
export type FieldSignupSectionRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileField = FileFieldInterface & {
  __typename?: 'FileField'
  _createdAt: Scalars['DateTime']['output']
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _updatedAt: Scalars['DateTime']['output']
  alt?: Maybe<Scalars['String']['output']>
  author?: Maybe<Scalars['String']['output']>
  basename: Scalars['String']['output']
  blurUpThumb?: Maybe<Scalars['String']['output']>
  blurhash?: Maybe<Scalars['String']['output']>
  colors: ColorField[]
  copyright?: Maybe<Scalars['String']['output']>
  customData: Scalars['CustomData']['output']
  exifInfo: Scalars['CustomData']['output']
  filename: Scalars['String']['output']
  focalPoint?: Maybe<FocalPoint>
  format: Scalars['String']['output']
  height?: Maybe<Scalars['IntType']['output']>
  id: Scalars['UploadId']['output']
  md5: Scalars['String']['output']
  mimeType: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  responsiveImage?: Maybe<ResponsiveImage>
  size: Scalars['IntType']['output']
  smartTags: Scalars['String']['output'][]
  tags: Scalars['String']['output'][]
  thumbhash?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
  video?: Maybe<UploadVideoField>
  width?: Maybe<Scalars['IntType']['output']>
}

export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>
  punch?: Scalars['Float']['input']
  quality?: Scalars['Int']['input']
  size?: Scalars['Int']['input']
}

export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  imgixParams?: InputMaybe<ImgixParams>
  locale?: InputMaybe<SiteLocale>
  sizes?: InputMaybe<Scalars['String']['input']>
}

export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>
}

export type FileFieldInterface = {
  _createdAt: Scalars['DateTime']['output']
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _updatedAt: Scalars['DateTime']['output']
  alt?: Maybe<Scalars['String']['output']>
  author?: Maybe<Scalars['String']['output']>
  basename: Scalars['String']['output']
  blurUpThumb?: Maybe<Scalars['String']['output']>
  blurhash?: Maybe<Scalars['String']['output']>
  colors: ColorField[]
  copyright?: Maybe<Scalars['String']['output']>
  customData: Scalars['CustomData']['output']
  exifInfo: Scalars['CustomData']['output']
  filename: Scalars['String']['output']
  focalPoint?: Maybe<FocalPoint>
  format: Scalars['String']['output']
  height?: Maybe<Scalars['IntType']['output']>
  id: Scalars['UploadId']['output']
  md5: Scalars['String']['output']
  mimeType: Scalars['String']['output']
  notes?: Maybe<Scalars['String']['output']>
  responsiveImage?: Maybe<ResponsiveImage>
  size: Scalars['IntType']['output']
  smartTags: Scalars['String']['output'][]
  tags: Scalars['String']['output'][]
  thumbhash?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
  video?: Maybe<UploadVideoField>
  width?: Maybe<Scalars['IntType']['output']>
}

export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>
  punch?: Scalars['Float']['input']
  quality?: Scalars['Int']['input']
  size?: Scalars['Int']['input']
}

export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  imgixParams?: InputMaybe<ImgixParams>
  locale?: InputMaybe<SiteLocale>
  sizes?: InputMaybe<Scalars['String']['input']>
}

export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>
}

export type FileFieldMultiLocaleField = {
  __typename?: 'FileFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<FileField>
}

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<InputMaybe<Scalars['UploadId']['input']>[]>
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<InputMaybe<Scalars['UploadId']['input']>[]>
}

/** Specifies how to filter Floating-point fields */
export type FloatFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['FloatType']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['FloatType']['input']>
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['FloatType']['input']>
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['FloatType']['input']>
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['FloatType']['input']>
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['FloatType']['input']>
}

export type FontAwesomeIconModelModelFilter = {
  AND?: InputMaybe<InputMaybe<FontAwesomeIconModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<FontAwesomeIconModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  collection?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  name?: InputMaybe<StringFilter>
}

export enum FontAwesomeIconModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CollectionAsc = 'collection_ASC',
  CollectionDesc = 'collection_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

/** Record of type [Field] Font Awesome Icon (font_awesome_icon_model) */
export type FontAwesomeIconModelRecord = RecordInterface & {
  __typename?: 'FontAwesomeIconModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  collection?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  name?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Font Awesome Icon (font_awesome_icon_model) */
export type FontAwesomeIconModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type GlobalSeoField = {
  __typename?: 'GlobalSeoField'
  facebookPageUrl?: Maybe<Scalars['String']['output']>
  fallbackSeo?: Maybe<SeoField>
  siteName?: Maybe<Scalars['String']['output']>
  titleSuffix?: Maybe<Scalars['String']['output']>
  twitterAccount?: Maybe<Scalars['String']['output']>
}

export type HomePageModelModelContentField =
  | FieldContentSectionModelRecord
  | HowItWorksSectionModelRecord
  | IndustrySectionModelRecord
  | InspirationSectionModelRecord
  | KnowledgeSectionModelRecord
  | PartnerSectionModelRecord
  | PresentationSectionModelRecord
  | PromotionBannerModelRecord
  | ReviewSectionModelRecord
  | ServicesSectionModelRecord
  | SupportSectionModelRecord

export type HomePageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<HomePageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<HomePageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<LinksFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum HomePageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type Home Page (home_page_model) */
export type HomePageModelRecord = RecordInterface & {
  __typename?: 'HomePageModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content: HomePageModelModelContentField[]
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type Home Page (home_page_model) */
export type HomePageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type HowItWorksItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<HowItWorksItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<HowItWorksItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  callToAction?: InputMaybe<LinkFilter>
  collection?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  icon?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
}

export enum HowItWorksItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CollectionAsc = 'collection_ASC',
  CollectionDesc = 'collection_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] How it works item (how_it_works_item_model) */
export type HowItWorksItemModelRecord = RecordInterface & {
  __typename?: 'HowItWorksItemModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  callToAction?: Maybe<FieldCallToActionModelRecord>
  collection?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] How it works item (how_it_works_item_model) */
export type HowItWorksItemModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] How it works item (how_it_works_item_model) */
export type HowItWorksItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] How it works item (how_it_works_item_model) */
export type HowItWorksItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] How it works item (how_it_works_item_model) */
export type HowItWorksItemModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] How it works item (how_it_works_item_model) */
export type HowItWorksItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type HowItWorksSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<HowItWorksSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<HowItWorksSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  actionButtonText?: InputMaybe<StringFilter>
  activeTab?: InputMaybe<StringFilter>
  contactUsList?: InputMaybe<LinksFilter>
  description?: InputMaybe<TextFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  items?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  strengthList?: InputMaybe<LinksFilter>
  title?: InputMaybe<StringFilter>
}

export enum HowItWorksSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ActionButtonTextAsc = 'actionButtonText_ASC',
  ActionButtonTextDesc = 'actionButtonText_DESC',
  ActiveTabAsc = 'activeTab_ASC',
  ActiveTabDesc = 'activeTab_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecord = RecordInterface & {
  __typename?: 'HowItWorksSectionModelRecord'
  _allActionButtonTextLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  actionButtonText?: Maybe<Scalars['String']['output']>
  activeTab?: Maybe<Scalars['String']['output']>
  contactUsList: HowItWorksItemModelRecord[]
  description?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  items: HowItWorksItemModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  strengthList: HowItWorksItemModelRecord[]
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecord_AllActionButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecordActionButtonTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] How it works section (how_it_works_section_model) */
export type HowItWorksSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: InputMaybe<Scalars['String']['input']>
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: InputMaybe<ImgixParamsAuto[]>
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: InputMaybe<Scalars['String']['input']>
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemove?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemoveFallback?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplace?: InputMaybe<Scalars['String']['input']>
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplaceFallback?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace-neg-prompt)
   */
  bgReplaceNegPrompt?: InputMaybe<Scalars['String']['input']>
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: InputMaybe<Scalars['String']['input']>
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: InputMaybe<ImgixParamsBlendAlign[]>
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars['String']['input']>
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: InputMaybe<ImgixParamsBlendCrop[]>
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: InputMaybe<Scalars['String']['input']>
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: InputMaybe<Scalars['String']['input']>
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: InputMaybe<Scalars['String']['input']>
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: InputMaybe<ImgixParamsCh[]>
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars['String']['input']>
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: InputMaybe<ImgixParamsCrop[]>
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: InputMaybe<ImgixParamsCs>
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: InputMaybe<Scalars['String']['input']>
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars['String']['input']>
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: InputMaybe<ImgixParamsFill>
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars['String']['input']>
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-fallback)
   */
  fillGenFallback?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-neg-prompt)
   */
  fillGenNegPrompt?: InputMaybe<Scalars['String']['input']>
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-pos)
   */
  fillGenPos?: InputMaybe<ImgixParamsFillGenPos[]>
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-prompt)
   */
  fillGenPrompt?: InputMaybe<Scalars['String']['input']>
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-seed)
   */
  fillGenSeed?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-cs)
   */
  fillGradientCs?: InputMaybe<ImgixParamsFillGradientCs>
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear)
   */
  fillGradientLinear?: InputMaybe<Scalars['String']['input']>
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection?: InputMaybe<ImgixParamsFillGradientLinearDirection[]>
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial)
   */
  fillGradientRadial?: InputMaybe<Scalars['String']['input']>
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius?: InputMaybe<Scalars['String']['input']>
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-type)
   */
  fillGradientType?: InputMaybe<ImgixParamsFillGradientType>
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: InputMaybe<ImgixParamsFit>
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: InputMaybe<ImgixParamsFlip>
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: InputMaybe<ImgixParamsFm>
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame?: InputMaybe<Scalars['String']['input']>
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   */
  gifQ?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: InputMaybe<Scalars['String']['input']>
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: InputMaybe<ImgixParamsIptc>
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   */
  jpgProgressive?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: InputMaybe<Scalars['String']['input']>
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: InputMaybe<ImgixParamsMarkAlign[]>
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: InputMaybe<Scalars['String']['input']>
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: InputMaybe<Scalars['String']['input']>
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: InputMaybe<Scalars['String']['input']>
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars['String']['input']>
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: InputMaybe<ImgixParamsPalette>
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: InputMaybe<Scalars['String']['input']>
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: InputMaybe<Scalars['String']['input']>
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   */
  svgSanitize?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: InputMaybe<ImgixParamsTrim>
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars['String']['input']>
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: InputMaybe<Scalars['String']['input']>
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: InputMaybe<ImgixParamsTxtAlign[]>
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: InputMaybe<ImgixParamsTxtClip[]>
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: InputMaybe<Scalars['String']['input']>
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: InputMaybe<Scalars['String']['input']>
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: InputMaybe<Scalars['String']['input']>
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale)
   */
  upscale?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale-fallback)
   */
  upscaleFallback?: InputMaybe<Scalars['BooleanType']['input']>
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: InputMaybe<Scalars['FloatType']['input']>
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: InputMaybe<Scalars['IntType']['input']>
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: InputMaybe<Scalars['FloatType']['input']>
}

export enum ImgixParamsAuto {
  Compress = 'compress',
  Enhance = 'enhance',
  Format = 'format',
  Redeye = 'redeye',
}

export enum ImgixParamsBlendAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top',
}

export enum ImgixParamsBlendCrop {
  Bottom = 'bottom',
  Faces = 'faces',
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

export enum ImgixParamsBlendFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Max = 'max',
  Scale = 'scale',
}

export enum ImgixParamsBlendMode {
  Burn = 'burn',
  Color = 'color',
  Darken = 'darken',
  Difference = 'difference',
  Dodge = 'dodge',
  Exclusion = 'exclusion',
  Hardlight = 'hardlight',
  Hue = 'hue',
  Lighten = 'lighten',
  Luminosity = 'luminosity',
  Multiply = 'multiply',
  Normal = 'normal',
  Overlay = 'overlay',
  Saturation = 'saturation',
  Screen = 'screen',
  Softlight = 'softlight',
}

export enum ImgixParamsBlendSize {
  Inherit = 'inherit',
}

export enum ImgixParamsCh {
  Dpr = 'dpr',
  SaveData = 'saveData',
  Width = 'width',
}

export enum ImgixParamsCrop {
  Bottom = 'bottom',
  Edges = 'edges',
  Entropy = 'entropy',
  Faces = 'faces',
  Focalpoint = 'focalpoint',
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

export enum ImgixParamsCs {
  Adobergb1998 = 'adobergb1998',
  Srgb = 'srgb',
  Strip = 'strip',
  Tinysrgb = 'tinysrgb',
}

export enum ImgixParamsFill {
  Blur = 'blur',
  Gen = 'gen',
  Generative = 'generative',
  Gradient = 'gradient',
  Solid = 'solid',
}

export enum ImgixParamsFillGenPos {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top',
}

export enum ImgixParamsFillGradientCs {
  Hsl = 'hsl',
  Lch = 'lch',
  Linear = 'linear',
  Oklab = 'oklab',
  Srgb = 'srgb',
}

export enum ImgixParamsFillGradientLinearDirection {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

export enum ImgixParamsFillGradientType {
  Linear = 'linear',
  Radial = 'radial',
}

export enum ImgixParamsFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Facearea = 'facearea',
  Fill = 'fill',
  Fillmax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale',
}

export enum ImgixParamsFlip {
  H = 'h',
  Hv = 'hv',
  V = 'v',
}

export enum ImgixParamsFm {
  Avif = 'avif',
  Blurhash = 'blurhash',
  Gif = 'gif',
  Jp2 = 'jp2',
  Jpg = 'jpg',
  Json = 'json',
  Jxr = 'jxr',
  Mp4 = 'mp4',
  Pjpg = 'pjpg',
  Png = 'png',
  Png8 = 'png8',
  Png32 = 'png32',
  Webm = 'webm',
  Webp = 'webp',
}

export enum ImgixParamsIptc {
  Allow = 'allow',
  Block = 'block',
}

export enum ImgixParamsMarkAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top',
}

export enum ImgixParamsMarkFit {
  Clip = 'clip',
  Crop = 'crop',
  Fill = 'fill',
  Max = 'max',
  Scale = 'scale',
}

export enum ImgixParamsMarkTile {
  Grid = 'grid',
}

export enum ImgixParamsPalette {
  Css = 'css',
  Json = 'json',
}

export enum ImgixParamsTransparency {
  Grid = 'grid',
}

export enum ImgixParamsTrim {
  Auto = 'auto',
  Color = 'color',
}

export enum ImgixParamsTxtAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top',
}

export enum ImgixParamsTxtClip {
  Ellipsis = 'ellipsis',
  End = 'end',
  Middle = 'middle',
  Start = 'start',
}

export enum ImgixParamsTxtFit {
  Max = 'max',
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars['BooleanType']['input']>
}

export type IndustryModelModelFilter = {
  AND?: InputMaybe<InputMaybe<IndustryModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<IndustryModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  hideInGeneratedPages?: InputMaybe<BooleanFilter>
  id?: InputMaybe<ItemIdFilter>
  idField?: InputMaybe<IntegerFilter>
  label?: InputMaybe<StringFilter>
  rank?: InputMaybe<IntegerFilter>
  slug?: InputMaybe<StringFilter>
  textVariable?: InputMaybe<StringFilter>
}

export enum IndustryModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  HideInGeneratedPagesAsc = 'hideInGeneratedPages_ASC',
  HideInGeneratedPagesDesc = 'hideInGeneratedPages_DESC',
  IdFieldAsc = 'idField_ASC',
  IdFieldDesc = 'idField_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RankAsc = 'rank_ASC',
  RankDesc = 'rank_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TextVariableAsc = 'textVariable_ASC',
  TextVariableDesc = 'textVariable_DESC',
}

/** Record of type Industry (industry_model) */
export type IndustryModelRecord = RecordInterface & {
  __typename?: 'IndustryModelRecord'
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allTextVariableLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  hideInGeneratedPages: Scalars['BooleanType']['output']
  id: Scalars['ItemId']['output']
  idField?: Maybe<Scalars['IntType']['output']>
  label?: Maybe<Scalars['String']['output']>
  rank?: Maybe<Scalars['IntType']['output']>
  slug?: Maybe<Scalars['String']['output']>
  textVariable?: Maybe<Scalars['String']['output']>
}

/** Record of type Industry (industry_model) */
export type IndustryModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry (industry_model) */
export type IndustryModelRecord_AllTextVariableLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry (industry_model) */
export type IndustryModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry (industry_model) */
export type IndustryModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry (industry_model) */
export type IndustryModelRecordTextVariableArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type IndustryOverviewPageModelModelContentBlocksField = StructuredTextAssetRecord | TableRecord

export type IndustryOverviewPageModelModelContentField = {
  __typename?: 'IndustryOverviewPageModelModelContentField'
  blocks: IndustryOverviewPageModelModelContentBlocksField[]
  links: IndustryOverviewPageModelModelContentLinksField[]
  value: Scalars['JsonField']['output']
}

export type IndustryOverviewPageModelModelContentFieldMultiLocaleField = {
  __typename?: 'IndustryOverviewPageModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<IndustryOverviewPageModelModelContentField>
}

export type IndustryOverviewPageModelModelContentLinksField =
  | ComponentAccordionModelRecord
  | ComponentBulletPointModelRecord
  | ComponentButtonModelRecord
  | FieldSignupSectionRecord
  | PromotionBannerModelRecord
  | VideoModelRecord

export type IndustryOverviewPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<IndustryOverviewPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<IndustryOverviewPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  author?: InputMaybe<LinksFilter>
  bannerImage?: InputMaybe<FileFilter>
  breadcrumbTitle?: InputMaybe<StringFilter>
  contactForm?: InputMaybe<LinkFilter>
  content?: InputMaybe<StructuredTextFilter>
  contentSectionPlot?: InputMaybe<StringFilter>
  contentSectionTitle?: InputMaybe<StringFilter>
  craftsmenProfiles?: InputMaybe<LinkFilter>
  description?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  industryItems?: InputMaybe<LinksFilter>
  industrySectionPlot?: InputMaybe<StringFilter>
  industrySectionTitle?: InputMaybe<StringFilter>
  inspirationSection?: InputMaybe<LinkFilter>
  knowledgeSection?: InputMaybe<LinkFilter>
  mainCallToAction?: InputMaybe<LinkFilter>
  offeringSection?: InputMaybe<LinkFilter>
  presentationSection?: InputMaybe<LinkFilter>
  proDirectoryIndustries?: InputMaybe<StringFilter>
  promotionalBannerSection?: InputMaybe<LinkFilter>
  promotionalSection?: InputMaybe<LinkFilter>
  readingTime?: InputMaybe<IntegerFilter>
  secondaryCallToAction?: InputMaybe<LinkFilter>
  seo?: InputMaybe<LinkFilter>
  slug?: InputMaybe<StringFilter>
  supportSection?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum IndustryOverviewPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BreadcrumbTitleAsc = 'breadcrumbTitle_ASC',
  BreadcrumbTitleDesc = 'breadcrumbTitle_DESC',
  ContentSectionPlotAsc = 'contentSectionPlot_ASC',
  ContentSectionPlotDesc = 'contentSectionPlot_DESC',
  ContentSectionTitleAsc = 'contentSectionTitle_ASC',
  ContentSectionTitleDesc = 'contentSectionTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IndustrySectionPlotAsc = 'industrySectionPlot_ASC',
  IndustrySectionPlotDesc = 'industrySectionPlot_DESC',
  IndustrySectionTitleAsc = 'industrySectionTitle_ASC',
  IndustrySectionTitleDesc = 'industrySectionTitle_DESC',
  ProDirectoryIndustriesAsc = 'proDirectoryIndustries_ASC',
  ProDirectoryIndustriesDesc = 'proDirectoryIndustries_DESC',
  ReadingTimeAsc = 'readingTime_ASC',
  ReadingTimeDesc = 'readingTime_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord = RecordInterface & {
  __typename?: 'IndustryOverviewPageModelRecord'
  _allBreadcrumbTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allContentLocales?: Maybe<IndustryOverviewPageModelModelContentFieldMultiLocaleField[]>
  _allContentSectionPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allContentSectionTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allIndustrySectionPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allIndustrySectionTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  author: AuthorModelRecord[]
  bannerImage?: Maybe<FileField>
  breadcrumbTitle?: Maybe<Scalars['String']['output']>
  contactForm?: Maybe<FieldContactFormModelRecord>
  content?: Maybe<IndustryOverviewPageModelModelContentField>
  contentSectionPlot?: Maybe<Scalars['String']['output']>
  contentSectionTitle?: Maybe<Scalars['String']['output']>
  craftsmenProfiles: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  industryItems: FieldHomePageIndustryItemModelRecord[]
  industrySectionPlot?: Maybe<Scalars['String']['output']>
  industrySectionTitle?: Maybe<Scalars['String']['output']>
  inspirationSection?: Maybe<InspirationSectionModelRecord>
  knowledgeSection?: Maybe<KnowledgeSectionModelRecord>
  mainCallToAction?: Maybe<FieldCallToActionModelRecord>
  offeringSection?: Maybe<OfferingSectionModelRecord>
  presentationSection?: Maybe<PresentationSectionModelRecord>
  proDirectoryIndustries?: Maybe<Scalars['String']['output']>
  promotionalBannerSection?: Maybe<PromotionBannerModelRecord>
  promotionalSection?: Maybe<InspirationSectionModelRecord>
  readingTime?: Maybe<Scalars['IntType']['output']>
  secondaryCallToAction?: Maybe<FieldCallToActionModelRecord>
  seo?: Maybe<SeoModelRecord>
  slug?: Maybe<Scalars['String']['output']>
  supportSection?: Maybe<SupportSectionModelRecord>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllBreadcrumbTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllContentSectionPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllContentSectionTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllIndustrySectionPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllIndustrySectionTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordBreadcrumbTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordContentSectionPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordContentSectionTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordIndustrySectionPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordIndustrySectionTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Overview Page (industry_overview_page_model) */
export type IndustryOverviewPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type IndustryRegionPageModelContentField =
  | FieldContentSectionModelRecord
  | FieldProMapSectionRecord
  | FieldRegionPageProDirectoryRecord
  | FieldSignupSectionRecord
  | PresentationSectionModelRecord
  | ReviewSectionModelRecord
  | SupportSectionModelRecord

/** Linking fields */
export enum IndustryRegionPageModelFieldsReferencingRegionModel {
  IndustryRegionPageRegion = 'industryRegionPage_region',
}

export type IndustryRegionPageModelFilter = {
  AND?: InputMaybe<InputMaybe<IndustryRegionPageModelFilter>[]>
  OR?: InputMaybe<InputMaybe<IndustryRegionPageModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<LinksFilter>
  coverSubtitle?: InputMaybe<StringFilter>
  coverTitle?: InputMaybe<StringFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  industry?: InputMaybe<LinkFilter>
  isDefault?: InputMaybe<BooleanFilter>
  region?: InputMaybe<LinkFilter>
  seo?: InputMaybe<LinkFilter>
}

export enum IndustryRegionPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CoverSubtitleAsc = 'coverSubtitle_ASC',
  CoverSubtitleDesc = 'coverSubtitle_DESC',
  CoverTitleAsc = 'coverTitle_ASC',
  CoverTitleDesc = 'coverTitle_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsDefaultAsc = 'isDefault_ASC',
  IsDefaultDesc = 'isDefault_DESC',
}

/** Record of type Industry Region Page (industry_region_page) */
export type IndustryRegionPageRecord = RecordInterface & {
  __typename?: 'IndustryRegionPageRecord'
  _allCoverSubtitleLocales?: Maybe<StringMultiLocaleField[]>
  _allCoverTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content: IndustryRegionPageModelContentField[]
  coverSubtitle?: Maybe<Scalars['String']['output']>
  coverTitle?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  industry?: Maybe<IndustryModelRecord>
  isDefault: Scalars['BooleanType']['output']
  region?: Maybe<RegionRecord>
  seo?: Maybe<SeoModelRecord>
}

/** Record of type Industry Region Page (industry_region_page) */
export type IndustryRegionPageRecord_AllCoverSubtitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Region Page (industry_region_page) */
export type IndustryRegionPageRecord_AllCoverTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Industry Region Page (industry_region_page) */
export type IndustryRegionPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Region Page (industry_region_page) */
export type IndustryRegionPageRecordCoverSubtitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Industry Region Page (industry_region_page) */
export type IndustryRegionPageRecordCoverTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type IndustrySectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<IndustrySectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<IndustrySectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  items?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum IndustrySectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Industry section (industry_section_model) */
export type IndustrySectionModelRecord = RecordInterface & {
  __typename?: 'IndustrySectionModelRecord'
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  items: FieldHomePageIndustryItemModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Industry section (industry_section_model) */
export type IndustrySectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Industry section (industry_section_model) */
export type IndustrySectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Industry section (industry_section_model) */
export type IndustrySectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Industry section (industry_section_model) */
export type IndustrySectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Industry section (industry_section_model) */
export type IndustrySectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type InspirationItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<InspirationItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<InspirationItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  background?: InputMaybe<FileFilter>
  callToAction?: InputMaybe<LinkFilter>
  chipColor?: InputMaybe<StringFilter>
  chipText?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
}

export enum InspirationItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ChipColorAsc = 'chipColor_ASC',
  ChipColorDesc = 'chipColor_DESC',
  ChipTextAsc = 'chipText_ASC',
  ChipTextDesc = 'chipText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecord = RecordInterface & {
  __typename?: 'InspirationItemModelRecord'
  _allChipTextLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  background?: Maybe<FileField>
  callToAction?: Maybe<FieldCallToActionModelRecord>
  chipColor?: Maybe<Scalars['String']['output']>
  chipText?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecord_AllChipTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecordChipTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Inspiration Item (inspiration_item_model) */
export type InspirationItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type InspirationSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<InspirationSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<InspirationSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  items?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum InspirationSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecord = RecordInterface & {
  __typename?: 'InspirationSectionModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  items: InspirationItemModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Inspiration Section (inspiration_section_model) */
export type InspirationSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter Integer fields */
export type IntegerFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['IntType']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['IntType']['input']>
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['IntType']['input']>
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['IntType']['input']>
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['IntType']['input']>
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['IntType']['input']>
}

export type IntentionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<IntentionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<IntentionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  label?: InputMaybe<StringFilter>
  questions?: InputMaybe<LinksFilter>
  rank?: InputMaybe<IntegerFilter>
  slug?: InputMaybe<StringFilter>
}

export enum IntentionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RankAsc = 'rank_ASC',
  RankDesc = 'rank_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
}

/** Record of type Intention (intention_model) */
export type IntentionModelRecord = RecordInterface & {
  __typename?: 'IntentionModelRecord'
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  label?: Maybe<Scalars['String']['output']>
  questions: QuestionModelRecord[]
  rank?: Maybe<Scalars['IntType']['output']>
  slug?: Maybe<Scalars['String']['output']>
}

/** Record of type Intention (intention_model) */
export type IntentionModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Intention (intention_model) */
export type IntentionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Intention (intention_model) */
export type IntentionModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenCraftsmenRegionDirectoryPageAndRegion = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<CraftsmenRegionDirectoryPageModelFieldsReferencingRegionModel[]>
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<CraftsmenRegionDirectoryPageModelFieldsReferencingRegionModel[]>
}

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenFieldRegionPageProMapAndRegion = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<FieldRegionPageProMapModelFieldsReferencingRegionModel[]>
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<FieldRegionPageProMapModelFieldsReferencingRegionModel[]>
}

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenIndustryRegionPageAndRegion = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<IndustryRegionPageModelFieldsReferencingRegionModel[]>
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<IndustryRegionPageModelFieldsReferencingRegionModel[]>
}

/** Specifies how to filter by linking fields */
export type InverseRelationshipFieldFilterBetweenRegionCraftsmenPageAndRegion = {
  /** Filter linking records that reference current record in at least one of the specified fields */
  anyIn?: InputMaybe<RegionCraftsmenPageModelFieldsReferencingRegionModel[]>
  /** Filter linking records that do not reference current record in any of the specified fields */
  notIn?: InputMaybe<RegionCraftsmenPageModelFieldsReferencingRegionModel[]>
}

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenCraftsmenRegionDirectoryPageAndRegion = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenCraftsmenRegionDirectoryPageAndRegion>
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>
}

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenFieldRegionPageProMapAndRegion = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenFieldRegionPageProMapAndRegion>
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>
}

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenIndustryRegionPageAndRegion = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenIndustryRegionPageAndRegion>
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>
}

/** Specifies how to filter linking records */
export type InverseRelationshipFilterBetweenRegionCraftsmenPageAndRegion = {
  /** Specifies how to filter by linking fields */
  fields?: InputMaybe<InverseRelationshipFieldFilterBetweenRegionCraftsmenPageAndRegion>
  /** Specifies how to filter by linking locales */
  locales?: InputMaybe<LinkingLocalesFilter>
}

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>
  /** Search records with the specified IDs */
  in?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
}

export enum ItemStatus {
  Draft = 'draft',
  Published = 'published',
  Updated = 'updated',
}

export type KnowledgeSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<KnowledgeSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<KnowledgeSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  cta?: InputMaybe<LinkFilter>
  faq?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  plot?: InputMaybe<StringFilter>
  text?: InputMaybe<TextFilter>
  title?: InputMaybe<StringFilter>
}

export enum KnowledgeSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecord = RecordInterface & {
  __typename?: 'KnowledgeSectionModelRecord'
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTextLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  cta?: Maybe<FieldCallToActionModelRecord>
  faq?: Maybe<ComponentAccordionModelRecord>
  id: Scalars['ItemId']['output']
  plot?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecordTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Knowledge Section (knowledge_section_model) */
export type KnowledgeSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records linked to one of the specified records */
  in?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>
  /** Filter records not linked to one of the specified records */
  notIn?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
}

/** Linking locales */
export enum LinkingLocale {
  NonLocalized = '_nonLocalized',
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it',
}

/** Specifies how to filter by linking locales */
export type LinkingLocalesFilter = {
  /** Filter linking records that link to current record in at least one of the specified locales */
  anyIn?: InputMaybe<LinkingLocale[]>
  /** Filter linking records that do not link to current record in any of the specified locales */
  notIn?: InputMaybe<LinkingLocale[]>
}

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
  /** Filter records linked to all of the specified records. The specified values must be Record IDs */
  allIn?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
  /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
  anyIn?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
  /** Search for records with an exact match. The specified values must be Record IDs */
  eq?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
  notIn?: InputMaybe<InputMaybe<Scalars['ItemId']['input']>[]>
}

/** Specifies how to filter by locale */
export type LocalesFilter = {
  /** Filter records that are localized in all the specified locales */
  allIn?: InputMaybe<SiteLocale[]>
  /** Filter records that are localized in at least one of the specified locales */
  anyIn?: InputMaybe<SiteLocale[]>
  /** Filter records that are not localized in any of the specified locales */
  notIn?: InputMaybe<SiteLocale[]>
}

export enum MuxThumbnailFormatType {
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png',
}

export type OfferingCategoryModelModelFilter = {
  AND?: InputMaybe<InputMaybe<OfferingCategoryModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<OfferingCategoryModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  idField?: InputMaybe<IntegerFilter>
  label?: InputMaybe<StringFilter>
  rank?: InputMaybe<IntegerFilter>
}

export enum OfferingCategoryModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdFieldAsc = 'idField_ASC',
  IdFieldDesc = 'idField_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RankAsc = 'rank_ASC',
  RankDesc = 'rank_DESC',
}

/** Record of type Offering Category (offering_category_model) */
export type OfferingCategoryModelRecord = RecordInterface & {
  __typename?: 'OfferingCategoryModelRecord'
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  idField?: Maybe<Scalars['IntType']['output']>
  label?: Maybe<Scalars['String']['output']>
  rank?: Maybe<Scalars['IntType']['output']>
}

/** Record of type Offering Category (offering_category_model) */
export type OfferingCategoryModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Category (offering_category_model) */
export type OfferingCategoryModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Category (offering_category_model) */
export type OfferingCategoryModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type OfferingDataModelModelFilter = {
  AND?: InputMaybe<InputMaybe<OfferingDataModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<OfferingDataModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  categories?: InputMaybe<LinksFilter>
  clientExpectation?: InputMaybe<BooleanFilter>
  clientFacingLabel?: InputMaybe<StringFilter>
  enableExecutionAddress?: InputMaybe<BooleanFilter>
  enableIntentions?: InputMaybe<BooleanFilter>
  enableSiteVisit?: InputMaybe<BooleanFilter>
  executionDates?: InputMaybe<StringFilter>
  firstComeFirstServeSeats?: InputMaybe<IntegerFilter>
  fromPrice?: InputMaybe<FloatFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  idField?: InputMaybe<IntegerFilter>
  industryPage?: InputMaybe<LinkFilter>
  intentions?: InputMaybe<LinksFilter>
  keywords?: InputMaybe<StringFilter>
  knowledgeSection?: InputMaybe<LinkFilter>
  label?: InputMaybe<StringFilter>
  questions?: InputMaybe<LinksFilter>
  servicesField?: InputMaybe<LinksFilter>
  slug?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum OfferingDataModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ClientExpectationAsc = 'clientExpectation_ASC',
  ClientExpectationDesc = 'clientExpectation_DESC',
  ClientFacingLabelAsc = 'clientFacingLabel_ASC',
  ClientFacingLabelDesc = 'clientFacingLabel_DESC',
  EnableExecutionAddressAsc = 'enableExecutionAddress_ASC',
  EnableExecutionAddressDesc = 'enableExecutionAddress_DESC',
  EnableIntentionsAsc = 'enableIntentions_ASC',
  EnableIntentionsDesc = 'enableIntentions_DESC',
  EnableSiteVisitAsc = 'enableSiteVisit_ASC',
  EnableSiteVisitDesc = 'enableSiteVisit_DESC',
  ExecutionDatesAsc = 'executionDates_ASC',
  ExecutionDatesDesc = 'executionDates_DESC',
  FirstComeFirstServeSeatsAsc = 'firstComeFirstServeSeats_ASC',
  FirstComeFirstServeSeatsDesc = 'firstComeFirstServeSeats_DESC',
  FromPriceAsc = 'fromPrice_ASC',
  FromPriceDesc = 'fromPrice_DESC',
  IdFieldAsc = 'idField_ASC',
  IdFieldDesc = 'idField_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeywordsAsc = 'keywords_ASC',
  KeywordsDesc = 'keywords_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecord = RecordInterface & {
  __typename?: 'OfferingDataModelRecord'
  _allClientFacingLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allKeywordsLocales?: Maybe<StringMultiLocaleField[]>
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  categories: OfferingCategoryModelRecord[]
  clientExpectation: Scalars['BooleanType']['output']
  clientFacingLabel?: Maybe<Scalars['String']['output']>
  enableExecutionAddress: Scalars['BooleanType']['output']
  enableIntentions: Scalars['BooleanType']['output']
  enableSiteVisit: Scalars['BooleanType']['output']
  executionDates?: Maybe<Scalars['String']['output']>
  firstComeFirstServeSeats?: Maybe<Scalars['IntType']['output']>
  fromPrice?: Maybe<Scalars['FloatType']['output']>
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  idField?: Maybe<Scalars['IntType']['output']>
  industryPage?: Maybe<IndustryOverviewPageModelRecord>
  intentions: IntentionModelRecord[]
  keywords?: Maybe<Scalars['String']['output']>
  knowledgeSection?: Maybe<KnowledgeSectionModelRecord>
  label?: Maybe<Scalars['String']['output']>
  questions: QuestionModelRecord[]
  servicesField: ServiceModelRecord[]
  slug?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecord_AllClientFacingLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecord_AllKeywordsLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecordClientFacingLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecordKeywordsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering (offering_data_model) */
export type OfferingDataModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type OfferingDetailPageModelModelContentBlocksField = StructuredTextAssetRecord | TableRecord

export type OfferingDetailPageModelModelContentField = {
  __typename?: 'OfferingDetailPageModelModelContentField'
  blocks: OfferingDetailPageModelModelContentBlocksField[]
  links: OfferingDetailPageModelModelContentLinksField[]
  value: Scalars['JsonField']['output']
}

export type OfferingDetailPageModelModelContentFieldMultiLocaleField = {
  __typename?: 'OfferingDetailPageModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<OfferingDetailPageModelModelContentField>
}

export type OfferingDetailPageModelModelContentLinksField =
  | ComponentAccordionModelRecord
  | ComponentBulletPointModelRecord
  | ComponentButtonModelRecord
  | FieldSignupSectionRecord
  | PromotionBannerModelRecord
  | VideoModelRecord

export type OfferingDetailPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<OfferingDetailPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<OfferingDetailPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  author?: InputMaybe<LinksFilter>
  bannerImage?: InputMaybe<FileFilter>
  breadcrumbTitle?: InputMaybe<StringFilter>
  contactForm?: InputMaybe<LinkFilter>
  content?: InputMaybe<StructuredTextFilter>
  contentPlot?: InputMaybe<StringFilter>
  contentTitle?: InputMaybe<StringFilter>
  craftsmenProfiles?: InputMaybe<LinkFilter>
  description?: InputMaybe<StringFilter>
  howItWorksSection?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  inspirationSection?: InputMaybe<LinkFilter>
  mainCallToAction?: InputMaybe<LinkFilter>
  offering?: InputMaybe<LinkFilter>
  parentField?: InputMaybe<LinkFilter>
  presentationSection?: InputMaybe<LinkFilter>
  promotionalBannerSection?: InputMaybe<LinkFilter>
  readingTime?: InputMaybe<IntegerFilter>
  relatedOfferingsSection?: InputMaybe<LinkFilter>
  secondaryCallToAction?: InputMaybe<LinkFilter>
  seo?: InputMaybe<LinkFilter>
  slug?: InputMaybe<StringFilter>
  supportSection?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum OfferingDetailPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BreadcrumbTitleAsc = 'breadcrumbTitle_ASC',
  BreadcrumbTitleDesc = 'breadcrumbTitle_DESC',
  ContentPlotAsc = 'contentPlot_ASC',
  ContentPlotDesc = 'contentPlot_DESC',
  ContentTitleAsc = 'contentTitle_ASC',
  ContentTitleDesc = 'contentTitle_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReadingTimeAsc = 'readingTime_ASC',
  ReadingTimeDesc = 'readingTime_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type OfferingDetailPageModelModelParentFieldField = IndustryOverviewPageModelRecord | StaticPageModelRecord

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord = RecordInterface & {
  __typename?: 'OfferingDetailPageModelRecord'
  _allBreadcrumbTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allContentLocales?: Maybe<OfferingDetailPageModelModelContentFieldMultiLocaleField[]>
  _allContentPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allContentTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  author: AuthorModelRecord[]
  bannerImage?: Maybe<FileField>
  breadcrumbTitle?: Maybe<Scalars['String']['output']>
  contactForm?: Maybe<FieldContactFormModelRecord>
  content?: Maybe<OfferingDetailPageModelModelContentField>
  contentPlot?: Maybe<Scalars['String']['output']>
  contentTitle?: Maybe<Scalars['String']['output']>
  craftsmenProfiles: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  howItWorksSection?: Maybe<HowItWorksSectionModelRecord>
  id: Scalars['ItemId']['output']
  inspirationSection?: Maybe<InspirationSectionModelRecord>
  mainCallToAction?: Maybe<FieldCallToActionModelRecord>
  offering?: Maybe<OfferingModelRecord>
  parentField?: Maybe<OfferingDetailPageModelModelParentFieldField>
  presentationSection?: Maybe<PresentationSectionModelRecord>
  promotionalBannerSection?: Maybe<PromotionBannerModelRecord>
  readingTime?: Maybe<Scalars['IntType']['output']>
  relatedOfferingsSection?: Maybe<OfferingSectionModelRecord>
  secondaryCallToAction?: Maybe<FieldCallToActionModelRecord>
  seo?: Maybe<SeoModelRecord>
  slug?: Maybe<Scalars['String']['output']>
  supportSection?: Maybe<SupportSectionModelRecord>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord_AllBreadcrumbTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord_AllContentPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord_AllContentTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecordBreadcrumbTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecordContentPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecordContentTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Detail Page (offering_detail_page_model) */
export type OfferingDetailPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type OfferingModelModelFilter = {
  AND?: InputMaybe<InputMaybe<OfferingModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<OfferingModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  offeringId?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum OfferingModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OfferingIdAsc = 'offeringId_ASC',
  OfferingIdDesc = 'offeringId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Offering (offering_model) */
export type OfferingModelRecord = RecordInterface & {
  __typename?: 'OfferingModelRecord'
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  offeringId?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Offering (offering_model) */
export type OfferingModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Offering (offering_model) */
export type OfferingModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Offering (offering_model) */
export type OfferingModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type OfferingPageModelModelContentField =
  | FieldContentSectionModelRecord
  | FieldProDirectorySectionModelRecord
  | FieldSignupSectionRecord
  | KnowledgeSectionModelRecord
  | PresentationSectionModelRecord
  | ReviewSectionModelRecord
  | SupportSectionModelRecord

export type OfferingPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<OfferingPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<OfferingPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<LinksFilter>
  coverSubtitle?: InputMaybe<StringFilter>
  coverTitle?: InputMaybe<StringFilter>
  displayCoverSellingPointBar?: InputMaybe<BooleanFilter>
  id?: InputMaybe<ItemIdFilter>
  offering?: InputMaybe<LinkFilter>
  seo?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum OfferingPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CoverSubtitleAsc = 'coverSubtitle_ASC',
  CoverSubtitleDesc = 'coverSubtitle_DESC',
  CoverTitleAsc = 'coverTitle_ASC',
  CoverTitleDesc = 'coverTitle_DESC',
  DisplayCoverSellingPointBarAsc = 'displayCoverSellingPointBar_ASC',
  DisplayCoverSellingPointBarDesc = 'displayCoverSellingPointBar_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type Offering Page (offering_page_model) */
export type OfferingPageModelRecord = RecordInterface & {
  __typename?: 'OfferingPageModelRecord'
  _allCoverSubtitleLocales?: Maybe<StringMultiLocaleField[]>
  _allCoverTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content: OfferingPageModelModelContentField[]
  coverSubtitle?: Maybe<Scalars['String']['output']>
  coverTitle?: Maybe<Scalars['String']['output']>
  displayCoverSellingPointBar: Scalars['BooleanType']['output']
  id: Scalars['ItemId']['output']
  offering?: Maybe<OfferingDataModelRecord>
  seo?: Maybe<SeoModelRecord>
  title?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type Offering Page (offering_page_model) */
export type OfferingPageModelRecord_AllCoverSubtitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Page (offering_page_model) */
export type OfferingPageModelRecord_AllCoverTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Offering Page (offering_page_model) */
export type OfferingPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Page (offering_page_model) */
export type OfferingPageModelRecordCoverSubtitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Offering Page (offering_page_model) */
export type OfferingPageModelRecordCoverTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type OfferingSectionItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<OfferingSectionItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<OfferingSectionItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<StringFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  target?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum OfferingSectionItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type OfferingSectionItemModelModelTargetField = OfferingDetailPageModelRecord | OfferingModelRecord

/** Record of type [Field] Offering Section Item (offering_section_item_model) */
export type OfferingSectionItemModelRecord = RecordInterface & {
  __typename?: 'OfferingSectionItemModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  target?: Maybe<OfferingSectionItemModelModelTargetField>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Offering Section Item (offering_section_item_model) */
export type OfferingSectionItemModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Offering Section Item (offering_section_item_model) */
export type OfferingSectionItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Offering Section Item (offering_section_item_model) */
export type OfferingSectionItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Offering Section Item (offering_section_item_model) */
export type OfferingSectionItemModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Offering Section Item (offering_section_item_model) */
export type OfferingSectionItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type OfferingSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<OfferingSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<OfferingSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  items2?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export type OfferingSectionModelModelItems2Field =
  | OfferingDetailPageModelRecord
  | OfferingModelRecord
  | OfferingSectionItemModelRecord

export enum OfferingSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Offering Section (offering_section_model) */
export type OfferingSectionModelRecord = RecordInterface & {
  __typename?: 'OfferingSectionModelRecord'
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  items2: OfferingSectionModelModelItems2Field[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Offering Section (offering_section_model) */
export type OfferingSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Offering Section (offering_section_model) */
export type OfferingSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Offering Section (offering_section_model) */
export type OfferingSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Offering Section (offering_section_model) */
export type OfferingSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Offering Section (offering_section_model) */
export type OfferingSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>
}

export type PartnerItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<PartnerItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<PartnerItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  logo?: InputMaybe<FileFilter>
  target?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum PartnerItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PartnerItemModelModelTargetField =
  | ArticleModelRecord
  | CategoryLandingPageModelRecord
  | StaticPageModelRecord

/** Record of type [Field] Partner item (partner_item_model) */
export type PartnerItemModelRecord = RecordInterface & {
  __typename?: 'PartnerItemModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  logo?: Maybe<FileField>
  target?: Maybe<PartnerItemModelModelTargetField>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Partner item (partner_item_model) */
export type PartnerItemModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Partner item (partner_item_model) */
export type PartnerItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Partner item (partner_item_model) */
export type PartnerItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Partner item (partner_item_model) */
export type PartnerItemModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Partner item (partner_item_model) */
export type PartnerItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type PartnerSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<PartnerSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<PartnerSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  items?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum PartnerSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Partner section (partner_section_model) */
export type PartnerSectionModelRecord = RecordInterface & {
  __typename?: 'PartnerSectionModelRecord'
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  items: PartnerItemModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Partner section (partner_section_model) */
export type PartnerSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Partner section (partner_section_model) */
export type PartnerSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Partner section (partner_section_model) */
export type PartnerSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Partner section (partner_section_model) */
export type PartnerSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Partner section (partner_section_model) */
export type PartnerSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type PresentationSectionItemModelModelContentField = {
  __typename?: 'PresentationSectionItemModelModelContentField'
  blocks: StructuredTextAssetRecord[]
  links: Scalars['String']['output'][]
  value: Scalars['JsonField']['output']
}

export type PresentationSectionItemModelModelContentFieldMultiLocaleField = {
  __typename?: 'PresentationSectionItemModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<PresentationSectionItemModelModelContentField>
}

export type PresentationSectionItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<PresentationSectionItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<PresentationSectionItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<StructuredTextFilter>
  id?: InputMaybe<ItemIdFilter>
  image?: InputMaybe<FileFilter>
  primaryCta?: InputMaybe<LinkFilter>
  secondaryCta?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
  youtubeId?: InputMaybe<StringFilter>
}

export enum PresentationSectionItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  YoutubeIdAsc = 'youtubeId_ASC',
  YoutubeIdDesc = 'youtubeId_DESC',
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecord = RecordInterface & {
  __typename?: 'PresentationSectionItemModelRecord'
  _allContentLocales?: Maybe<PresentationSectionItemModelModelContentFieldMultiLocaleField[]>
  _allPrimaryCtaLocales?: Maybe<FieldCallToActionModelRecordMultiLocaleField[]>
  _allSecondaryCtaLocales?: Maybe<FieldCallToActionModelRecordMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content?: Maybe<PresentationSectionItemModelModelContentField>
  id: Scalars['ItemId']['output']
  image?: Maybe<FileField>
  primaryCta?: Maybe<FieldCallToActionModelRecord>
  secondaryCta?: Maybe<FieldCallToActionModelRecord>
  title?: Maybe<Scalars['String']['output']>
  youtubeId?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecord_AllPrimaryCtaLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecord_AllSecondaryCtaLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecordPrimaryCtaArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecordSecondaryCtaArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Presentation Section Item (presentation_section_item_model) */
export type PresentationSectionItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type PresentationSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<PresentationSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<PresentationSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  items?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum PresentationSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Presentation Section (presentation_section_model) */
export type PresentationSectionModelRecord = RecordInterface & {
  __typename?: 'PresentationSectionModelRecord'
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  items: PresentationSectionItemModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Presentation Section (presentation_section_model) */
export type PresentationSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Presentation Section (presentation_section_model) */
export type PresentationSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Presentation Section (presentation_section_model) */
export type PresentationSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Presentation Section (presentation_section_model) */
export type PresentationSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Presentation Section (presentation_section_model) */
export type PresentationSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ProDirectoryPageModelModelContentField =
  | FieldSignupSectionRecord
  | InspirationSectionModelRecord
  | KnowledgeSectionModelRecord
  | PromotionBannerModelRecord
  | ReviewSectionModelRecord

export type ProDirectoryPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ProDirectoryPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ProDirectoryPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<LinksFilter>
  id?: InputMaybe<ItemIdFilter>
  title?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum ProDirectoryPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type Pro Directory Page (pro_directory_page_model) */
export type ProDirectoryPageModelRecord = RecordInterface & {
  __typename?: 'ProDirectoryPageModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content: ProDirectoryPageModelModelContentField[]
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type Pro Directory Page (pro_directory_page_model) */
export type ProDirectoryPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type ProRegisterPlanPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ProRegisterPlanPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ProRegisterPlanPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<TextFilter>
  faq?: InputMaybe<LinkFilter>
  headerImage?: InputMaybe<FileFilter>
  id?: InputMaybe<ItemIdFilter>
  promotionalBanner?: InputMaybe<LinkFilter>
  sellingPoints?: InputMaybe<LinkFilter>
  slug?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum ProRegisterPlanPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type Pro Register Plan Page (pro_register_plan_page_model) */
export type ProRegisterPlanPageModelRecord = RecordInterface & {
  __typename?: 'ProRegisterPlanPageModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  faq?: Maybe<ComponentAccordionModelRecord>
  headerImage?: Maybe<FileField>
  id: Scalars['ItemId']['output']
  promotionalBanner?: Maybe<PromotionBannerModelRecord>
  sellingPoints?: Maybe<ComponentTabModelRecord>
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Pro Register Plan Page (pro_register_plan_page_model) */
export type ProRegisterPlanPageModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Pro Register Plan Page (pro_register_plan_page_model) */
export type ProRegisterPlanPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Pro Register Plan Page (pro_register_plan_page_model) */
export type ProRegisterPlanPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Pro Register Plan Page (pro_register_plan_page_model) */
export type ProRegisterPlanPageModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Pro Register Plan Page (pro_register_plan_page_model) */
export type ProRegisterPlanPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type PromotionBannerModelModelFilter = {
  AND?: InputMaybe<InputMaybe<PromotionBannerModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<PromotionBannerModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  background?: InputMaybe<FileFilter>
  callToAction?: InputMaybe<LinkFilter>
  content?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  partnerLogo?: InputMaybe<FileFilter>
  title?: InputMaybe<StringFilter>
}

export enum PromotionBannerModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Promotion Banner (promotion_banner_model) */
export type PromotionBannerModelRecord = RecordInterface & {
  __typename?: 'PromotionBannerModelRecord'
  _allContentLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  background?: Maybe<FileField>
  callToAction?: Maybe<FieldCallToActionModelRecord>
  content?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  partnerLogo?: Maybe<FileField>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Promotion Banner (promotion_banner_model) */
export type PromotionBannerModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Promotion Banner (promotion_banner_model) */
export type PromotionBannerModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Promotion Banner (promotion_banner_model) */
export type PromotionBannerModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Promotion Banner (promotion_banner_model) */
export type PromotionBannerModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Promotion Banner (promotion_banner_model) */
export type PromotionBannerModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>
}

/** The query root for this schema */
export type Query = {
  __typename?: 'Query'
  /** Returns meta information regarding a record collection */
  _allAnnouncementBannerV2ModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allAnswerModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allArticleCtaCardItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allArticleCtaCardModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allArticleModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allAuthorModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allAutomaticArticleSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allCalculatorModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allCategoryLandingPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allClientReviewModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentAccordionElementModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentAccordionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentBulletPointModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentButtonModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentDropdownMenuItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentDropdownMenuModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentTabElementModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allComponentTabModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allEmergencyLandingPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allEmergencyOfferingModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allEmergencyOfferingSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldCallToActionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldContactFormModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldContentSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldDynamicProDirectorySectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldHomePageIndustryItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldMarketingTopicsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldProDirectorySectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldProMapSectionsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldRegionPageProDirectoriesMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldRegionPageProMapsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFieldSignupSectionsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allFontAwesomeIconModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allHomePageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allHowItWorksItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allHowItWorksSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allIndustryModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allIndustryOverviewPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allIndustryRegionPagesMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allIndustrySectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allInspirationItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allInspirationSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allIntentionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allKnowledgeSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allOfferingCategoryModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allOfferingDataModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allOfferingDetailPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allOfferingModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allOfferingPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allOfferingSectionItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allOfferingSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allPartnerItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allPartnerSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allPresentationSectionItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allPresentationSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allProDirectoryPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allProRegisterPlanPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allPromotionBannerModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allQuestionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allRedirectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allRegionCraftsmenPagesMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allRegionsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allReviewSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allSeoModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allServiceItemModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allServiceModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allServiceOverviewPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allServicesSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allSkillModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allStaticPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allSupportSectionModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allTopicOverviewPageModelsMeta: CollectionMetadata
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allVideoModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allVoucherPriceBasedTermModelsMeta: CollectionMetadata
  /** Returns meta information regarding a record collection */
  _allVoucherServiceFeeTermModelsMeta: CollectionMetadata
  /** Returns the single instance record */
  _site: Site
  /** Returns a collection of records */
  allAnnouncementBannerV2Models: AnnouncementBannerV2ModelRecord[]
  /** Returns a collection of records */
  allAnswerModels: AnswerModelRecord[]
  /** Returns a collection of records */
  allArticleCtaCardItemModels: ArticleCtaCardItemModelRecord[]
  /** Returns a collection of records */
  allArticleCtaCardModels: ArticleCtaCardModelRecord[]
  /** Returns a collection of records */
  allArticleModels: ArticleModelRecord[]
  /** Returns a collection of records */
  allAuthorModels: AuthorModelRecord[]
  /** Returns a collection of records */
  allAutomaticArticleSectionModels: AutomaticArticleSectionModelRecord[]
  /** Returns a collection of records */
  allCalculatorModels: CalculatorModelRecord[]
  /** Returns a collection of records */
  allCategoryLandingPageModels: CategoryLandingPageModelRecord[]
  /** Returns a collection of records */
  allClientReviewModels: ClientReviewModelRecord[]
  /** Returns a collection of records */
  allComponentAccordionElementModels: ComponentAccordionElementModelRecord[]
  /** Returns a collection of records */
  allComponentAccordionModels: ComponentAccordionModelRecord[]
  /** Returns a collection of records */
  allComponentBulletPointModels: ComponentBulletPointModelRecord[]
  /** Returns a collection of records */
  allComponentButtonModels: ComponentButtonModelRecord[]
  /** Returns a collection of records */
  allComponentDropdownMenuItemModels: ComponentDropdownMenuItemModelRecord[]
  /** Returns a collection of records */
  allComponentDropdownMenuModels: ComponentDropdownMenuModelRecord[]
  /** Returns a collection of records */
  allComponentTabElementModels: ComponentTabElementModelRecord[]
  /** Returns a collection of records */
  allComponentTabModels: ComponentTabModelRecord[]
  /** Returns a collection of records */
  allEmergencyLandingPageModels: EmergencyLandingPageModelRecord[]
  /** Returns a collection of records */
  allEmergencyOfferingModels: EmergencyOfferingModelRecord[]
  /** Returns a collection of records */
  allEmergencyOfferingSectionModels: EmergencyOfferingSectionModelRecord[]
  /** Returns a collection of records */
  allFieldCallToActionModels: FieldCallToActionModelRecord[]
  /** Returns a collection of records */
  allFieldContactFormModels: FieldContactFormModelRecord[]
  /** Returns a collection of records */
  allFieldContentSectionModels: FieldContentSectionModelRecord[]
  /** Returns a collection of records */
  allFieldDynamicProDirectorySectionModels: FieldDynamicProDirectorySectionModelRecord[]
  /** Returns a collection of records */
  allFieldHomePageIndustryItemModels: FieldHomePageIndustryItemModelRecord[]
  /** Returns a collection of records */
  allFieldMarketingTopics: FieldMarketingTopicRecord[]
  /** Returns a collection of records */
  allFieldProDirectorySectionModels: FieldProDirectorySectionModelRecord[]
  /** Returns a collection of records */
  allFieldProMapSections: FieldProMapSectionRecord[]
  /** Returns a collection of records */
  allFieldRegionPageProDirectories: FieldRegionPageProDirectoryRecord[]
  /** Returns a collection of records */
  allFieldRegionPageProMaps: FieldRegionPageProMapRecord[]
  /** Returns a collection of records */
  allFieldSignupSections: FieldSignupSectionRecord[]
  /** Returns a collection of records */
  allFontAwesomeIconModels: FontAwesomeIconModelRecord[]
  /** Returns a collection of records */
  allHomePageModels: HomePageModelRecord[]
  /** Returns a collection of records */
  allHowItWorksItemModels: HowItWorksItemModelRecord[]
  /** Returns a collection of records */
  allHowItWorksSectionModels: HowItWorksSectionModelRecord[]
  /** Returns a collection of records */
  allIndustryModels: IndustryModelRecord[]
  /** Returns a collection of records */
  allIndustryOverviewPageModels: IndustryOverviewPageModelRecord[]
  /** Returns a collection of records */
  allIndustryRegionPages: IndustryRegionPageRecord[]
  /** Returns a collection of records */
  allIndustrySectionModels: IndustrySectionModelRecord[]
  /** Returns a collection of records */
  allInspirationItemModels: InspirationItemModelRecord[]
  /** Returns a collection of records */
  allInspirationSectionModels: InspirationSectionModelRecord[]
  /** Returns a collection of records */
  allIntentionModels: IntentionModelRecord[]
  /** Returns a collection of records */
  allKnowledgeSectionModels: KnowledgeSectionModelRecord[]
  /** Returns a collection of records */
  allOfferingCategoryModels: OfferingCategoryModelRecord[]
  /** Returns a collection of records */
  allOfferingDataModels: OfferingDataModelRecord[]
  /** Returns a collection of records */
  allOfferingDetailPageModels: OfferingDetailPageModelRecord[]
  /** Returns a collection of records */
  allOfferingModels: OfferingModelRecord[]
  /** Returns a collection of records */
  allOfferingPageModels: OfferingPageModelRecord[]
  /** Returns a collection of records */
  allOfferingSectionItemModels: OfferingSectionItemModelRecord[]
  /** Returns a collection of records */
  allOfferingSectionModels: OfferingSectionModelRecord[]
  /** Returns a collection of records */
  allPartnerItemModels: PartnerItemModelRecord[]
  /** Returns a collection of records */
  allPartnerSectionModels: PartnerSectionModelRecord[]
  /** Returns a collection of records */
  allPresentationSectionItemModels: PresentationSectionItemModelRecord[]
  /** Returns a collection of records */
  allPresentationSectionModels: PresentationSectionModelRecord[]
  /** Returns a collection of records */
  allProDirectoryPageModels: ProDirectoryPageModelRecord[]
  /** Returns a collection of records */
  allProRegisterPlanPageModels: ProRegisterPlanPageModelRecord[]
  /** Returns a collection of records */
  allPromotionBannerModels: PromotionBannerModelRecord[]
  /** Returns a collection of records */
  allQuestionModels: QuestionModelRecord[]
  /** Returns a collection of records */
  allRedirectionModels: RedirectionModelRecord[]
  /** Returns a collection of records */
  allRegionCraftsmenPages: RegionCraftsmenPageRecord[]
  /** Returns a collection of records */
  allRegions: RegionRecord[]
  /** Returns a collection of records */
  allReviewSectionModels: ReviewSectionModelRecord[]
  /** Returns a collection of records */
  allSeoModels: SeoModelRecord[]
  /** Returns a collection of records */
  allServiceItemModels: ServiceItemModelRecord[]
  /** Returns a collection of records */
  allServiceModels: ServiceModelRecord[]
  /** Returns a collection of records */
  allServiceOverviewPageModels: ServiceOverviewPageModelRecord[]
  /** Returns a collection of records */
  allServicesSectionModels: ServicesSectionModelRecord[]
  /** Returns a collection of records */
  allSkillModels: SkillModelRecord[]
  /** Returns a collection of records */
  allStaticPageModels: StaticPageModelRecord[]
  /** Returns a collection of records */
  allSupportSectionModels: SupportSectionModelRecord[]
  /** Returns a collection of records */
  allTopicOverviewPageModels: TopicOverviewPageModelRecord[]
  /** Returns a collection of assets */
  allUploads: FileField[]
  /** Returns a collection of records */
  allVideoModels: VideoModelRecord[]
  /** Returns a collection of records */
  allVoucherPriceBasedTermModels: VoucherPriceBasedTermModelRecord[]
  /** Returns a collection of records */
  allVoucherServiceFeeTermModels: VoucherServiceFeeTermModelRecord[]
  /** Returns a specific record */
  announcementBannerV2Model?: Maybe<AnnouncementBannerV2ModelRecord>
  /** Returns a specific record */
  answerModel?: Maybe<AnswerModelRecord>
  /** Returns a specific record */
  articleCtaCardItemModel?: Maybe<ArticleCtaCardItemModelRecord>
  /** Returns a specific record */
  articleCtaCardModel?: Maybe<ArticleCtaCardModelRecord>
  /** Returns a specific record */
  articleModel?: Maybe<ArticleModelRecord>
  /** Returns a specific record */
  authorModel?: Maybe<AuthorModelRecord>
  /** Returns a specific record */
  automaticArticleSectionModel?: Maybe<AutomaticArticleSectionModelRecord>
  /** Returns a specific record */
  calculatorModel?: Maybe<CalculatorModelRecord>
  /** Returns a specific record */
  categoryLandingPageModel?: Maybe<CategoryLandingPageModelRecord>
  /** Returns a specific record */
  clientReviewModel?: Maybe<ClientReviewModelRecord>
  /** Returns a specific record */
  componentAccordionElementModel?: Maybe<ComponentAccordionElementModelRecord>
  /** Returns a specific record */
  componentAccordionModel?: Maybe<ComponentAccordionModelRecord>
  /** Returns a specific record */
  componentBulletPointModel?: Maybe<ComponentBulletPointModelRecord>
  /** Returns a specific record */
  componentButtonModel?: Maybe<ComponentButtonModelRecord>
  /** Returns a specific record */
  componentDropdownMenuItemModel?: Maybe<ComponentDropdownMenuItemModelRecord>
  /** Returns a specific record */
  componentDropdownMenuModel?: Maybe<ComponentDropdownMenuModelRecord>
  /** Returns a specific record */
  componentTabElementModel?: Maybe<ComponentTabElementModelRecord>
  /** Returns a specific record */
  componentTabModel?: Maybe<ComponentTabModelRecord>
  /** Returns the single instance record */
  craftsmenRegionDirectoryPage?: Maybe<CraftsmenRegionDirectoryPageRecord>
  /** Returns a specific record */
  emergencyLandingPageModel?: Maybe<EmergencyLandingPageModelRecord>
  /** Returns a specific record */
  emergencyOfferingModel?: Maybe<EmergencyOfferingModelRecord>
  /** Returns a specific record */
  emergencyOfferingSectionModel?: Maybe<EmergencyOfferingSectionModelRecord>
  /** Returns a specific record */
  fieldCallToActionModel?: Maybe<FieldCallToActionModelRecord>
  /** Returns a specific record */
  fieldContactFormModel?: Maybe<FieldContactFormModelRecord>
  /** Returns a specific record */
  fieldContentSectionModel?: Maybe<FieldContentSectionModelRecord>
  /** Returns a specific record */
  fieldDynamicProDirectorySectionModel?: Maybe<FieldDynamicProDirectorySectionModelRecord>
  /** Returns a specific record */
  fieldHomePageIndustryItemModel?: Maybe<FieldHomePageIndustryItemModelRecord>
  /** Returns a specific record */
  fieldMarketingTopic?: Maybe<FieldMarketingTopicRecord>
  /** Returns a specific record */
  fieldProDirectorySectionModel?: Maybe<FieldProDirectorySectionModelRecord>
  /** Returns a specific record */
  fieldProMapSection?: Maybe<FieldProMapSectionRecord>
  /** Returns a specific record */
  fieldRegionPageProDirectory?: Maybe<FieldRegionPageProDirectoryRecord>
  /** Returns a specific record */
  fieldRegionPageProMap?: Maybe<FieldRegionPageProMapRecord>
  /** Returns a specific record */
  fieldSignupSection?: Maybe<FieldSignupSectionRecord>
  /** Returns a specific record */
  fontAwesomeIconModel?: Maybe<FontAwesomeIconModelRecord>
  /** Returns a specific record */
  homePageModel?: Maybe<HomePageModelRecord>
  /** Returns a specific record */
  howItWorksItemModel?: Maybe<HowItWorksItemModelRecord>
  /** Returns a specific record */
  howItWorksSectionModel?: Maybe<HowItWorksSectionModelRecord>
  /** Returns a specific record */
  industryModel?: Maybe<IndustryModelRecord>
  /** Returns a specific record */
  industryOverviewPageModel?: Maybe<IndustryOverviewPageModelRecord>
  /** Returns a specific record */
  industryRegionPage?: Maybe<IndustryRegionPageRecord>
  /** Returns a specific record */
  industrySectionModel?: Maybe<IndustrySectionModelRecord>
  /** Returns a specific record */
  inspirationItemModel?: Maybe<InspirationItemModelRecord>
  /** Returns a specific record */
  inspirationSectionModel?: Maybe<InspirationSectionModelRecord>
  /** Returns a specific record */
  intentionModel?: Maybe<IntentionModelRecord>
  /** Returns a specific record */
  knowledgeSectionModel?: Maybe<KnowledgeSectionModelRecord>
  /** Returns a specific record */
  offeringCategoryModel?: Maybe<OfferingCategoryModelRecord>
  /** Returns a specific record */
  offeringDataModel?: Maybe<OfferingDataModelRecord>
  /** Returns a specific record */
  offeringDetailPageModel?: Maybe<OfferingDetailPageModelRecord>
  /** Returns a specific record */
  offeringModel?: Maybe<OfferingModelRecord>
  /** Returns a specific record */
  offeringPageModel?: Maybe<OfferingPageModelRecord>
  /** Returns a specific record */
  offeringSectionItemModel?: Maybe<OfferingSectionItemModelRecord>
  /** Returns a specific record */
  offeringSectionModel?: Maybe<OfferingSectionModelRecord>
  /** Returns a specific record */
  partnerItemModel?: Maybe<PartnerItemModelRecord>
  /** Returns a specific record */
  partnerSectionModel?: Maybe<PartnerSectionModelRecord>
  /** Returns a specific record */
  presentationSectionItemModel?: Maybe<PresentationSectionItemModelRecord>
  /** Returns a specific record */
  presentationSectionModel?: Maybe<PresentationSectionModelRecord>
  /** Returns a specific record */
  proDirectoryPageModel?: Maybe<ProDirectoryPageModelRecord>
  /** Returns a specific record */
  proRegisterPlanPageModel?: Maybe<ProRegisterPlanPageModelRecord>
  /** Returns a specific record */
  promotionBannerModel?: Maybe<PromotionBannerModelRecord>
  /** Returns a specific record */
  questionModel?: Maybe<QuestionModelRecord>
  /** Returns a specific record */
  redirectionModel?: Maybe<RedirectionModelRecord>
  /** Returns a specific record */
  region?: Maybe<RegionRecord>
  /** Returns a specific record */
  regionCraftsmenPage?: Maybe<RegionCraftsmenPageRecord>
  /** Returns a specific record */
  reviewSectionModel?: Maybe<ReviewSectionModelRecord>
  /** Returns a specific record */
  seoModel?: Maybe<SeoModelRecord>
  /** Returns a specific record */
  serviceItemModel?: Maybe<ServiceItemModelRecord>
  /** Returns a specific record */
  serviceModel?: Maybe<ServiceModelRecord>
  /** Returns a specific record */
  serviceOverviewPageModel?: Maybe<ServiceOverviewPageModelRecord>
  /** Returns a specific record */
  servicesSectionModel?: Maybe<ServicesSectionModelRecord>
  /** Returns a specific record */
  skillModel?: Maybe<SkillModelRecord>
  /** Returns a specific record */
  staticPageModel?: Maybe<StaticPageModelRecord>
  /** Returns a specific record */
  supportSectionModel?: Maybe<SupportSectionModelRecord>
  /** Returns a specific record */
  topicOverviewPageModel?: Maybe<TopicOverviewPageModelRecord>
  /** Returns a specific asset */
  upload?: Maybe<FileField>
  /** Returns a specific record */
  videoModel?: Maybe<VideoModelRecord>
  /** Returns a specific record */
  voucherPriceBasedTermModel?: Maybe<VoucherPriceBasedTermModelRecord>
  /** Returns a specific record */
  voucherServiceFeeTermModel?: Maybe<VoucherServiceFeeTermModelRecord>
}

/** The query root for this schema */
export type Query_AllAnnouncementBannerV2ModelsMetaArgs = {
  filter?: InputMaybe<AnnouncementBannerV2ModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllAnswerModelsMetaArgs = {
  filter?: InputMaybe<AnswerModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllArticleCtaCardItemModelsMetaArgs = {
  filter?: InputMaybe<ArticleCtaCardItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllArticleCtaCardModelsMetaArgs = {
  filter?: InputMaybe<ArticleCtaCardModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllArticleModelsMetaArgs = {
  filter?: InputMaybe<ArticleModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllAuthorModelsMetaArgs = {
  filter?: InputMaybe<AuthorModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllAutomaticArticleSectionModelsMetaArgs = {
  filter?: InputMaybe<AutomaticArticleSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllCalculatorModelsMetaArgs = {
  filter?: InputMaybe<CalculatorModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllCategoryLandingPageModelsMetaArgs = {
  filter?: InputMaybe<CategoryLandingPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllClientReviewModelsMetaArgs = {
  filter?: InputMaybe<ClientReviewModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentAccordionElementModelsMetaArgs = {
  filter?: InputMaybe<ComponentAccordionElementModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentAccordionModelsMetaArgs = {
  filter?: InputMaybe<ComponentAccordionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentBulletPointModelsMetaArgs = {
  filter?: InputMaybe<ComponentBulletPointModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentButtonModelsMetaArgs = {
  filter?: InputMaybe<ComponentButtonModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentDropdownMenuItemModelsMetaArgs = {
  filter?: InputMaybe<ComponentDropdownMenuItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentDropdownMenuModelsMetaArgs = {
  filter?: InputMaybe<ComponentDropdownMenuModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentTabElementModelsMetaArgs = {
  filter?: InputMaybe<ComponentTabElementModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllComponentTabModelsMetaArgs = {
  filter?: InputMaybe<ComponentTabModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllEmergencyLandingPageModelsMetaArgs = {
  filter?: InputMaybe<EmergencyLandingPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllEmergencyOfferingModelsMetaArgs = {
  filter?: InputMaybe<EmergencyOfferingModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllEmergencyOfferingSectionModelsMetaArgs = {
  filter?: InputMaybe<EmergencyOfferingSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldCallToActionModelsMetaArgs = {
  filter?: InputMaybe<FieldCallToActionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldContactFormModelsMetaArgs = {
  filter?: InputMaybe<FieldContactFormModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldContentSectionModelsMetaArgs = {
  filter?: InputMaybe<FieldContentSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldDynamicProDirectorySectionModelsMetaArgs = {
  filter?: InputMaybe<FieldDynamicProDirectorySectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldHomePageIndustryItemModelsMetaArgs = {
  filter?: InputMaybe<FieldHomePageIndustryItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldMarketingTopicsMetaArgs = {
  filter?: InputMaybe<FieldMarketingTopicModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldProDirectorySectionModelsMetaArgs = {
  filter?: InputMaybe<FieldProDirectorySectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldProMapSectionsMetaArgs = {
  filter?: InputMaybe<FieldProMapSectionModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldRegionPageProDirectoriesMetaArgs = {
  filter?: InputMaybe<FieldRegionPageProDirectoryModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldRegionPageProMapsMetaArgs = {
  filter?: InputMaybe<FieldRegionPageProMapModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFieldSignupSectionsMetaArgs = {
  filter?: InputMaybe<FieldSignupSectionModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllFontAwesomeIconModelsMetaArgs = {
  filter?: InputMaybe<FontAwesomeIconModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllHomePageModelsMetaArgs = {
  filter?: InputMaybe<HomePageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllHowItWorksItemModelsMetaArgs = {
  filter?: InputMaybe<HowItWorksItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllHowItWorksSectionModelsMetaArgs = {
  filter?: InputMaybe<HowItWorksSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllIndustryModelsMetaArgs = {
  filter?: InputMaybe<IndustryModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllIndustryOverviewPageModelsMetaArgs = {
  filter?: InputMaybe<IndustryOverviewPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllIndustryRegionPagesMetaArgs = {
  filter?: InputMaybe<IndustryRegionPageModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllIndustrySectionModelsMetaArgs = {
  filter?: InputMaybe<IndustrySectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllInspirationItemModelsMetaArgs = {
  filter?: InputMaybe<InspirationItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllInspirationSectionModelsMetaArgs = {
  filter?: InputMaybe<InspirationSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllIntentionModelsMetaArgs = {
  filter?: InputMaybe<IntentionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllKnowledgeSectionModelsMetaArgs = {
  filter?: InputMaybe<KnowledgeSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllOfferingCategoryModelsMetaArgs = {
  filter?: InputMaybe<OfferingCategoryModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllOfferingDataModelsMetaArgs = {
  filter?: InputMaybe<OfferingDataModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllOfferingDetailPageModelsMetaArgs = {
  filter?: InputMaybe<OfferingDetailPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllOfferingModelsMetaArgs = {
  filter?: InputMaybe<OfferingModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllOfferingPageModelsMetaArgs = {
  filter?: InputMaybe<OfferingPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllOfferingSectionItemModelsMetaArgs = {
  filter?: InputMaybe<OfferingSectionItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllOfferingSectionModelsMetaArgs = {
  filter?: InputMaybe<OfferingSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllPartnerItemModelsMetaArgs = {
  filter?: InputMaybe<PartnerItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllPartnerSectionModelsMetaArgs = {
  filter?: InputMaybe<PartnerSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllPresentationSectionItemModelsMetaArgs = {
  filter?: InputMaybe<PresentationSectionItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllPresentationSectionModelsMetaArgs = {
  filter?: InputMaybe<PresentationSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllProDirectoryPageModelsMetaArgs = {
  filter?: InputMaybe<ProDirectoryPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllProRegisterPlanPageModelsMetaArgs = {
  filter?: InputMaybe<ProRegisterPlanPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllPromotionBannerModelsMetaArgs = {
  filter?: InputMaybe<PromotionBannerModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllQuestionModelsMetaArgs = {
  filter?: InputMaybe<QuestionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllRedirectionModelsMetaArgs = {
  filter?: InputMaybe<RedirectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllRegionCraftsmenPagesMetaArgs = {
  filter?: InputMaybe<RegionCraftsmenPageModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllRegionsMetaArgs = {
  filter?: InputMaybe<RegionModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllReviewSectionModelsMetaArgs = {
  filter?: InputMaybe<ReviewSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllSeoModelsMetaArgs = {
  filter?: InputMaybe<SeoModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllServiceItemModelsMetaArgs = {
  filter?: InputMaybe<ServiceItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllServiceModelsMetaArgs = {
  filter?: InputMaybe<ServiceModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllServiceOverviewPageModelsMetaArgs = {
  filter?: InputMaybe<ServiceOverviewPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllServicesSectionModelsMetaArgs = {
  filter?: InputMaybe<ServicesSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllSkillModelsMetaArgs = {
  filter?: InputMaybe<SkillModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllStaticPageModelsMetaArgs = {
  filter?: InputMaybe<StaticPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllSupportSectionModelsMetaArgs = {
  filter?: InputMaybe<SupportSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllTopicOverviewPageModelsMetaArgs = {
  filter?: InputMaybe<TopicOverviewPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllVideoModelsMetaArgs = {
  filter?: InputMaybe<VideoModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllVoucherPriceBasedTermModelsMetaArgs = {
  filter?: InputMaybe<VoucherPriceBasedTermModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_AllVoucherServiceFeeTermModelsMetaArgs = {
  filter?: InputMaybe<VoucherServiceFeeTermModelModelFilter>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type QueryAllAnnouncementBannerV2ModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AnnouncementBannerV2ModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AnnouncementBannerV2ModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllAnswerModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AnswerModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AnswerModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllArticleCtaCardItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ArticleCtaCardItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ArticleCtaCardItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllArticleCtaCardModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ArticleCtaCardModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ArticleCtaCardModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllArticleModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ArticleModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ArticleModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllAuthorModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AuthorModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AuthorModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllAutomaticArticleSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AutomaticArticleSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AutomaticArticleSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllCalculatorModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<CalculatorModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<CalculatorModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllCategoryLandingPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<CategoryLandingPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<CategoryLandingPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllClientReviewModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ClientReviewModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ClientReviewModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentAccordionElementModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentAccordionElementModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentAccordionElementModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentAccordionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentAccordionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentAccordionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentBulletPointModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentBulletPointModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentBulletPointModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentButtonModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentButtonModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentButtonModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentDropdownMenuItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentDropdownMenuItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentDropdownMenuItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentDropdownMenuModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentDropdownMenuModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentDropdownMenuModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentTabElementModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentTabElementModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentTabElementModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllComponentTabModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentTabModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentTabModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllEmergencyLandingPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<EmergencyLandingPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<EmergencyLandingPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllEmergencyOfferingModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<EmergencyOfferingModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<EmergencyOfferingModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllEmergencyOfferingSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<EmergencyOfferingSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<EmergencyOfferingSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldCallToActionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldCallToActionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldCallToActionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldContactFormModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldContactFormModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldContactFormModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldContentSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldContentSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldContentSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldDynamicProDirectorySectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldDynamicProDirectorySectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldDynamicProDirectorySectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldHomePageIndustryItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldHomePageIndustryItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldHomePageIndustryItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldMarketingTopicsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldMarketingTopicModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldMarketingTopicModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldProDirectorySectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldProDirectorySectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldProDirectorySectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldProMapSectionsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldProMapSectionModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldProMapSectionModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldRegionPageProDirectoriesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldRegionPageProDirectoryModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldRegionPageProDirectoryModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldRegionPageProMapsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldRegionPageProMapModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldRegionPageProMapModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFieldSignupSectionsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldSignupSectionModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldSignupSectionModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllFontAwesomeIconModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FontAwesomeIconModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FontAwesomeIconModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllHomePageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<HomePageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<HomePageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllHowItWorksItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<HowItWorksItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<HowItWorksItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllHowItWorksSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<HowItWorksSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<HowItWorksSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllIndustryModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustryModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustryModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllIndustryOverviewPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustryOverviewPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustryOverviewPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllIndustryRegionPagesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustryRegionPageModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustryRegionPageModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllIndustrySectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustrySectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustrySectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllInspirationItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<InspirationItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<InspirationItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllInspirationSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<InspirationSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<InspirationSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllIntentionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IntentionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IntentionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllKnowledgeSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<KnowledgeSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<KnowledgeSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllOfferingCategoryModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingCategoryModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingCategoryModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllOfferingDataModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingDataModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingDataModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllOfferingDetailPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingDetailPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingDetailPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllOfferingModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllOfferingPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllOfferingSectionItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingSectionItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingSectionItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllOfferingSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllPartnerItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PartnerItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PartnerItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllPartnerSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PartnerSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PartnerSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllPresentationSectionItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PresentationSectionItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PresentationSectionItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllPresentationSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PresentationSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PresentationSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllProDirectoryPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ProDirectoryPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ProDirectoryPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllProRegisterPlanPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ProRegisterPlanPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ProRegisterPlanPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllPromotionBannerModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PromotionBannerModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PromotionBannerModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllQuestionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<QuestionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<QuestionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllRedirectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<RedirectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<RedirectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllRegionCraftsmenPagesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<RegionCraftsmenPageModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<RegionCraftsmenPageModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllRegionsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<RegionModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<RegionModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllReviewSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ReviewSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ReviewSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllSeoModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<SeoModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<SeoModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllServiceItemModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServiceItemModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServiceItemModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllServiceModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServiceModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServiceModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllServiceOverviewPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServiceOverviewPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServiceOverviewPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllServicesSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServicesSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServicesSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllSkillModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<SkillModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<SkillModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllStaticPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<StaticPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<StaticPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllSupportSectionModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<SupportSectionModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<SupportSectionModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllTopicOverviewPageModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<TopicOverviewPageModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<TopicOverviewPageModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<UploadFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<UploadOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllVideoModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<VideoModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<VideoModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllVoucherPriceBasedTermModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<VoucherPriceBasedTermModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<VoucherPriceBasedTermModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAllVoucherServiceFeeTermModelsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<VoucherServiceFeeTermModelModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<VoucherServiceFeeTermModelModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
}

/** The query root for this schema */
export type QueryAnnouncementBannerV2ModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AnnouncementBannerV2ModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AnnouncementBannerV2ModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryAnswerModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AnswerModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AnswerModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryArticleCtaCardItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ArticleCtaCardItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ArticleCtaCardItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryArticleCtaCardModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ArticleCtaCardModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ArticleCtaCardModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryArticleModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ArticleModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ArticleModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryAuthorModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AuthorModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AuthorModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryAutomaticArticleSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<AutomaticArticleSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<AutomaticArticleSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryCalculatorModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<CalculatorModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<CalculatorModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryCategoryLandingPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<CategoryLandingPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<CategoryLandingPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryClientReviewModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ClientReviewModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ClientReviewModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentAccordionElementModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentAccordionElementModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentAccordionElementModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentAccordionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentAccordionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentAccordionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentBulletPointModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentBulletPointModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentBulletPointModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentButtonModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentButtonModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentButtonModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentDropdownMenuItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentDropdownMenuItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentDropdownMenuItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentDropdownMenuModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentDropdownMenuModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentDropdownMenuModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentTabElementModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentTabElementModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentTabElementModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryComponentTabModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ComponentTabModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ComponentTabModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryCraftsmenRegionDirectoryPageArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** The query root for this schema */
export type QueryEmergencyLandingPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<EmergencyLandingPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<EmergencyLandingPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryEmergencyOfferingModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<EmergencyOfferingModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<EmergencyOfferingModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryEmergencyOfferingSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<EmergencyOfferingSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<EmergencyOfferingSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldCallToActionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldCallToActionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldCallToActionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldContactFormModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldContactFormModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldContactFormModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldContentSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldContentSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldContentSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldDynamicProDirectorySectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldDynamicProDirectorySectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldDynamicProDirectorySectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldHomePageIndustryItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldHomePageIndustryItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldHomePageIndustryItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldMarketingTopicArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldMarketingTopicModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldMarketingTopicModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldProDirectorySectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldProDirectorySectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldProDirectorySectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldProMapSectionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldProMapSectionModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldProMapSectionModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldRegionPageProDirectoryArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldRegionPageProDirectoryModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldRegionPageProDirectoryModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldRegionPageProMapArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldRegionPageProMapModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldRegionPageProMapModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFieldSignupSectionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldSignupSectionModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldSignupSectionModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryFontAwesomeIconModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FontAwesomeIconModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FontAwesomeIconModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryHomePageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<HomePageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<HomePageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryHowItWorksItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<HowItWorksItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<HowItWorksItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryHowItWorksSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<HowItWorksSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<HowItWorksSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryIndustryModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustryModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustryModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryIndustryOverviewPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustryOverviewPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustryOverviewPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryIndustryRegionPageArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustryRegionPageModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustryRegionPageModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryIndustrySectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustrySectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustrySectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryInspirationItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<InspirationItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<InspirationItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryInspirationSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<InspirationSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<InspirationSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryIntentionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IntentionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IntentionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryKnowledgeSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<KnowledgeSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<KnowledgeSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryOfferingCategoryModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingCategoryModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingCategoryModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryOfferingDataModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingDataModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingDataModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryOfferingDetailPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingDetailPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingDetailPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryOfferingModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryOfferingPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryOfferingSectionItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingSectionItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingSectionItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryOfferingSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<OfferingSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<OfferingSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryPartnerItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PartnerItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PartnerItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryPartnerSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PartnerSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PartnerSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryPresentationSectionItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PresentationSectionItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PresentationSectionItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryPresentationSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PresentationSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PresentationSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryProDirectoryPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ProDirectoryPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ProDirectoryPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryProRegisterPlanPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ProRegisterPlanPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ProRegisterPlanPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryPromotionBannerModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<PromotionBannerModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<PromotionBannerModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryQuestionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<QuestionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<QuestionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryRedirectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<RedirectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<RedirectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryRegionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<RegionModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<RegionModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryRegionCraftsmenPageArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<RegionCraftsmenPageModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<RegionCraftsmenPageModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryReviewSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ReviewSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ReviewSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QuerySeoModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<SeoModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<SeoModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryServiceItemModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServiceItemModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServiceItemModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryServiceModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServiceModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServiceModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryServiceOverviewPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServiceOverviewPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServiceOverviewPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryServicesSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<ServicesSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<ServicesSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QuerySkillModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<SkillModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<SkillModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryStaticPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<StaticPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<StaticPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QuerySupportSectionModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<SupportSectionModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<SupportSectionModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryTopicOverviewPageModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<TopicOverviewPageModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<TopicOverviewPageModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<UploadFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<UploadOrderBy>[]>
}

/** The query root for this schema */
export type QueryVideoModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<VideoModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<VideoModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryVoucherPriceBasedTermModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<VoucherPriceBasedTermModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<VoucherPriceBasedTermModelModelOrderBy>[]>
}

/** The query root for this schema */
export type QueryVoucherServiceFeeTermModelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<VoucherServiceFeeTermModelModelFilter>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<VoucherServiceFeeTermModelModelOrderBy>[]>
}

export type QuestionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<QuestionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<QuestionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  answers?: InputMaybe<LinksFilter>
  clientLabel?: InputMaybe<StringFilter>
  errorLabel?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  proLabel?: InputMaybe<StringFilter>
  required?: InputMaybe<BooleanFilter>
  stepperLowerBound?: InputMaybe<IntegerFilter>
  stepperSize?: InputMaybe<IntegerFilter>
  stepperUpperBound?: InputMaybe<IntegerFilter>
  technicalId?: InputMaybe<StringFilter>
  tooltip?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum QuestionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ClientLabelAsc = 'clientLabel_ASC',
  ClientLabelDesc = 'clientLabel_DESC',
  ErrorLabelAsc = 'errorLabel_ASC',
  ErrorLabelDesc = 'errorLabel_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ProLabelAsc = 'proLabel_ASC',
  ProLabelDesc = 'proLabel_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  StepperLowerBoundAsc = 'stepperLowerBound_ASC',
  StepperLowerBoundDesc = 'stepperLowerBound_DESC',
  StepperSizeAsc = 'stepperSize_ASC',
  StepperSizeDesc = 'stepperSize_DESC',
  StepperUpperBoundAsc = 'stepperUpperBound_ASC',
  StepperUpperBoundDesc = 'stepperUpperBound_DESC',
  TechnicalIdAsc = 'technicalId_ASC',
  TechnicalIdDesc = 'technicalId_DESC',
  TooltipAsc = 'tooltip_ASC',
  TooltipDesc = 'tooltip_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type Question (question_model) */
export type QuestionModelRecord = RecordInterface & {
  __typename?: 'QuestionModelRecord'
  _allClientLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allErrorLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allProLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allTooltipLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  answers: AnswerModelRecord[]
  clientLabel?: Maybe<Scalars['String']['output']>
  errorLabel?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  proLabel?: Maybe<Scalars['String']['output']>
  required: Scalars['BooleanType']['output']
  stepperLowerBound?: Maybe<Scalars['IntType']['output']>
  stepperSize?: Maybe<Scalars['IntType']['output']>
  stepperUpperBound?: Maybe<Scalars['IntType']['output']>
  technicalId?: Maybe<Scalars['String']['output']>
  tooltip?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type Question (question_model) */
export type QuestionModelRecord_AllClientLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Question (question_model) */
export type QuestionModelRecord_AllErrorLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Question (question_model) */
export type QuestionModelRecord_AllProLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Question (question_model) */
export type QuestionModelRecord_AllTooltipLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Question (question_model) */
export type QuestionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Question (question_model) */
export type QuestionModelRecordClientLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Question (question_model) */
export type QuestionModelRecordErrorLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Question (question_model) */
export type QuestionModelRecordProLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Question (question_model) */
export type QuestionModelRecordTooltipArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type RecordInterface = {
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
}

export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type RedirectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<RedirectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<RedirectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  comment?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  name?: InputMaybe<StringFilter>
  statusField?: InputMaybe<IntegerFilter>
  target?: InputMaybe<StringFilter>
}

export enum RedirectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  StatusFieldAsc = 'statusField_ASC',
  StatusFieldDesc = 'statusField_DESC',
  TargetAsc = 'target_ASC',
  TargetDesc = 'target_DESC',
}

/** Record of type Redirection (redirection_model) */
export type RedirectionModelRecord = RecordInterface & {
  __typename?: 'RedirectionModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  name?: Maybe<Scalars['String']['output']>
  statusField?: Maybe<Scalars['IntType']['output']>
  target?: Maybe<Scalars['String']['output']>
}

/** Record of type Redirection (redirection_model) */
export type RedirectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Redirection (redirection_model) */
export type RedirectionModelRecordCommentArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

export type RegionCraftsmenPageModelContentField =
  | FieldContentSectionModelRecord
  | FieldProMapSectionRecord
  | FieldRegionPageProDirectoryRecord
  | FieldSignupSectionRecord
  | KnowledgeSectionModelRecord
  | PresentationSectionModelRecord
  | ReviewSectionModelRecord
  | SupportSectionModelRecord

/** Linking fields */
export enum RegionCraftsmenPageModelFieldsReferencingRegionModel {
  RegionCraftsmenPageRegion = 'regionCraftsmenPage_region',
}

export type RegionCraftsmenPageModelFilter = {
  AND?: InputMaybe<InputMaybe<RegionCraftsmenPageModelFilter>[]>
  OR?: InputMaybe<InputMaybe<RegionCraftsmenPageModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  content?: InputMaybe<LinksFilter>
  coverSubtitle?: InputMaybe<StringFilter>
  coverTitle?: InputMaybe<StringFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  featuredIndustries?: InputMaybe<LinksFilter>
  id?: InputMaybe<ItemIdFilter>
  isDefault?: InputMaybe<BooleanFilter>
  region?: InputMaybe<LinkFilter>
  seo?: InputMaybe<LinkFilter>
}

export enum RegionCraftsmenPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CoverSubtitleAsc = 'coverSubtitle_ASC',
  CoverSubtitleDesc = 'coverSubtitle_DESC',
  CoverTitleAsc = 'coverTitle_ASC',
  CoverTitleDesc = 'coverTitle_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsDefaultAsc = 'isDefault_ASC',
  IsDefaultDesc = 'isDefault_DESC',
}

/** Record of type Craftsmen Region Page (region_craftsmen_page) */
export type RegionCraftsmenPageRecord = RecordInterface & {
  __typename?: 'RegionCraftsmenPageRecord'
  _allCoverSubtitleLocales?: Maybe<StringMultiLocaleField[]>
  _allCoverTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  content: RegionCraftsmenPageModelContentField[]
  coverSubtitle?: Maybe<Scalars['String']['output']>
  coverTitle?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  featuredIndustries: IndustryModelRecord[]
  id: Scalars['ItemId']['output']
  isDefault: Scalars['BooleanType']['output']
  region?: Maybe<RegionRecord>
  seo?: Maybe<SeoModelRecord>
}

/** Record of type Craftsmen Region Page (region_craftsmen_page) */
export type RegionCraftsmenPageRecord_AllCoverSubtitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Craftsmen Region Page (region_craftsmen_page) */
export type RegionCraftsmenPageRecord_AllCoverTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Craftsmen Region Page (region_craftsmen_page) */
export type RegionCraftsmenPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Craftsmen Region Page (region_craftsmen_page) */
export type RegionCraftsmenPageRecordCoverSubtitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Craftsmen Region Page (region_craftsmen_page) */
export type RegionCraftsmenPageRecordCoverTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type RegionModelFilter = {
  AND?: InputMaybe<InputMaybe<RegionModelFilter>[]>
  OR?: InputMaybe<InputMaybe<RegionModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  clientFacingLabel?: InputMaybe<StringFilter>
  descriptiveLabel?: InputMaybe<StringFilter>
  disableRegionSeoIndexing?: InputMaybe<BooleanFilter>
  excludedIndustries?: InputMaybe<LinksFilter>
  id?: InputMaybe<ItemIdFilter>
  postalCodes?: InputMaybe<StringFilter>
  slug?: InputMaybe<StringFilter>
}

export enum RegionModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ClientFacingLabelAsc = 'clientFacingLabel_ASC',
  ClientFacingLabelDesc = 'clientFacingLabel_DESC',
  DescriptiveLabelAsc = 'descriptiveLabel_ASC',
  DescriptiveLabelDesc = 'descriptiveLabel_DESC',
  DisableRegionSeoIndexingAsc = 'disableRegionSeoIndexing_ASC',
  DisableRegionSeoIndexingDesc = 'disableRegionSeoIndexing_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PostalCodesAsc = 'postalCodes_ASC',
  PostalCodesDesc = 'postalCodes_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
}

/** Record of type Region (region) */
export type RegionRecord = RecordInterface & {
  __typename?: 'RegionRecord'
  _allClientFacingLabelLocales?: Maybe<StringMultiLocaleField[]>
  _allDisableRegionSeoIndexingLocales?: Maybe<BooleanTypeNonNullMultiLocaleField[]>
  _allReferencingCraftsmenRegionDirectoryPages: CraftsmenRegionDirectoryPageRecord[]
  /** Returns meta information regarding a record collection */
  _allReferencingCraftsmenRegionDirectoryPagesMeta: CollectionMetadata
  _allReferencingFieldRegionPageProMaps: FieldRegionPageProMapRecord[]
  /** Returns meta information regarding a record collection */
  _allReferencingFieldRegionPageProMapsMeta: CollectionMetadata
  _allReferencingIndustryRegionPages: IndustryRegionPageRecord[]
  /** Returns meta information regarding a record collection */
  _allReferencingIndustryRegionPagesMeta: CollectionMetadata
  _allReferencingRegionCraftsmenPages: RegionCraftsmenPageRecord[]
  /** Returns meta information regarding a record collection */
  _allReferencingRegionCraftsmenPagesMeta: CollectionMetadata
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  clientFacingLabel?: Maybe<Scalars['String']['output']>
  descriptiveLabel?: Maybe<Scalars['String']['output']>
  disableRegionSeoIndexing: Scalars['BooleanType']['output']
  excludedIndustries: IndustryModelRecord[]
  id: Scalars['ItemId']['output']
  postalCodes?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
}

/** Record of type Region (region) */
export type RegionRecord_AllClientFacingLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Region (region) */
export type RegionRecord_AllDisableRegionSeoIndexingLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingCraftsmenRegionDirectoryPagesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<CraftsmenRegionDirectoryPageModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<CraftsmenRegionDirectoryPageModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
  through?: InputMaybe<InverseRelationshipFilterBetweenCraftsmenRegionDirectoryPageAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingCraftsmenRegionDirectoryPagesMetaArgs = {
  filter?: InputMaybe<CraftsmenRegionDirectoryPageModelFilter>
  locale?: InputMaybe<SiteLocale>
  through?: InputMaybe<InverseRelationshipFilterBetweenCraftsmenRegionDirectoryPageAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingFieldRegionPageProMapsArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<FieldRegionPageProMapModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<FieldRegionPageProMapModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
  through?: InputMaybe<InverseRelationshipFilterBetweenFieldRegionPageProMapAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingFieldRegionPageProMapsMetaArgs = {
  filter?: InputMaybe<FieldRegionPageProMapModelFilter>
  locale?: InputMaybe<SiteLocale>
  through?: InputMaybe<InverseRelationshipFilterBetweenFieldRegionPageProMapAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingIndustryRegionPagesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<IndustryRegionPageModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<IndustryRegionPageModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
  through?: InputMaybe<InverseRelationshipFilterBetweenIndustryRegionPageAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingIndustryRegionPagesMetaArgs = {
  filter?: InputMaybe<IndustryRegionPageModelFilter>
  locale?: InputMaybe<SiteLocale>
  through?: InputMaybe<InverseRelationshipFilterBetweenIndustryRegionPageAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingRegionCraftsmenPagesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  filter?: InputMaybe<RegionCraftsmenPageModelFilter>
  first?: InputMaybe<Scalars['IntType']['input']>
  locale?: InputMaybe<SiteLocale>
  orderBy?: InputMaybe<InputMaybe<RegionCraftsmenPageModelOrderBy>[]>
  skip?: InputMaybe<Scalars['IntType']['input']>
  through?: InputMaybe<InverseRelationshipFilterBetweenRegionCraftsmenPageAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_AllReferencingRegionCraftsmenPagesMetaArgs = {
  filter?: InputMaybe<RegionCraftsmenPageModelFilter>
  locale?: InputMaybe<SiteLocale>
  through?: InputMaybe<InverseRelationshipFilterBetweenRegionCraftsmenPageAndRegion>
}

/** Record of type Region (region) */
export type RegionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Region (region) */
export type RegionRecordClientFacingLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Region (region) */
export type RegionRecordDisableRegionSeoIndexingArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<InputMaybe<ResolutionType>[]>
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<InputMaybe<ResolutionType>[]>
}

export enum ResolutionType {
  Icon = 'icon',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export type ResponsiveImage = {
  __typename?: 'ResponsiveImage'
  alt?: Maybe<Scalars['String']['output']>
  aspectRatio: Scalars['FloatType']['output']
  base64?: Maybe<Scalars['String']['output']>
  bgColor?: Maybe<Scalars['String']['output']>
  height: Scalars['IntType']['output']
  sizes: Scalars['String']['output']
  src: Scalars['String']['output']
  srcSet: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  webpSrcSet: Scalars['String']['output']
  width: Scalars['IntType']['output']
}

export type ReviewSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ReviewSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ReviewSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  averageRating?: InputMaybe<FloatFilter>
  callToActionTitle?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  plot?: InputMaybe<StringFilter>
  reviews?: InputMaybe<LinksFilter>
  title?: InputMaybe<StringFilter>
}

export enum ReviewSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  AverageRatingAsc = 'averageRating_ASC',
  AverageRatingDesc = 'averageRating_DESC',
  CallToActionTitleAsc = 'callToActionTitle_ASC',
  CallToActionTitleDesc = 'callToActionTitle_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecord = RecordInterface & {
  __typename?: 'ReviewSectionModelRecord'
  _allCallToActionTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  averageRating?: Maybe<Scalars['FloatType']['output']>
  callToActionTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  plot?: Maybe<Scalars['String']['output']>
  reviews: ClientReviewModelRecord[]
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecord_AllCallToActionTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecordCallToActionTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Review Section (review_section_model) */
export type ReviewSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type SeoField = {
  __typename?: 'SeoField'
  description?: Maybe<Scalars['String']['output']>
  image?: Maybe<FileField>
  noIndex?: Maybe<Scalars['BooleanType']['output']>
  title?: Maybe<Scalars['String']['output']>
  twitterCard?: Maybe<Scalars['String']['output']>
}

export type SeoModelModelCanonicalPageField =
  | ArticleModelRecord
  | CategoryLandingPageModelRecord
  | EmergencyLandingPageModelRecord
  | IndustryOverviewPageModelRecord
  | OfferingDetailPageModelRecord
  | ServiceOverviewPageModelRecord
  | TopicOverviewPageModelRecord

export type SeoModelModelFilter = {
  AND?: InputMaybe<InputMaybe<SeoModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<SeoModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  canonicalPage?: InputMaybe<LinkFilter>
  changeFrequency?: InputMaybe<StringFilter>
  description?: InputMaybe<StringFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  doNotIndexPage?: InputMaybe<BooleanFilter>
  id?: InputMaybe<ItemIdFilter>
  sitemapPriority?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum SeoModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ChangeFrequencyAsc = 'changeFrequency_ASC',
  ChangeFrequencyDesc = 'changeFrequency_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  DoNotIndexPageAsc = 'doNotIndexPage_ASC',
  DoNotIndexPageDesc = 'doNotIndexPage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SitemapPriorityAsc = 'sitemapPriority_ASC',
  SitemapPriorityDesc = 'sitemapPriority_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] SEO (seo_model) */
export type SeoModelRecord = RecordInterface & {
  __typename?: 'SeoModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  canonicalPage?: Maybe<SeoModelModelCanonicalPageField>
  changeFrequency?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  doNotIndexPage: Scalars['BooleanType']['output']
  id: Scalars['ItemId']['output']
  sitemapPriority?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] SEO (seo_model) */
export type SeoModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] SEO (seo_model) */
export type SeoModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] SEO (seo_model) */
export type SeoModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] SEO (seo_model) */
export type SeoModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] SEO (seo_model) */
export type SeoModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ServiceItemModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ServiceItemModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ServiceItemModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  callToAction?: InputMaybe<LinkFilter>
  callToActionPath?: InputMaybe<StringFilter>
  callToActionText?: InputMaybe<StringFilter>
  chipColour?: InputMaybe<StringFilter>
  chipText?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  icon?: InputMaybe<LinkFilter>
  id?: InputMaybe<ItemIdFilter>
  image?: InputMaybe<FileFilter>
  shortDescription?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
  typeField?: InputMaybe<StringFilter>
}

export enum ServiceItemModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CallToActionPathAsc = 'callToActionPath_ASC',
  CallToActionPathDesc = 'callToActionPath_DESC',
  CallToActionTextAsc = 'callToActionText_ASC',
  CallToActionTextDesc = 'callToActionText_DESC',
  ChipColourAsc = 'chipColour_ASC',
  ChipColourDesc = 'chipColour_DESC',
  ChipTextAsc = 'chipText_ASC',
  ChipTextDesc = 'chipText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeFieldAsc = 'typeField_ASC',
  TypeFieldDesc = 'typeField_DESC',
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecord = RecordInterface & {
  __typename?: 'ServiceItemModelRecord'
  _allCallToActionTextLocales?: Maybe<StringMultiLocaleField[]>
  _allChipTextLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allShortDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  callToAction?: Maybe<FieldCallToActionModelRecord>
  callToActionPath?: Maybe<Scalars['String']['output']>
  callToActionText?: Maybe<Scalars['String']['output']>
  chipColour?: Maybe<Scalars['String']['output']>
  chipText?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  icon?: Maybe<FontAwesomeIconModelRecord>
  id: Scalars['ItemId']['output']
  image?: Maybe<FileField>
  shortDescription?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  typeField?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecord_AllCallToActionTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecord_AllChipTextLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecord_AllShortDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecordCallToActionTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecordChipTextArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecordShortDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Service item (service_item_model) */
export type ServiceItemModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ServiceModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ServiceModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ServiceModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  idField?: InputMaybe<IntegerFilter>
  label?: InputMaybe<StringFilter>
  rank?: InputMaybe<IntegerFilter>
  skill?: InputMaybe<LinkFilter>
}

export enum ServiceModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdFieldAsc = 'idField_ASC',
  IdFieldDesc = 'idField_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RankAsc = 'rank_ASC',
  RankDesc = 'rank_DESC',
}

/** Record of type Service (service_model) */
export type ServiceModelRecord = RecordInterface & {
  __typename?: 'ServiceModelRecord'
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  idField?: Maybe<Scalars['IntType']['output']>
  label?: Maybe<Scalars['String']['output']>
  rank?: Maybe<Scalars['IntType']['output']>
  skill?: Maybe<SkillModelRecord>
}

/** Record of type Service (service_model) */
export type ServiceModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Service (service_model) */
export type ServiceModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Service (service_model) */
export type ServiceModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ServiceOverviewPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ServiceOverviewPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ServiceOverviewPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  breadcrumbTitle?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  partnerSection?: InputMaybe<LinkFilter>
  plot?: InputMaybe<StringFilter>
  seo?: InputMaybe<LinkFilter>
  servicesSection?: InputMaybe<LinkFilter>
  slug?: InputMaybe<StringFilter>
  supportSection?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum ServiceOverviewPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BreadcrumbTitleAsc = 'breadcrumbTitle_ASC',
  BreadcrumbTitleDesc = 'breadcrumbTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecord = RecordInterface & {
  __typename?: 'ServiceOverviewPageModelRecord'
  _allBreadcrumbTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  breadcrumbTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  partnerSection?: Maybe<PartnerSectionModelRecord>
  plot?: Maybe<Scalars['String']['output']>
  seo?: Maybe<SeoModelRecord>
  servicesSection?: Maybe<ServicesSectionModelRecord>
  slug?: Maybe<Scalars['String']['output']>
  supportSection?: Maybe<SupportSectionModelRecord>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecord_AllBreadcrumbTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecordBreadcrumbTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Service Overview Page (service_overview_page_model) */
export type ServiceOverviewPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type ServicesSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<ServicesSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<ServicesSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  description?: InputMaybe<TextFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  items?: InputMaybe<LinksFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum ServicesSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecord = RecordInterface & {
  __typename?: 'ServicesSectionModelRecord'
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  items: ServiceItemModelRecord[]
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Services section (services_section_model) */
export type ServicesSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Block of type Single-line String (single_line_string) */
export type SingleLineStringRecord = RecordInterface & {
  __typename?: 'SingleLineStringRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  value?: Maybe<Scalars['String']['output']>
}

/** Block of type Single-line String (single_line_string) */
export type SingleLineStringRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type SingleLineStringRecordListListNonNullMultiLocaleField = {
  __typename?: 'SingleLineStringRecordListListNonNullMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value: SingleLineStringRecord[]
}

export type Site = {
  __typename?: 'Site'
  favicon?: Maybe<FileField>
  faviconMetaTags: Tag[]
  globalSeo?: Maybe<GlobalSeoField>
  locales: SiteLocale[]
  noIndex?: Maybe<Scalars['BooleanType']['output']>
}

export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<InputMaybe<FaviconType>[]>
}

export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export enum SiteLocale {
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it',
}

export type SkillModelModelFilter = {
  AND?: InputMaybe<InputMaybe<SkillModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<SkillModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  id?: InputMaybe<ItemIdFilter>
  idField?: InputMaybe<IntegerFilter>
  industry?: InputMaybe<LinkFilter>
  label?: InputMaybe<StringFilter>
  rank?: InputMaybe<IntegerFilter>
}

export enum SkillModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdFieldAsc = 'idField_ASC',
  IdFieldDesc = 'idField_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RankAsc = 'rank_ASC',
  RankDesc = 'rank_DESC',
}

/** Record of type Skill (skill_model) */
export type SkillModelRecord = RecordInterface & {
  __typename?: 'SkillModelRecord'
  _allLabelLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  idField?: Maybe<Scalars['IntType']['output']>
  industry?: Maybe<IndustryModelRecord>
  label?: Maybe<Scalars['String']['output']>
  rank?: Maybe<Scalars['IntType']['output']>
}

/** Record of type Skill (skill_model) */
export type SkillModelRecord_AllLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Skill (skill_model) */
export type SkillModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Skill (skill_model) */
export type SkillModelRecordLabelArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type StaticPageModelModelContentBlocksField = StructuredTextAssetRecord | TableRecord

export type StaticPageModelModelContentField = {
  __typename?: 'StaticPageModelModelContentField'
  blocks: StaticPageModelModelContentBlocksField[]
  links: StaticPageModelModelContentLinksField[]
  value: Scalars['JsonField']['output']
}

export type StaticPageModelModelContentFieldMultiLocaleField = {
  __typename?: 'StaticPageModelModelContentFieldMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<StaticPageModelModelContentField>
}

export type StaticPageModelModelContentLinksField =
  | ComponentBulletPointModelRecord
  | ComponentButtonModelRecord
  | FieldSignupSectionRecord
  | PromotionBannerModelRecord

export type StaticPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<StaticPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<StaticPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  breadcrumbTitle?: InputMaybe<StringFilter>
  content?: InputMaybe<StructuredTextFilter>
  id?: InputMaybe<ItemIdFilter>
  offeringSection?: InputMaybe<LinkFilter>
  partnerSection?: InputMaybe<LinkFilter>
  path?: InputMaybe<StringFilter>
  promotionalBannerSection?: InputMaybe<LinkFilter>
  reviewSection?: InputMaybe<LinkFilter>
  serviceSection?: InputMaybe<LinkFilter>
  slug?: InputMaybe<StringFilter>
  supportSection?: InputMaybe<LinkFilter>
  title?: InputMaybe<StringFilter>
}

export enum StaticPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BreadcrumbTitleAsc = 'breadcrumbTitle_ASC',
  BreadcrumbTitleDesc = 'breadcrumbTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecord = RecordInterface & {
  __typename?: 'StaticPageModelRecord'
  _allBreadcrumbTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allContentLocales?: Maybe<StaticPageModelModelContentFieldMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  breadcrumbTitle?: Maybe<Scalars['String']['output']>
  content?: Maybe<StaticPageModelModelContentField>
  id: Scalars['ItemId']['output']
  offeringSection?: Maybe<OfferingSectionModelRecord>
  partnerSection?: Maybe<PartnerSectionModelRecord>
  path?: Maybe<Scalars['String']['output']>
  promotionalBannerSection?: Maybe<PromotionBannerModelRecord>
  reviewSection?: Maybe<ReviewSectionModelRecord>
  serviceSection?: Maybe<ServicesSectionModelRecord>
  slug?: Maybe<Scalars['String']['output']>
  supportSection?: Maybe<SupportSectionModelRecord>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecord_AllBreadcrumbTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecordBreadcrumbTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecordContentArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Static Page (static_page_model) */
export type StaticPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>
  /** Search records with the specified statuses */
  in?: InputMaybe<InputMaybe<ItemStatus>[]>
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>
  /** Search records without the specified statuses */
  notIn?: InputMaybe<InputMaybe<ItemStatus>[]>
}

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars['BooleanType']['input']>
  pattern: Scalars['String']['input']
  regexp?: InputMaybe<Scalars['BooleanType']['input']>
}

export type StringMultiLocaleField = {
  __typename?: 'StringMultiLocaleField'
  locale?: Maybe<SiteLocale>
  value?: Maybe<Scalars['String']['output']>
}

/** Block of type Structured Text asset (structured_text_asset) */
export type StructuredTextAssetRecord = RecordInterface & {
  __typename?: 'StructuredTextAssetRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  file?: Maybe<FileField>
  id: Scalars['ItemId']['output']
}

/** Block of type Structured Text asset (structured_text_asset) */
export type StructuredTextAssetRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter Structured Text fields values */
export type StructuredTextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

/** Block of type Structured Text Plot (structured_text_plot) */
export type StructuredTextPlotRecord = RecordInterface & {
  __typename?: 'StructuredTextPlotRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  title?: Maybe<Scalars['String']['output']>
}

/** Block of type Structured Text Plot (structured_text_plot) */
export type StructuredTextPlotRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type SupportSectionModelModelFilter = {
  AND?: InputMaybe<InputMaybe<SupportSectionModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<SupportSectionModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  contactDescription?: InputMaybe<StringFilter>
  contactName?: InputMaybe<StringFilter>
  contactRole?: InputMaybe<StringFilter>
  contactTitle?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  email?: InputMaybe<StringFilter>
  fieldTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  image?: InputMaybe<FileFilter>
  phoneNumber?: InputMaybe<StringFilter>
  plot?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum SupportSectionModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ContactDescriptionAsc = 'contactDescription_ASC',
  ContactDescriptionDesc = 'contactDescription_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ContactRoleAsc = 'contactRole_ASC',
  ContactRoleDesc = 'contactRole_DESC',
  ContactTitleAsc = 'contactTitle_ASC',
  ContactTitleDesc = 'contactTitle_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FieldTitleAsc = 'fieldTitle_ASC',
  FieldTitleDesc = 'fieldTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  PlotAsc = 'plot_ASC',
  PlotDesc = 'plot_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord = RecordInterface & {
  __typename?: 'SupportSectionModelRecord'
  _allContactDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allContactNameLocales?: Maybe<StringMultiLocaleField[]>
  _allContactRoleLocales?: Maybe<StringMultiLocaleField[]>
  _allContactTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allEmailLocales?: Maybe<StringMultiLocaleField[]>
  _allImageLocales?: Maybe<FileFieldMultiLocaleField[]>
  _allPhoneNumberLocales?: Maybe<StringMultiLocaleField[]>
  _allPlotLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  contactDescription?: Maybe<Scalars['String']['output']>
  contactName?: Maybe<Scalars['String']['output']>
  contactRole?: Maybe<Scalars['String']['output']>
  contactTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  fieldTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  image?: Maybe<FileField>
  phoneNumber?: Maybe<Scalars['String']['output']>
  plot?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllContactDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllContactNameLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllContactRoleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllContactTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllEmailLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllImageLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllPhoneNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllPlotLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordContactDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordContactNameArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordContactRoleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordContactTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordEmailArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordImageArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordPhoneNumberArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordPlotArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type [Field] Support Section (support_section_model) */
export type SupportSectionModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Block of type Data Table (table) */
export type TableRecord = RecordInterface & {
  __typename?: 'TableRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  id: Scalars['ItemId']['output']
  tableContent?: Maybe<Scalars['JsonField']['output']>
}

/** Block of type Data Table (table) */
export type TableRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type Tag = {
  __typename?: 'Tag'
  attributes?: Maybe<Scalars['MetaTagAttributes']['output']>
  content?: Maybe<Scalars['String']['output']>
  tag: Scalars['String']['output']
}

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

export type TopicOverviewPageModelModelFilter = {
  AND?: InputMaybe<InputMaybe<TopicOverviewPageModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<TopicOverviewPageModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _locales?: InputMaybe<LocalesFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  breadcrumbTitle?: InputMaybe<StringFilter>
  description?: InputMaybe<TextFilter>
  id?: InputMaybe<ItemIdFilter>
  rank?: InputMaybe<IntegerFilter>
  showInOverview?: InputMaybe<BooleanFilter>
  slug?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export enum TopicOverviewPageModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BreadcrumbTitleAsc = 'breadcrumbTitle_ASC',
  BreadcrumbTitleDesc = 'breadcrumbTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RankAsc = 'rank_ASC',
  RankDesc = 'rank_DESC',
  ShowInOverviewAsc = 'showInOverview_ASC',
  ShowInOverviewDesc = 'showInOverview_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecord = RecordInterface & {
  __typename?: 'TopicOverviewPageModelRecord'
  _allBreadcrumbTitleLocales?: Maybe<StringMultiLocaleField[]>
  _allDescriptionLocales?: Maybe<StringMultiLocaleField[]>
  _allTitleLocales?: Maybe<StringMultiLocaleField[]>
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _locales: SiteLocale[]
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  breadcrumbTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  rank?: Maybe<Scalars['IntType']['output']>
  showInOverview: Scalars['BooleanType']['output']
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecord_AllBreadcrumbTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecord_AllDescriptionLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecordBreadcrumbTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecordDescriptionArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
  markdown?: InputMaybe<Scalars['Boolean']['input']>
}

/** Record of type Topic Overview Page (topic_overview_page_model) */
export type TopicOverviewPageModelRecordTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>
  /** Search uploads with the specified types */
  in?: InputMaybe<InputMaybe<UploadType>[]>
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>
  /** Search uploads without the specified types */
  notIn?: InputMaybe<InputMaybe<UploadType>[]>
}

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>
}

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<InputMaybe<ColorBucketType>[]>
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<InputMaybe<ColorBucketType>[]>
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>
  /** Search for uploads with an exact match */
  eq?: InputMaybe<InputMaybe<ColorBucketType>[]>
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<InputMaybe<ColorBucketType>[]>
}

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>
}

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

export type UploadFilter = {
  AND?: InputMaybe<InputMaybe<UploadFilter>[]>
  OR?: InputMaybe<InputMaybe<UploadFilter>[]>
  _createdAt?: InputMaybe<UploadCreatedAtFilter>
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>
  alt?: InputMaybe<UploadAltFilter>
  author?: InputMaybe<UploadAuthorFilter>
  basename?: InputMaybe<UploadBasenameFilter>
  colors?: InputMaybe<UploadColorsFilter>
  copyright?: InputMaybe<UploadCopyrightFilter>
  filename?: InputMaybe<UploadFilenameFilter>
  format?: InputMaybe<UploadFormatFilter>
  height?: InputMaybe<UploadHeightFilter>
  id?: InputMaybe<UploadIdFilter>
  inUse?: InputMaybe<InUseFilter>
  md5?: InputMaybe<UploadMd5Filter>
  mimeType?: InputMaybe<UploadMimeTypeFilter>
  notes?: InputMaybe<UploadNotesFilter>
  orientation?: InputMaybe<OrientationFilter>
  resolution?: InputMaybe<ResolutionFilter>
  size?: InputMaybe<UploadSizeFilter>
  smartTags?: InputMaybe<UploadTagsFilter>
  tags?: InputMaybe<UploadTagsFilter>
  title?: InputMaybe<UploadTitleFilter>
  type?: InputMaybe<TypeFilter>
  width?: InputMaybe<UploadWidthFilter>
}

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Search assets with the specified formats */
  in?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
}

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars['IntType']['input']>
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars['IntType']['input']>
}

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>
  /** Search assets with the specified IDs */
  in?: InputMaybe<InputMaybe<Scalars['UploadId']['input']>[]>
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<InputMaybe<Scalars['UploadId']['input']>[]>
}

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Search assets with the specified MD5s */
  in?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
}

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Search assets with the specified mime types */
  in?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

export enum UploadOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BasenameAsc = 'basename_ASC',
  BasenameDesc = 'basename_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  ResolutionAsc = 'resolution_ASC',
  ResolutionDesc = 'resolution_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
}

export enum UploadOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square',
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars['IntType']['input']>
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars['IntType']['input']>
}

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Scalars['String']['input'][]>
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Scalars['String']['input'][]>
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars['String']['input']>
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['String']['input'][]>
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Scalars['String']['input'][]>
}

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>
  /** Search assets with the specified as default title */
  in?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<InputMaybe<Scalars['String']['input']>[]>
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>
}

export enum UploadType {
  Archive = 'archive',
  Audio = 'audio',
  Image = 'image',
  Pdfdocument = 'pdfdocument',
  Presentation = 'presentation',
  Richtext = 'richtext',
  Spreadsheet = 'spreadsheet',
  Video = 'video',
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>
}

export type UploadVideoField = {
  __typename?: 'UploadVideoField'
  alt?: Maybe<Scalars['String']['output']>
  blurUpThumb?: Maybe<Scalars['String']['output']>
  blurhash?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['Int']['output']>
  framerate?: Maybe<Scalars['Int']['output']>
  height: Scalars['IntType']['output']
  mp4Url?: Maybe<Scalars['String']['output']>
  muxAssetId: Scalars['String']['output']
  muxPlaybackId: Scalars['String']['output']
  streamingUrl: Scalars['String']['output']
  thumbhash?: Maybe<Scalars['String']['output']>
  thumbnailUrl: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  width: Scalars['IntType']['output']
}

export type UploadVideoFieldAltArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

export type UploadVideoFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>
  punch?: Scalars['Float']['input']
  quality?: Scalars['Int']['input']
  size?: Scalars['Int']['input']
}

export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>
  res?: InputMaybe<VideoMp4Res>
}

export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>
}

export type UploadVideoFieldTitleArgs = {
  fallbackLocales?: InputMaybe<SiteLocale[]>
  locale?: InputMaybe<SiteLocale>
}

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars['IntType']['input']>
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars['IntType']['input']>
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars['IntType']['input']>
}

export type VideoModelModelFilter = {
  AND?: InputMaybe<InputMaybe<VideoModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<VideoModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  descriptiveText?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  youtubeId?: InputMaybe<StringFilter>
}

export enum VideoModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTextAsc = 'descriptiveText_ASC',
  DescriptiveTextDesc = 'descriptiveText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  YoutubeIdAsc = 'youtubeId_ASC',
  YoutubeIdDesc = 'youtubeId_DESC',
}

/** Record of type [Component] Video (video_model) */
export type VideoModelRecord = RecordInterface & {
  __typename?: 'VideoModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  descriptiveText?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  youtubeId?: Maybe<Scalars['String']['output']>
}

/** Record of type [Component] Video (video_model) */
export type VideoModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export enum VideoMp4Res {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

export type VoucherPriceBasedTermModelModelFilter = {
  AND?: InputMaybe<InputMaybe<VoucherPriceBasedTermModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<VoucherPriceBasedTermModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  discount?: InputMaybe<IntegerFilter>
  from?: InputMaybe<IntegerFilter>
  id?: InputMaybe<ItemIdFilter>
  technicalTermType?: InputMaybe<StringFilter>
}

export enum VoucherPriceBasedTermModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  DiscountAsc = 'discount_ASC',
  DiscountDesc = 'discount_DESC',
  FromAsc = 'from_ASC',
  FromDesc = 'from_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TechnicalTermTypeAsc = 'technicalTermType_ASC',
  TechnicalTermTypeDesc = 'technicalTermType_DESC',
}

/** Record of type [Field] Voucher Price Based Term (voucher_price_based_term_model) */
export type VoucherPriceBasedTermModelRecord = RecordInterface & {
  __typename?: 'VoucherPriceBasedTermModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  discount?: Maybe<Scalars['IntType']['output']>
  from?: Maybe<Scalars['IntType']['output']>
  id: Scalars['ItemId']['output']
  technicalTermType?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Voucher Price Based Term (voucher_price_based_term_model) */
export type VoucherPriceBasedTermModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type VoucherServiceFeeTermModelModelFilter = {
  AND?: InputMaybe<InputMaybe<VoucherServiceFeeTermModelModelFilter>[]>
  OR?: InputMaybe<InputMaybe<VoucherServiceFeeTermModelModelFilter>[]>
  _createdAt?: InputMaybe<CreatedAtFilter>
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>
  _isValid?: InputMaybe<BooleanFilter>
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>
  _publishedAt?: InputMaybe<PublishedAtFilter>
  _status?: InputMaybe<StatusFilter>
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>
  _updatedAt?: InputMaybe<UpdatedAtFilter>
  clientServiceFee?: InputMaybe<BooleanFilter>
  descriptiveTitle?: InputMaybe<StringFilter>
  id?: InputMaybe<ItemIdFilter>
  technicalTermType?: InputMaybe<StringFilter>
}

export enum VoucherServiceFeeTermModelModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ClientServiceFeeAsc = 'clientServiceFee_ASC',
  ClientServiceFeeDesc = 'clientServiceFee_DESC',
  DescriptiveTitleAsc = 'descriptiveTitle_ASC',
  DescriptiveTitleDesc = 'descriptiveTitle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TechnicalTermTypeAsc = 'technicalTermType_ASC',
  TechnicalTermTypeDesc = 'technicalTermType_DESC',
}

/** Record of type [Field] Voucher Service Fee Term (voucher_service_fee_term_model) */
export type VoucherServiceFeeTermModelRecord = RecordInterface & {
  __typename?: 'VoucherServiceFeeTermModelRecord'
  _createdAt: Scalars['DateTime']['output']
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>
  _isValid: Scalars['BooleanType']['output']
  _modelApiKey: Scalars['String']['output']
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Tag[]
  _status: ItemStatus
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>
  _updatedAt: Scalars['DateTime']['output']
  clientServiceFee: Scalars['BooleanType']['output']
  descriptiveTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ItemId']['output']
  technicalTermType?: Maybe<Scalars['String']['output']>
}

/** Record of type [Field] Voucher Service Fee Term (voucher_service_fee_term_model) */
export type VoucherServiceFeeTermModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>
}

export type FocalPoint = {
  __typename?: 'focalPoint'
  x: Scalars['FloatType']['output']
  y: Scalars['FloatType']['output']
}

export type GetAnnouncementBannersByLocationQueryVariables = Exact<{
  locale: SiteLocale
  location: Scalars['String']['input']
}>

export type GetAnnouncementBannersByLocationQuery = {
  __typename?: 'Query'
  announcementBannerV2Model?:
    | {
        __typename?: 'AnnouncementBannerV2ModelRecord'
        id: any
        location?: string | undefined
        textMessage?: string | undefined
        linkText?: string | undefined
        link?: string | undefined
      }
    | undefined
}

export type GetIndustryItemsQueryVariables = Exact<{
  locale: SiteLocale
}>

export type GetIndustryItemsQuery = {
  __typename?: 'Query'
  allFieldHomePageIndustryItemModels: {
    __typename?: 'FieldHomePageIndustryItemModelRecord'
    title?: string | undefined
    icon?: string | undefined
    collection?: string | undefined
    target?:
      | {__typename?: 'CategoryLandingPageModelRecord'}
      | {__typename?: 'EmergencyLandingPageModelRecord'}
      | {
          __typename?: 'IndustryOverviewPageModelRecord'
          slug?: string | undefined
          offeringSection?:
            | {
                __typename?: 'OfferingSectionModelRecord'
                id: any
                title?: string | undefined
                plot?: string | undefined
                items2: (
                  | {
                      __typename?: 'OfferingDetailPageModelRecord'
                      description?: string | undefined
                      slug?: string | undefined
                      offering?:
                        | {
                            __typename?: 'OfferingModelRecord'
                            title?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                          }
                        | undefined
                    }
                  | {
                      __typename?: 'OfferingModelRecord'
                      title?: string | undefined
                      offeringId?: string | undefined
                      icon?:
                        | {
                            __typename?: 'FontAwesomeIconModelRecord'
                            collection?: string | undefined
                            name?: string | undefined
                          }
                        | undefined
                    }
                  | {
                      __typename?: 'OfferingSectionItemModelRecord'
                      title?: string | undefined
                      description?: string | undefined
                      icon?:
                        | {
                            __typename?: 'FontAwesomeIconModelRecord'
                            collection?: string | undefined
                            name?: string | undefined
                          }
                        | undefined
                      target?:
                        | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                        | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                        | undefined
                    }
                )[]
              }
            | undefined
        }
      | {__typename?: 'StaticPageModelRecord'}
      | undefined
  }[]
}

export type GetRegisterProPageQueryVariables = Exact<{
  slug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetRegisterProPageQuery = {
  __typename?: 'Query'
  proRegisterPlanPageModel?:
    | {
        __typename?: 'ProRegisterPlanPageModelRecord'
        slug?: string | undefined
        title?: string | undefined
        description?: string | undefined
        headerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        sellingPoints?:
          | {
              __typename?: 'ComponentTabModelRecord'
              slug?: string | undefined
              elements: {
                __typename?: 'ComponentTabElementModelRecord'
                title?: string | undefined
                headline?: string | undefined
                text?: string | undefined
                icon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
                image?:
                  | {
                      __typename?: 'FileField'
                      height?: any | undefined
                      width?: any | undefined
                      url: string
                      title?: string | undefined
                    }
                  | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                callToAction2?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
        promotionalBanner?:
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | undefined
      }
    | undefined
}

export type GetTopicOverviewPageQueryVariables = Exact<{
  pageSlug: Scalars['String']['input']
  locale: SiteLocale
  moreTopicCount: Scalars['IntType']['input']
}>

export type GetTopicOverviewPageQuery = {
  __typename?: 'Query'
  topicOverviewPageModel?:
    | {
        __typename?: 'TopicOverviewPageModelRecord'
        title?: string | undefined
        slug?: string | undefined
        breadcrumbTitle?: string | undefined
        description?: string | undefined
        _locales: SiteLocale[]
      }
    | undefined
  moreTopics: {
    __typename?: 'TopicOverviewPageModelRecord'
    id: any
    slug?: string | undefined
    title?: string | undefined
    breadcrumbTitle?: string | undefined
  }[]
}

export type GetArticlePageQueryVariables = Exact<{
  pageSlug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetArticlePageQuery = {
  __typename?: 'Query'
  articleModel?:
    | {
        __typename: 'ArticleModelRecord'
        id: any
        _publishedAt?: any | undefined
        _locales: SiteLocale[]
        release?: any | undefined
        slug?: string | undefined
        title?: string | undefined
        breadcrumbTitle?: string | undefined
        preview?: string | undefined
        readingTime?: any | undefined
        parentField?:
          | {
              __typename?: 'ServiceOverviewPageModelRecord'
              slug?: string | undefined
              title?: string | undefined
              breadcrumbTitle?: string | undefined
            }
          | undefined
        headerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        content?:
          | {
              __typename?: 'ArticleModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'StructuredTextPlotRecord'; id: any; title?: string | undefined}
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
        author: {
          __typename?: 'AuthorModelRecord'
          description?: string | undefined
          name?: string | undefined
          partnerLogo?:
            | {
                __typename?: 'FileField'
                height?: any | undefined
                width?: any | undefined
                url: string
                title?: string | undefined
              }
            | undefined
          partnerLogoCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          cta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          portrait?: {__typename?: 'FileField'; url: string} | undefined
        }[]
        topic?:
          | {
              __typename?: 'TopicOverviewPageModelRecord'
              _locales: SiteLocale[]
              slug?: string | undefined
              title?: string | undefined
              breadcrumbTitle?: string | undefined
            }
          | undefined
        mainCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        secondaryCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
}

export type GetArticlePageAvailableLocalesQueryVariables = Exact<{
  pageSlug: Scalars['String']['input']
}>

export type GetArticlePageAvailableLocalesQuery = {
  __typename?: 'Query'
  articleModel?: {__typename?: 'ArticleModelRecord'; _locales: SiteLocale[]} | undefined
}

export type GetTopicListQueryVariables = Exact<{
  locale: SiteLocale
  count: Scalars['IntType']['input']
}>

export type GetTopicListQuery = {
  __typename?: 'Query'
  allTopicOverviewPageModels: {
    __typename?: 'TopicOverviewPageModelRecord'
    id: any
    slug?: string | undefined
    title?: string | undefined
    breadcrumbTitle?: string | undefined
  }[]
}

export type GetStaticPageQueryVariables = Exact<{
  pageSlug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetStaticPageQuery = {
  __typename?: 'Query'
  staticPageModel?:
    | {
        __typename?: 'StaticPageModelRecord'
        serviceSection?:
          | {
              __typename?: 'ServicesSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'ServiceItemModelRecord'
                id: any
                title?: string | undefined
                shortDescription?: string | undefined
                chipText?: string | undefined
                chipColour?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                serviceIcon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
        supportSection?:
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
          | undefined
        offeringSection?:
          | {
              __typename?: 'OfferingSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              items2: (
                | {
                    __typename?: 'OfferingDetailPageModelRecord'
                    description?: string | undefined
                    slug?: string | undefined
                    offering?:
                      | {
                          __typename?: 'OfferingModelRecord'
                          title?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    offeringId?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingSectionItemModelRecord'
                    title?: string | undefined
                    description?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    target?:
                      | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                      | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                      | undefined
                  }
              )[]
            }
          | undefined
        partnerSection?:
          | {
              __typename?: 'PartnerSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PartnerItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                target?:
                  | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
                  | undefined
              }[]
            }
          | undefined
        reviewSection?:
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | undefined
        content?:
          | {
              __typename?: 'StaticPageModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
              )[]
            }
          | undefined
        promotionalBannerSection?:
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | undefined
      }
    | undefined
}

export type GetIndustryOverviewPageSlugsQueryVariables = Exact<{
  locale: SiteLocale
}>

export type GetIndustryOverviewPageSlugsQuery = {
  __typename?: 'Query'
  allIndustryOverviewPageModels: {
    __typename?: 'IndustryOverviewPageModelRecord'
    slug?: string | undefined
    proDirectoryIndustries?: string | undefined
  }[]
}

export type GetServiceOverviewPageQueryVariables = Exact<{
  pageSlug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetServiceOverviewPageQuery = {
  __typename?: 'Query'
  serviceOverviewPageModel?:
    | {
        __typename?: 'ServiceOverviewPageModelRecord'
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        _locales: SiteLocale[]
        servicesSection?:
          | {
              __typename?: 'ServicesSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'ServiceItemModelRecord'
                id: any
                title?: string | undefined
                shortDescription?: string | undefined
                chipText?: string | undefined
                chipColour?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                serviceIcon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
        supportSection?:
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
          | undefined
        partnerSection?:
          | {
              __typename?: 'PartnerSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PartnerItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                target?:
                  | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
                  | undefined
              }[]
            }
          | undefined
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              changeFrequency?: string | undefined
              doNotIndexPage: any
              sitemapPriority?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
}

export type GetCategoryLandingPageQueryVariables = Exact<{
  locale: SiteLocale
  pageSlug: Scalars['String']['input']
}>

export type GetCategoryLandingPageQuery = {
  __typename?: 'Query'
  categoryLandingPageModel?:
    | {
        __typename?: 'CategoryLandingPageModelRecord'
        title?: string | undefined
        description?: string | undefined
        showPartnershipSection: any
        _locales: SiteLocale[]
        primaryCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        secondaryCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        bannerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnershipImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
        sections: (
          | {
              __typename: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {
              __typename: 'FieldDynamicProDirectorySectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              industries?: string | undefined
              location?: string | undefined
              distance?: any | undefined
              offerings: {__typename?: 'OfferingDataModelRecord'; id?: any | undefined}[]
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename: 'HowItWorksSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              actionButtonText?: string | undefined
              activeTab?: string | undefined
              items: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              strengthList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              contactUsList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'IndustrySectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              industryItems: {
                __typename?: 'FieldHomePageIndustryItemModelRecord'
                title?: string | undefined
                collection?: string | undefined
                industryIcon?: string | undefined
                target?:
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
                  | undefined
              }[]
            }
          | {
              __typename: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename: 'OfferingSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              items2: (
                | {
                    __typename?: 'OfferingDetailPageModelRecord'
                    description?: string | undefined
                    slug?: string | undefined
                    offering?:
                      | {
                          __typename?: 'OfferingModelRecord'
                          title?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    offeringId?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingSectionItemModelRecord'
                    title?: string | undefined
                    description?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    target?:
                      | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                      | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                      | undefined
                  }
              )[]
            }
          | {
              __typename: 'PartnerSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PartnerItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                target?:
                  | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
                  | undefined
              }[]
            }
          | {
              __typename: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {
              __typename: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename: 'ServicesSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'ServiceItemModelRecord'
                id: any
                title?: string | undefined
                shortDescription?: string | undefined
                chipText?: string | undefined
                chipColour?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                serviceIcon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
      }
    | undefined
}

export type GetEmergencyLandingPageQueryVariables = Exact<{
  locale: SiteLocale
  pageSlug: Scalars['String']['input']
}>

export type GetEmergencyLandingPageQuery = {
  __typename?: 'Query'
  emergencyLandingPageModel?:
    | {
        __typename?: 'EmergencyLandingPageModelRecord'
        title?: string | undefined
        description?: string | undefined
        hint?: string | undefined
        showPhoneNumber: any
        phoneNumber?: string | undefined
        _locales: SiteLocale[]
        offering?: {__typename?: 'OfferingDataModelRecord'; idField?: any | undefined} | undefined
        sections: (
          | {
              __typename: 'EmergencyOfferingSectionModelRecord'
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              actionButtonText?: string | undefined
              formTitle?: string | undefined
              formDescription?: string | undefined
              typeInputLabel?: string | undefined
              offerings: {
                __typename?: 'EmergencyOfferingModelRecord'
                title?: string | undefined
                collection?: string | undefined
                icon?: string | undefined
              }[]
            }
          | {
              __typename: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {
              __typename: 'FieldDynamicProDirectorySectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              industries?: string | undefined
              location?: string | undefined
              distance?: any | undefined
              offerings: {__typename?: 'OfferingDataModelRecord'; id?: any | undefined}[]
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename: 'HowItWorksSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              actionButtonText?: string | undefined
              activeTab?: string | undefined
              items: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              strengthList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              contactUsList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'IndustrySectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              industryItems: {
                __typename?: 'FieldHomePageIndustryItemModelRecord'
                title?: string | undefined
                collection?: string | undefined
                industryIcon?: string | undefined
                target?:
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
                  | undefined
              }[]
            }
          | {
              __typename: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {__typename: 'OfferingSectionModelRecord'}
          | {
              __typename: 'PartnerSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PartnerItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                target?:
                  | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
                  | undefined
              }[]
            }
          | {
              __typename: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {
              __typename: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename: 'ServicesSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'ServiceItemModelRecord'
                id: any
                title?: string | undefined
                shortDescription?: string | undefined
                chipText?: string | undefined
                chipColour?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                serviceIcon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
}

export type GetOfferingByIdForPageQueryVariables = Exact<{
  locale: SiteLocale
  offeringId: Scalars['IntType']['input']
}>

export type GetOfferingByIdForPageQuery = {
  __typename?: 'Query'
  offering?:
    | {
        __typename?: 'OfferingDataModelRecord'
        label?: string | undefined
        clientFacingLabel?: string | undefined
        id?: any | undefined
        cmsId: any
        icon?:
          | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
          | undefined
        industryPage?:
          | {
              __typename?: 'IndustryOverviewPageModelRecord'
              slug?: string | undefined
              title?: string | undefined
              breadcrumbTitle?: string | undefined
            }
          | undefined
      }
    | undefined
}

export type GetOfferingBySlugForPageQueryVariables = Exact<{
  locale: SiteLocale
  slug: Scalars['String']['input']
}>

export type GetOfferingBySlugForPageQuery = {
  __typename?: 'Query'
  offering?:
    | {
        __typename?: 'OfferingDataModelRecord'
        label?: string | undefined
        clientFacingLabel?: string | undefined
        id?: any | undefined
        cmsId: any
        icon?:
          | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
          | undefined
        industryPage?:
          | {
              __typename?: 'IndustryOverviewPageModelRecord'
              slug?: string | undefined
              title?: string | undefined
              breadcrumbTitle?: string | undefined
            }
          | undefined
      }
    | undefined
}

export type GetOfferingPageQueryVariables = Exact<{
  locale: SiteLocale
  offeringCmsId: Scalars['ItemId']['input']
}>

export type GetOfferingPageQuery = {
  __typename?: 'Query'
  page?:
    | {
        __typename?: 'OfferingPageModelRecord'
        _locales: SiteLocale[]
        coverTitle?: string | undefined
        coverSubtitle?: string | undefined
        displayCoverSellingPointBar: any
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {__typename?: 'FieldProDirectorySectionModelRecord'; typeField?: string | undefined}
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
  defaultPage?:
    | {
        __typename?: 'OfferingPageModelRecord'
        _locales: SiteLocale[]
        coverTitle?: string | undefined
        coverSubtitle?: string | undefined
        displayCoverSellingPointBar: any
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {__typename?: 'FieldProDirectorySectionModelRecord'; typeField?: string | undefined}
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
  offering?:
    | {
        __typename?: 'OfferingDataModelRecord'
        knowledgeSection?:
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | undefined
      }
    | undefined
}

export type GetIndustryOverviewPageQueryVariables = Exact<{
  pageSlug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetIndustryOverviewPageQuery = {
  __typename?: 'Query'
  industryOverviewPageModel?:
    | {
        __typename?: 'IndustryOverviewPageModelRecord'
        title?: string | undefined
        breadcrumbTitle?: string | undefined
        description?: string | undefined
        readingTime?: any | undefined
        industrySectionPlot?: string | undefined
        industrySectionTitle?: string | undefined
        proDirectoryIndustries?: string | undefined
        contentSectionPlot?: string | undefined
        contentSectionTitle?: string | undefined
        _locales: SiteLocale[]
        bannerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        offeringSection?:
          | {
              __typename?: 'OfferingSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              items2: (
                | {
                    __typename?: 'OfferingDetailPageModelRecord'
                    description?: string | undefined
                    slug?: string | undefined
                    offering?:
                      | {
                          __typename?: 'OfferingModelRecord'
                          title?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    offeringId?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingSectionItemModelRecord'
                    title?: string | undefined
                    description?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    target?:
                      | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                      | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                      | undefined
                  }
              )[]
            }
          | undefined
        author: {
          __typename?: 'AuthorModelRecord'
          description?: string | undefined
          name?: string | undefined
          partnerLogo?:
            | {
                __typename?: 'FileField'
                height?: any | undefined
                width?: any | undefined
                url: string
                title?: string | undefined
              }
            | undefined
          partnerLogoCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          cta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          portrait?: {__typename?: 'FileField'; url: string} | undefined
        }[]
        mainCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        secondaryCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        inspirationSection?:
          | {
              __typename?: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
        supportSection?:
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
          | undefined
        industryItems: {
          __typename?: 'FieldHomePageIndustryItemModelRecord'
          title?: string | undefined
          collection?: string | undefined
          industryIcon?: string | undefined
          target?:
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
            | undefined
        }[]
        promotionalBannerSection?:
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | undefined
        contactForm?:
          | {
              __typename?: 'FieldContactFormModelRecord'
              alternativeCallToActionTitle?: string | undefined
              callToAction1?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              callToAction2?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
            }
          | undefined
        promotionalSection?:
          | {
              __typename?: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              changeFrequency?: string | undefined
              doNotIndexPage: any
              sitemapPriority?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
        knowledgeSection?:
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | undefined
        content?:
          | {
              __typename?: 'IndustryOverviewPageModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
        presentationSection?:
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | undefined
}

export type GetProDirectoryPageQueryVariables = Exact<{
  locale: SiteLocale
  type: Scalars['String']['input']
}>

export type GetProDirectoryPageQuery = {
  __typename?: 'Query'
  proDirectoryPageModel?:
    | {
        __typename?: 'ProDirectoryPageModelRecord'
        content: (
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
        )[]
      }
    | undefined
}

export type GetHomePageQueryVariables = Exact<{
  locale: SiteLocale
  type: Scalars['String']['input']
}>

export type GetHomePageQuery = {
  __typename?: 'Query'
  homePageModel?:
    | {
        __typename?: 'HomePageModelRecord'
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {
              __typename?: 'HowItWorksSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              actionButtonText?: string | undefined
              activeTab?: string | undefined
              items: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              strengthList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              contactUsList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'IndustrySectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              industryItems: {
                __typename?: 'FieldHomePageIndustryItemModelRecord'
                title?: string | undefined
                collection?: string | undefined
                industryIcon?: string | undefined
                target?:
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
                  | undefined
              }[]
            }
          | {
              __typename?: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename?: 'PartnerSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PartnerItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                target?:
                  | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
                  | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                  | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
                  | undefined
              }[]
            }
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename?: 'ServicesSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'ServiceItemModelRecord'
                id: any
                title?: string | undefined
                shortDescription?: string | undefined
                chipText?: string | undefined
                chipColour?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                serviceIcon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
      }
    | undefined
}

export type GetOfferingDetailPageQueryVariables = Exact<{
  pageSlug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetOfferingDetailPageQuery = {
  __typename?: 'Query'
  offeringDetailPageModel?:
    | {
        __typename?: 'OfferingDetailPageModelRecord'
        title?: string | undefined
        breadcrumbTitle?: string | undefined
        description?: string | undefined
        readingTime?: any | undefined
        contentTitle?: string | undefined
        contentPlot?: string | undefined
        _locales: SiteLocale[]
        offering?:
          | {
              __typename?: 'OfferingModelRecord'
              offeringId?: string | undefined
              title?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
            }
          | undefined
        parentField?:
          | {
              __typename?: 'IndustryOverviewPageModelRecord'
              slug?: string | undefined
              title?: string | undefined
              breadcrumbTitle?: string | undefined
            }
          | {
              __typename?: 'StaticPageModelRecord'
              slug?: string | undefined
              path?: string | undefined
              title?: string | undefined
              breadcrumbTitle?: string | undefined
            }
          | undefined
        mainCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        secondaryCallToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        bannerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        howItWorksSection?:
          | {
              __typename?: 'HowItWorksSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              actionButtonText?: string | undefined
              activeTab?: string | undefined
              items: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              strengthList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
              contactUsList: {
                __typename?: 'HowItWorksItemModelRecord'
                id: any
                title?: string | undefined
                description?: string | undefined
                collection?: string | undefined
                howItWorksIcon?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
        relatedOfferingsSection?:
          | {
              __typename?: 'OfferingSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              items2: (
                | {
                    __typename?: 'OfferingDetailPageModelRecord'
                    description?: string | undefined
                    slug?: string | undefined
                    offering?:
                      | {
                          __typename?: 'OfferingModelRecord'
                          title?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    offeringId?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingSectionItemModelRecord'
                    title?: string | undefined
                    description?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    target?:
                      | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                      | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                      | undefined
                  }
              )[]
            }
          | undefined
        inspirationSection?:
          | {
              __typename?: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              title?: string | undefined
              doNotIndexPage: any
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
        promotionalBannerSection?:
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | undefined
        supportSection?:
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
          | undefined
        contactForm?:
          | {
              __typename?: 'FieldContactFormModelRecord'
              alternativeCallToActionTitle?: string | undefined
              callToAction1?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              callToAction2?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
            }
          | undefined
        author: {
          __typename?: 'AuthorModelRecord'
          description?: string | undefined
          name?: string | undefined
          partnerLogo?:
            | {
                __typename?: 'FileField'
                height?: any | undefined
                width?: any | undefined
                url: string
                title?: string | undefined
              }
            | undefined
          partnerLogoCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          cta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          portrait?: {__typename?: 'FileField'; url: string} | undefined
        }[]
        content?:
          | {
              __typename?: 'OfferingDetailPageModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
        presentationSection?:
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | undefined
}

export type GetCraftsmenRegionQueryVariables = Exact<{
  regionSlug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetCraftsmenRegionQuery = {
  __typename?: 'Query'
  region?:
    | {
        __typename?: 'RegionRecord'
        postalCodes?: string | undefined
        slug?: string | undefined
        clientFacingLabel?: string | undefined
        disableRegionSeoIndexing: any
        cmsId: any
      }
    | undefined
}

export type GetCraftsmenIndustryQueryVariables = Exact<{
  industrySlug: Scalars['String']['input']
  locale: SiteLocale
}>

export type GetCraftsmenIndustryQuery = {
  __typename?: 'Query'
  industry?:
    | {
        __typename?: 'IndustryModelRecord'
        slug?: string | undefined
        label?: string | undefined
        textVariable?: string | undefined
        hideInGeneratedPages: any
        id?: any | undefined
        cmsId: any
      }
    | undefined
}

export type GetCraftsmenRegionPageQueryVariables = Exact<{
  regionId: Scalars['ItemId']['input']
  locale: SiteLocale
}>

export type GetCraftsmenRegionPageQuery = {
  __typename?: 'Query'
  page?:
    | {
        __typename?: 'RegionCraftsmenPageRecord'
        coverTitle?: string | undefined
        coverSubtitle?: string | undefined
        featuredIndustries: {
          __typename?: 'IndustryModelRecord'
          slug?: string | undefined
          label?: string | undefined
          hideInGeneratedPages: any
        }[]
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {__typename: 'FieldProMapSectionRecord'; id: any; title?: string | undefined}
          | {__typename?: 'FieldRegionPageProDirectoryRecord'}
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
  defaultPage?:
    | {
        __typename?: 'RegionCraftsmenPageRecord'
        coverTitle?: string | undefined
        coverSubtitle?: string | undefined
        featuredIndustries: {
          __typename?: 'IndustryModelRecord'
          slug?: string | undefined
          label?: string | undefined
          hideInGeneratedPages: any
        }[]
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {__typename: 'FieldProMapSectionRecord'; id: any; title?: string | undefined}
          | {__typename?: 'FieldRegionPageProDirectoryRecord'}
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
}

export type RegionalIndustryFieldsFragment = {
  __typename?: 'IndustryModelRecord'
  slug?: string | undefined
  label?: string | undefined
  textVariable?: string | undefined
  hideInGeneratedPages: any
  id?: any | undefined
  cmsId: any
}

export type GetIndustryRegionPageQueryVariables = Exact<{
  regionId?: InputMaybe<Scalars['ItemId']['input']>
  industryId: Scalars['ItemId']['input']
  locale: SiteLocale
}>

export type GetIndustryRegionPageQuery = {
  __typename?: 'Query'
  page?:
    | {
        __typename?: 'IndustryRegionPageRecord'
        coverTitle?: string | undefined
        coverSubtitle?: string | undefined
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {__typename: 'FieldProMapSectionRecord'; id: any; title?: string | undefined}
          | {__typename?: 'FieldRegionPageProDirectoryRecord'}
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
  defaultPage?:
    | {
        __typename?: 'IndustryRegionPageRecord'
        coverTitle?: string | undefined
        coverSubtitle?: string | undefined
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {__typename: 'FieldProMapSectionRecord'; id: any; title?: string | undefined}
          | {__typename?: 'FieldRegionPageProDirectoryRecord'}
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'PresentationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              items: {
                __typename?: 'PresentationSectionItemModelRecord'
                title?: string | undefined
                youtubeId?: string | undefined
                content?:
                  | {
                      __typename?: 'PresentationSectionItemModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                    }
                  | undefined
                image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                primaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                secondaryCta?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
          | {
              __typename?: 'SupportSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              description?: string | undefined
              phoneNumber?: string | undefined
              email?: string | undefined
              contactTitle?: string | undefined
              contactDescription?: string | undefined
              contactName?: string | undefined
              contactRole?: string | undefined
              image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
            }
        )[]
        seo?:
          | {
              __typename?: 'SeoModelRecord'
              doNotIndexPage: any
              title?: string | undefined
              description?: string | undefined
              canonicalPage?:
                | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
                | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
                | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
                | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
                | undefined
            }
          | undefined
      }
    | undefined
}

export type GetCraftsmenRegionDirectoryPageQueryVariables = Exact<{
  locale: SiteLocale
  regionPageSize: Scalars['IntType']['input']
}>

export type GetCraftsmenRegionDirectoryPageQuery = {
  __typename?: 'Query'
  page?:
    | {
        __typename?: 'CraftsmenRegionDirectoryPageRecord'
        descriptiveTitle?: string | undefined
        topRegions: {
          __typename?: 'RegionRecord'
          postalCodes?: string | undefined
          slug?: string | undefined
          clientFacingLabel?: string | undefined
          disableRegionSeoIndexing: any
          cmsId: any
        }[]
        excludedIndustries: {
          __typename?: 'IndustryModelRecord'
          slug?: string | undefined
          label?: string | undefined
          textVariable?: string | undefined
          hideInGeneratedPages: any
          id?: any | undefined
          cmsId: any
        }[]
        content: (
          | {
              __typename?: 'FieldContentSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              content?:
                | {
                    __typename?: 'FieldContentSectionModelModelContentField'
                    value: any
                    blocks: (
                      | {
                          __typename: 'StructuredTextAssetRecord'
                          id: any
                          file?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
                    )[]
                    links: (
                      | {
                          __typename: 'ComponentAccordionModelRecord'
                          id: any
                          name?: string | undefined
                          elements: {
                            __typename?: 'ComponentAccordionElementModelRecord'
                            title?: string | undefined
                            content?:
                              | {
                                  __typename?: 'ComponentAccordionElementModelModelContentField'
                                  json: any
                                  blocks: {
                                    __typename: 'StructuredTextAssetRecord'
                                    id: any
                                    file?:
                                      | {
                                          __typename?: 'FileField'
                                          height?: any | undefined
                                          width?: any | undefined
                                          url: string
                                          title?: string | undefined
                                        }
                                      | undefined
                                  }[]
                                  links: (
                                    | {
                                        __typename: 'ComponentBulletPointModelRecord'
                                        id: any
                                        lead?: string | undefined
                                        text?: string | undefined
                                        iconColor?: string | undefined
                                        icon?:
                                          | {
                                              __typename?: 'FontAwesomeIconModelRecord'
                                              collection?: string | undefined
                                              name?: string | undefined
                                            }
                                          | undefined
                                        content?:
                                          | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                          | undefined
                                      }
                                    | {
                                        __typename: 'ComponentButtonModelRecord'
                                        id: any
                                        label?: string | undefined
                                        typeField?: string | undefined
                                        targetPath?: string | undefined
                                      }
                                    | {
                                        __typename: 'PromotionBannerModelRecord'
                                        id: any
                                        title?: string | undefined
                                        contentText?: string | undefined
                                        callToAction?:
                                          | {
                                              __typename?: 'FieldCallToActionModelRecord'
                                              title?: string | undefined
                                              actionType?: string | undefined
                                              actionValue?: string | undefined
                                              anchor?:
                                                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                                | {__typename: 'FieldProMapSectionRecord'; id: any}
                                                | {__typename: 'FieldSignupSectionRecord'; id: any}
                                                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                                | {__typename: 'IndustrySectionModelRecord'; id: any}
                                                | {__typename: 'InspirationSectionModelRecord'; id: any}
                                                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                                | {__typename: 'OfferingSectionModelRecord'; id: any}
                                                | {__typename: 'PartnerSectionModelRecord'; id: any}
                                                | {__typename: 'PresentationSectionModelRecord'; id: any}
                                                | {__typename: 'PromotionBannerModelRecord'; id: any}
                                                | {__typename: 'ReviewSectionModelRecord'; id: any}
                                                | {__typename: 'ServicesSectionModelRecord'; id: any}
                                                | {__typename: 'SupportSectionModelRecord'; id: any}
                                                | undefined
                                            }
                                          | undefined
                                        background?:
                                          | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                          | undefined
                                        partnerLogo?:
                                          | {
                                              __typename?: 'FileField'
                                              height?: any | undefined
                                              width?: any | undefined
                                              url: string
                                              title?: string | undefined
                                            }
                                          | undefined
                                      }
                                    | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                                  )[]
                                }
                              | undefined
                          }[]
                        }
                      | {
                          __typename: 'ComponentBulletPointModelRecord'
                          id: any
                          lead?: string | undefined
                          text?: string | undefined
                          iconColor?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                          content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                        }
                      | {
                          __typename: 'ComponentButtonModelRecord'
                          id: any
                          label?: string | undefined
                          typeField?: string | undefined
                          targetPath?: string | undefined
                        }
                      | {
                          __typename: 'FieldSignupSectionRecord'
                          id: any
                          title?: string | undefined
                          description?: string | undefined
                          formTitle?: string | undefined
                          formButtonLabel?: string | undefined
                          image?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                          topic?:
                            | {
                                __typename?: 'FieldMarketingTopicRecord'
                                topic?: string | undefined
                                sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                              }
                            | undefined
                        }
                      | {
                          __typename: 'PromotionBannerModelRecord'
                          id: any
                          title?: string | undefined
                          contentText?: string | undefined
                          callToAction?:
                            | {
                                __typename?: 'FieldCallToActionModelRecord'
                                title?: string | undefined
                                actionType?: string | undefined
                                actionValue?: string | undefined
                                anchor?:
                                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                                  | {__typename: 'SupportSectionModelRecord'; id: any}
                                  | undefined
                              }
                            | undefined
                          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                          partnerLogo?:
                            | {
                                __typename?: 'FileField'
                                height?: any | undefined
                                width?: any | undefined
                                url: string
                                title?: string | undefined
                              }
                            | undefined
                        }
                      | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                    )[]
                  }
                | undefined
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename?: 'InspirationSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              items: {
                __typename?: 'InspirationItemModelRecord'
                title?: string | undefined
                description?: string | undefined
                chipText?: string | undefined
                chipColor?: string | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
              }[]
            }
          | {
              __typename?: 'KnowledgeSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              text?: string | undefined
              cta?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              faq?:
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | undefined
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'ReviewSectionModelRecord'
              id: any
              plot?: string | undefined
              title?: string | undefined
              description?: string | undefined
              callToActionTitle?: string | undefined
              reviews: {
                __typename?: 'ClientReviewModelRecord'
                author?: string | undefined
                comment?: string | undefined
                platform?: string | undefined
                rating?: any | undefined
              }[]
            }
        )[]
      }
    | undefined
  regions: {
    __typename?: 'RegionRecord'
    postalCodes?: string | undefined
    slug?: string | undefined
    clientFacingLabel?: string | undefined
    disableRegionSeoIndexing: any
    cmsId: any
    excludedIndustries: {__typename?: 'IndustryModelRecord'; id?: any | undefined}[]
  }[]
  regionsMeta: {__typename?: 'CollectionMetadata'; count: any}
  industries: {
    __typename?: 'IndustryModelRecord'
    slug?: string | undefined
    label?: string | undefined
    textVariable?: string | undefined
    hideInGeneratedPages: any
    id?: any | undefined
    cmsId: any
  }[]
  sideNavIndustries: {
    __typename?: 'IndustryOverviewPageModelRecord'
    slug?: string | undefined
    title?: string | undefined
    breadcrumbTitle?: string | undefined
    proDirectoryIndustries?: string | undefined
  }[]
}

export type GetCraftsmenRegionDirectoryRegionsQueryVariables = Exact<{
  locale: SiteLocale
  pageSize: Scalars['IntType']['input']
  offset: Scalars['IntType']['input']
}>

export type GetCraftsmenRegionDirectoryRegionsQuery = {
  __typename?: 'Query'
  regions: {
    __typename?: 'RegionRecord'
    postalCodes?: string | undefined
    slug?: string | undefined
    clientFacingLabel?: string | undefined
    disableRegionSeoIndexing: any
    cmsId: any
    excludedIndustries: {__typename?: 'IndustryModelRecord'; id?: any | undefined}[]
  }[]
  regionsMeta: {__typename?: 'CollectionMetadata'; count: any}
}

export type CraftsmenRegionDirectoryPageFieldsFragment = {
  __typename?: 'CraftsmenRegionDirectoryPageRecord'
  descriptiveTitle?: string | undefined
  topRegions: {
    __typename?: 'RegionRecord'
    postalCodes?: string | undefined
    slug?: string | undefined
    clientFacingLabel?: string | undefined
    disableRegionSeoIndexing: any
    cmsId: any
  }[]
  excludedIndustries: {
    __typename?: 'IndustryModelRecord'
    slug?: string | undefined
    label?: string | undefined
    textVariable?: string | undefined
    hideInGeneratedPages: any
    id?: any | undefined
    cmsId: any
  }[]
  content: (
    | {
        __typename?: 'FieldContentSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        content?:
          | {
              __typename?: 'FieldContentSectionModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
      }
    | {
        __typename: 'FieldSignupSectionRecord'
        id: any
        title?: string | undefined
        description?: string | undefined
        formTitle?: string | undefined
        formButtonLabel?: string | undefined
        image?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
        topic?:
          | {
              __typename?: 'FieldMarketingTopicRecord'
              topic?: string | undefined
              sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
            }
          | undefined
      }
    | {
        __typename?: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename?: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | {
        __typename?: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
  )[]
}

export type CraftsmenRegionPageFieldsFragment = {
  __typename?: 'RegionCraftsmenPageRecord'
  coverTitle?: string | undefined
  coverSubtitle?: string | undefined
  featuredIndustries: {
    __typename?: 'IndustryModelRecord'
    slug?: string | undefined
    label?: string | undefined
    hideInGeneratedPages: any
  }[]
  content: (
    | {
        __typename?: 'FieldContentSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        content?:
          | {
              __typename?: 'FieldContentSectionModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
      }
    | {__typename: 'FieldProMapSectionRecord'; id: any; title?: string | undefined}
    | {__typename?: 'FieldRegionPageProDirectoryRecord'}
    | {
        __typename: 'FieldSignupSectionRecord'
        id: any
        title?: string | undefined
        description?: string | undefined
        formTitle?: string | undefined
        formButtonLabel?: string | undefined
        image?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
        topic?:
          | {
              __typename?: 'FieldMarketingTopicRecord'
              topic?: string | undefined
              sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
            }
          | undefined
      }
    | {
        __typename?: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | {
        __typename?: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename?: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
  )[]
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        doNotIndexPage: any
        title?: string | undefined
        description?: string | undefined
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
}

export type IndustryRegionPageFieldsFragment = {
  __typename?: 'IndustryRegionPageRecord'
  coverTitle?: string | undefined
  coverSubtitle?: string | undefined
  content: (
    | {
        __typename?: 'FieldContentSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        content?:
          | {
              __typename?: 'FieldContentSectionModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
      }
    | {__typename: 'FieldProMapSectionRecord'; id: any; title?: string | undefined}
    | {__typename?: 'FieldRegionPageProDirectoryRecord'}
    | {
        __typename: 'FieldSignupSectionRecord'
        id: any
        title?: string | undefined
        description?: string | undefined
        formTitle?: string | undefined
        formButtonLabel?: string | undefined
        image?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
        topic?:
          | {
              __typename?: 'FieldMarketingTopicRecord'
              topic?: string | undefined
              sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
            }
          | undefined
      }
    | {
        __typename?: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename?: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
  )[]
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        doNotIndexPage: any
        title?: string | undefined
        description?: string | undefined
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
}

export type CraftsmenRegionPageIndustryFieldsFragment = {
  __typename?: 'IndustryModelRecord'
  slug?: string | undefined
  label?: string | undefined
  hideInGeneratedPages: any
}

export type OfferingPageFieldsFragment = {
  __typename?: 'OfferingPageModelRecord'
  _locales: SiteLocale[]
  coverTitle?: string | undefined
  coverSubtitle?: string | undefined
  displayCoverSellingPointBar: any
  content: (
    | {
        __typename?: 'FieldContentSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        content?:
          | {
              __typename?: 'FieldContentSectionModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
      }
    | {__typename?: 'FieldProDirectorySectionModelRecord'; typeField?: string | undefined}
    | {
        __typename: 'FieldSignupSectionRecord'
        id: any
        title?: string | undefined
        description?: string | undefined
        formTitle?: string | undefined
        formButtonLabel?: string | undefined
        image?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
        topic?:
          | {
              __typename?: 'FieldMarketingTopicRecord'
              topic?: string | undefined
              sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
            }
          | undefined
      }
    | {
        __typename?: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | {
        __typename?: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename?: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
  )[]
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        doNotIndexPage: any
        title?: string | undefined
        description?: string | undefined
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
}

export type OfferingCoverFieldsFragment = {
  __typename?: 'OfferingPageModelRecord'
  coverTitle?: string | undefined
  coverSubtitle?: string | undefined
  displayCoverSellingPointBar: any
}

export type OfferingDataFieldsFragment = {
  __typename?: 'OfferingDataModelRecord'
  label?: string | undefined
  clientFacingLabel?: string | undefined
  id?: any | undefined
  cmsId: any
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  industryPage?:
    | {
        __typename?: 'IndustryOverviewPageModelRecord'
        slug?: string | undefined
        title?: string | undefined
        breadcrumbTitle?: string | undefined
      }
    | undefined
}

export type EmergencyLandingPageFieldsFragment = {
  __typename?: 'EmergencyLandingPageModelRecord'
  title?: string | undefined
  description?: string | undefined
  hint?: string | undefined
  showPhoneNumber: any
  phoneNumber?: string | undefined
  _locales: SiteLocale[]
  offering?: {__typename?: 'OfferingDataModelRecord'; idField?: any | undefined} | undefined
  sections: (
    | {
        __typename: 'EmergencyOfferingSectionModelRecord'
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        actionButtonText?: string | undefined
        formTitle?: string | undefined
        formDescription?: string | undefined
        typeInputLabel?: string | undefined
        offerings: {
          __typename?: 'EmergencyOfferingModelRecord'
          title?: string | undefined
          collection?: string | undefined
          icon?: string | undefined
        }[]
      }
    | {
        __typename: 'FieldContentSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        content?:
          | {
              __typename?: 'FieldContentSectionModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
      }
    | {
        __typename: 'FieldDynamicProDirectorySectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        industries?: string | undefined
        location?: string | undefined
        distance?: any | undefined
        offerings: {__typename?: 'OfferingDataModelRecord'; id?: any | undefined}[]
      }
    | {
        __typename: 'FieldSignupSectionRecord'
        id: any
        title?: string | undefined
        description?: string | undefined
        formTitle?: string | undefined
        formButtonLabel?: string | undefined
        image?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
        topic?:
          | {
              __typename?: 'FieldMarketingTopicRecord'
              topic?: string | undefined
              sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
            }
          | undefined
      }
    | {
        __typename: 'HowItWorksSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        actionButtonText?: string | undefined
        activeTab?: string | undefined
        items: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        strengthList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        contactUsList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename: 'IndustrySectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        industryItems: {
          __typename?: 'FieldHomePageIndustryItemModelRecord'
          title?: string | undefined
          collection?: string | undefined
          industryIcon?: string | undefined
          target?:
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | {__typename: 'OfferingSectionModelRecord'}
    | {
        __typename: 'PartnerSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PartnerItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          target?:
            | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | {
        __typename: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
    | {
        __typename: 'ServicesSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'ServiceItemModelRecord'
          id: any
          title?: string | undefined
          shortDescription?: string | undefined
          chipText?: string | undefined
          chipColour?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          serviceIcon?:
            | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
  )[]
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        doNotIndexPage: any
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
}

export type CategoryLandingPageFieldsFragment = {
  __typename?: 'CategoryLandingPageModelRecord'
  title?: string | undefined
  description?: string | undefined
  showPartnershipSection: any
  _locales: SiteLocale[]
  primaryCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  secondaryCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  bannerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnershipImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        doNotIndexPage: any
        title?: string | undefined
        description?: string | undefined
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
  sections: (
    | {
        __typename: 'FieldContentSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        content?:
          | {
              __typename?: 'FieldContentSectionModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
      }
    | {
        __typename: 'FieldDynamicProDirectorySectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        industries?: string | undefined
        location?: string | undefined
        distance?: any | undefined
        offerings: {__typename?: 'OfferingDataModelRecord'; id?: any | undefined}[]
      }
    | {
        __typename: 'FieldSignupSectionRecord'
        id: any
        title?: string | undefined
        description?: string | undefined
        formTitle?: string | undefined
        formButtonLabel?: string | undefined
        image?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
        topic?:
          | {
              __typename?: 'FieldMarketingTopicRecord'
              topic?: string | undefined
              sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
            }
          | undefined
      }
    | {
        __typename: 'HowItWorksSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        actionButtonText?: string | undefined
        activeTab?: string | undefined
        items: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        strengthList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        contactUsList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename: 'IndustrySectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        industryItems: {
          __typename?: 'FieldHomePageIndustryItemModelRecord'
          title?: string | undefined
          collection?: string | undefined
          industryIcon?: string | undefined
          target?:
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | {
        __typename: 'OfferingSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        items2: (
          | {
              __typename?: 'OfferingDetailPageModelRecord'
              description?: string | undefined
              slug?: string | undefined
              offering?:
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingModelRecord'
              title?: string | undefined
              offeringId?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingSectionItemModelRecord'
              title?: string | undefined
              description?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              target?:
                | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                | undefined
            }
        )[]
      }
    | {
        __typename: 'PartnerSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PartnerItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          target?:
            | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | {
        __typename: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
    | {
        __typename: 'ServicesSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'ServiceItemModelRecord'
          id: any
          title?: string | undefined
          shortDescription?: string | undefined
          chipText?: string | undefined
          chipColour?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          serviceIcon?:
            | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
  )[]
}

export type DynamicProDirectorySectionFieldsFragment = {
  __typename?: 'FieldDynamicProDirectorySectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  description?: string | undefined
  industries?: string | undefined
  location?: string | undefined
  distance?: any | undefined
  offerings: {__typename?: 'OfferingDataModelRecord'; id?: any | undefined}[]
}

export type ContentSectionFieldsFragment = {
  __typename?: 'FieldContentSectionModelRecord'
  id: any
  title?: string | undefined
  plot?: string | undefined
  content?:
    | {
        __typename?: 'FieldContentSectionModelModelContentField'
        value: any
        blocks: (
          | {
              __typename: 'StructuredTextAssetRecord'
              id: any
              file?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
        )[]
        links: (
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'ComponentBulletPointModelRecord'
              id: any
              lead?: string | undefined
              text?: string | undefined
              iconColor?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
            }
          | {
              __typename: 'ComponentButtonModelRecord'
              id: any
              label?: string | undefined
              typeField?: string | undefined
              targetPath?: string | undefined
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
        )[]
      }
    | undefined
}

export type StructuredTextAssetFieldsFragment = {
  __typename: 'StructuredTextAssetRecord'
  id: any
  file?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

export type DataTableFieldsFragment = {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}

export type StructuredTextPlotFieldsFragment = {
  __typename: 'StructuredTextPlotRecord'
  id: any
  title?: string | undefined
}

type ContentSectionLinksFields_ComponentAccordionModelRecord_Fragment = {
  __typename: 'ComponentAccordionModelRecord'
  id: any
  name?: string | undefined
  elements: {
    __typename?: 'ComponentAccordionElementModelRecord'
    title?: string | undefined
    content?:
      | {
          __typename?: 'ComponentAccordionElementModelModelContentField'
          json: any
          blocks: {
            __typename: 'StructuredTextAssetRecord'
            id: any
            file?:
              | {
                  __typename?: 'FileField'
                  height?: any | undefined
                  width?: any | undefined
                  url: string
                  title?: string | undefined
                }
              | undefined
          }[]
          links: (
            | {
                __typename: 'ComponentBulletPointModelRecord'
                id: any
                lead?: string | undefined
                text?: string | undefined
                iconColor?: string | undefined
                icon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
                content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
              }
            | {
                __typename: 'ComponentButtonModelRecord'
                id: any
                label?: string | undefined
                typeField?: string | undefined
                targetPath?: string | undefined
              }
            | {
                __typename: 'PromotionBannerModelRecord'
                id: any
                title?: string | undefined
                contentText?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                partnerLogo?:
                  | {
                      __typename?: 'FileField'
                      height?: any | undefined
                      width?: any | undefined
                      url: string
                      title?: string | undefined
                    }
                  | undefined
              }
            | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
          )[]
        }
      | undefined
  }[]
}

type ContentSectionLinksFields_ComponentBulletPointModelRecord_Fragment = {
  __typename: 'ComponentBulletPointModelRecord'
  id: any
  lead?: string | undefined
  text?: string | undefined
  iconColor?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
}

type ContentSectionLinksFields_ComponentButtonModelRecord_Fragment = {
  __typename: 'ComponentButtonModelRecord'
  id: any
  label?: string | undefined
  typeField?: string | undefined
  targetPath?: string | undefined
}

type ContentSectionLinksFields_FieldSignupSectionRecord_Fragment = {__typename?: 'FieldSignupSectionRecord'}

type ContentSectionLinksFields_PromotionBannerModelRecord_Fragment = {
  __typename: 'PromotionBannerModelRecord'
  id: any
  title?: string | undefined
  contentText?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

type ContentSectionLinksFields_VideoModelRecord_Fragment = {
  __typename: 'VideoModelRecord'
  youtubeId?: string | undefined
  id: any
}

export type ContentSectionLinksFieldsFragment =
  | ContentSectionLinksFields_ComponentAccordionModelRecord_Fragment
  | ContentSectionLinksFields_ComponentBulletPointModelRecord_Fragment
  | ContentSectionLinksFields_ComponentButtonModelRecord_Fragment
  | ContentSectionLinksFields_FieldSignupSectionRecord_Fragment
  | ContentSectionLinksFields_PromotionBannerModelRecord_Fragment
  | ContentSectionLinksFields_VideoModelRecord_Fragment

export type OfferingsSectionFieldsFragment = {
  __typename?: 'OfferingSectionModelRecord'
  id: any
  title?: string | undefined
  plot?: string | undefined
  items2: (
    | {
        __typename?: 'OfferingDetailPageModelRecord'
        description?: string | undefined
        slug?: string | undefined
        offering?:
          | {
              __typename?: 'OfferingModelRecord'
              title?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
            }
          | undefined
      }
    | {
        __typename?: 'OfferingModelRecord'
        title?: string | undefined
        offeringId?: string | undefined
        icon?:
          | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
          | undefined
      }
    | {
        __typename?: 'OfferingSectionItemModelRecord'
        title?: string | undefined
        description?: string | undefined
        icon?:
          | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
          | undefined
        target?:
          | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
          | undefined
      }
  )[]
}

type OfferingsSectionItemCollection_OfferingDetailPageModelRecord_Fragment = {
  __typename?: 'OfferingDetailPageModelRecord'
  description?: string | undefined
  slug?: string | undefined
  offering?:
    | {
        __typename?: 'OfferingModelRecord'
        title?: string | undefined
        icon?:
          | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
          | undefined
      }
    | undefined
}

type OfferingsSectionItemCollection_OfferingModelRecord_Fragment = {
  __typename?: 'OfferingModelRecord'
  title?: string | undefined
  offeringId?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
}

type OfferingsSectionItemCollection_OfferingSectionItemModelRecord_Fragment = {
  __typename?: 'OfferingSectionItemModelRecord'
  title?: string | undefined
  description?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  target?:
    | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
    | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
    | undefined
}

export type OfferingsSectionItemCollectionFragment =
  | OfferingsSectionItemCollection_OfferingDetailPageModelRecord_Fragment
  | OfferingsSectionItemCollection_OfferingModelRecord_Fragment
  | OfferingsSectionItemCollection_OfferingSectionItemModelRecord_Fragment

export type PresentationSectionFieldsFragment = {
  __typename?: 'PresentationSectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  items: {
    __typename?: 'PresentationSectionItemModelRecord'
    title?: string | undefined
    youtubeId?: string | undefined
    content?:
      | {
          __typename?: 'PresentationSectionItemModelModelContentField'
          json: any
          blocks: {
            __typename: 'StructuredTextAssetRecord'
            id: any
            file?:
              | {
                  __typename?: 'FileField'
                  height?: any | undefined
                  width?: any | undefined
                  url: string
                  title?: string | undefined
                }
              | undefined
          }[]
        }
      | undefined
    image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
    primaryCta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    secondaryCta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
  }[]
}

export type PresentationSectionItemFieldsFragment = {
  __typename?: 'PresentationSectionItemModelRecord'
  title?: string | undefined
  youtubeId?: string | undefined
  content?:
    | {
        __typename?: 'PresentationSectionItemModelModelContentField'
        json: any
        blocks: {
          __typename: 'StructuredTextAssetRecord'
          id: any
          file?:
            | {
                __typename?: 'FileField'
                height?: any | undefined
                width?: any | undefined
                url: string
                title?: string | undefined
              }
            | undefined
        }[]
      }
    | undefined
  image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  primaryCta?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  secondaryCta?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
}

export type KnowledgeSectionFieldsFragment = {
  __typename?: 'KnowledgeSectionModelRecord'
  id: any
  title?: string | undefined
  plot?: string | undefined
  text?: string | undefined
  cta?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  faq?:
    | {
        __typename: 'ComponentAccordionModelRecord'
        id: any
        name?: string | undefined
        elements: {
          __typename?: 'ComponentAccordionElementModelRecord'
          title?: string | undefined
          content?:
            | {
                __typename?: 'ComponentAccordionElementModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
                links: (
                  | {
                      __typename: 'ComponentBulletPointModelRecord'
                      id: any
                      lead?: string | undefined
                      text?: string | undefined
                      iconColor?: string | undefined
                      icon?:
                        | {
                            __typename?: 'FontAwesomeIconModelRecord'
                            collection?: string | undefined
                            name?: string | undefined
                          }
                        | undefined
                      content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                    }
                  | {
                      __typename: 'ComponentButtonModelRecord'
                      id: any
                      label?: string | undefined
                      typeField?: string | undefined
                      targetPath?: string | undefined
                    }
                  | {
                      __typename: 'PromotionBannerModelRecord'
                      id: any
                      title?: string | undefined
                      contentText?: string | undefined
                      callToAction?:
                        | {
                            __typename?: 'FieldCallToActionModelRecord'
                            title?: string | undefined
                            actionType?: string | undefined
                            actionValue?: string | undefined
                            anchor?:
                              | {__typename: 'FieldContentSectionModelRecord'; id: any}
                              | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                              | {__typename: 'FieldProMapSectionRecord'; id: any}
                              | {__typename: 'FieldSignupSectionRecord'; id: any}
                              | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                              | {__typename: 'IndustrySectionModelRecord'; id: any}
                              | {__typename: 'InspirationSectionModelRecord'; id: any}
                              | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                              | {__typename: 'OfferingSectionModelRecord'; id: any}
                              | {__typename: 'PartnerSectionModelRecord'; id: any}
                              | {__typename: 'PresentationSectionModelRecord'; id: any}
                              | {__typename: 'PromotionBannerModelRecord'; id: any}
                              | {__typename: 'ReviewSectionModelRecord'; id: any}
                              | {__typename: 'ServicesSectionModelRecord'; id: any}
                              | {__typename: 'SupportSectionModelRecord'; id: any}
                              | undefined
                          }
                        | undefined
                      background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                      partnerLogo?:
                        | {
                            __typename?: 'FileField'
                            height?: any | undefined
                            width?: any | undefined
                            url: string
                            title?: string | undefined
                          }
                        | undefined
                    }
                  | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                )[]
              }
            | undefined
        }[]
      }
    | undefined
}

export type ComponentAccordionFieldsFragment = {
  __typename: 'ComponentAccordionModelRecord'
  id: any
  name?: string | undefined
  elements: {
    __typename?: 'ComponentAccordionElementModelRecord'
    title?: string | undefined
    content?:
      | {
          __typename?: 'ComponentAccordionElementModelModelContentField'
          json: any
          blocks: {
            __typename: 'StructuredTextAssetRecord'
            id: any
            file?:
              | {
                  __typename?: 'FileField'
                  height?: any | undefined
                  width?: any | undefined
                  url: string
                  title?: string | undefined
                }
              | undefined
          }[]
          links: (
            | {
                __typename: 'ComponentBulletPointModelRecord'
                id: any
                lead?: string | undefined
                text?: string | undefined
                iconColor?: string | undefined
                icon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
                content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
              }
            | {
                __typename: 'ComponentButtonModelRecord'
                id: any
                label?: string | undefined
                typeField?: string | undefined
                targetPath?: string | undefined
              }
            | {
                __typename: 'PromotionBannerModelRecord'
                id: any
                title?: string | undefined
                contentText?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                partnerLogo?:
                  | {
                      __typename?: 'FileField'
                      height?: any | undefined
                      width?: any | undefined
                      url: string
                      title?: string | undefined
                    }
                  | undefined
              }
            | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
          )[]
        }
      | undefined
  }[]
}

type ComponentAccordionElementLinksFields_ComponentBulletPointModelRecord_Fragment = {
  __typename: 'ComponentBulletPointModelRecord'
  id: any
  lead?: string | undefined
  text?: string | undefined
  iconColor?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
}

type ComponentAccordionElementLinksFields_ComponentButtonModelRecord_Fragment = {
  __typename: 'ComponentButtonModelRecord'
  id: any
  label?: string | undefined
  typeField?: string | undefined
  targetPath?: string | undefined
}

type ComponentAccordionElementLinksFields_PromotionBannerModelRecord_Fragment = {
  __typename: 'PromotionBannerModelRecord'
  id: any
  title?: string | undefined
  contentText?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

type ComponentAccordionElementLinksFields_VideoModelRecord_Fragment = {
  __typename: 'VideoModelRecord'
  youtubeId?: string | undefined
  id: any
}

export type ComponentAccordionElementLinksFieldsFragment =
  | ComponentAccordionElementLinksFields_ComponentBulletPointModelRecord_Fragment
  | ComponentAccordionElementLinksFields_ComponentButtonModelRecord_Fragment
  | ComponentAccordionElementLinksFields_PromotionBannerModelRecord_Fragment
  | ComponentAccordionElementLinksFields_VideoModelRecord_Fragment

export type VideoFieldsFragment = {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}

export type ComponentButtonFieldsFragment = {
  __typename: 'ComponentButtonModelRecord'
  id: any
  label?: string | undefined
  typeField?: string | undefined
  targetPath?: string | undefined
}

export type ComponentBulletPointFieldsFragment = {
  __typename: 'ComponentBulletPointModelRecord'
  id: any
  lead?: string | undefined
  text?: string | undefined
  iconColor?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
}

export type SupportSectionFieldsFragment = {
  __typename?: 'SupportSectionModelRecord'
  id: any
  title?: string | undefined
  plot?: string | undefined
  description?: string | undefined
  phoneNumber?: string | undefined
  email?: string | undefined
  contactTitle?: string | undefined
  contactDescription?: string | undefined
  contactName?: string | undefined
  contactRole?: string | undefined
  image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
}

export type ServiceSectionFieldsFragment = {
  __typename?: 'ServicesSectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  description?: string | undefined
  items: {
    __typename?: 'ServiceItemModelRecord'
    id: any
    title?: string | undefined
    shortDescription?: string | undefined
    chipText?: string | undefined
    chipColour?: string | undefined
    callToAction?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    serviceIcon?:
      | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
      | undefined
  }[]
}

export type ServicesItemFieldsFragment = {
  __typename?: 'ServiceItemModelRecord'
  id: any
  title?: string | undefined
  shortDescription?: string | undefined
  chipText?: string | undefined
  chipColour?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  serviceIcon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
}

export type FontAwesomeFieldsFragment = {
  __typename?: 'FontAwesomeIconModelRecord'
  collection?: string | undefined
  name?: string | undefined
}

export type ReviewSectionFieldsFragment = {
  __typename?: 'ReviewSectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  description?: string | undefined
  callToActionTitle?: string | undefined
  reviews: {
    __typename?: 'ClientReviewModelRecord'
    author?: string | undefined
    comment?: string | undefined
    platform?: string | undefined
    rating?: any | undefined
  }[]
}

export type ClientReviewFieldsFragment = {
  __typename?: 'ClientReviewModelRecord'
  author?: string | undefined
  comment?: string | undefined
  platform?: string | undefined
  rating?: any | undefined
}

export type PromotionalBannerSectionFieldsFragment = {
  __typename: 'PromotionBannerModelRecord'
  id: any
  title?: string | undefined
  contentText?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

export type SignupSectionFieldsFragment = {
  __typename: 'FieldSignupSectionRecord'
  id: any
  title?: string | undefined
  description?: string | undefined
  formTitle?: string | undefined
  formButtonLabel?: string | undefined
  image?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
  topic?:
    | {
        __typename?: 'FieldMarketingTopicRecord'
        topic?: string | undefined
        sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
      }
    | undefined
}

export type PartnerSectionFieldsFragment = {
  __typename?: 'PartnerSectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  items: {
    __typename?: 'PartnerItemModelRecord'
    title?: string | undefined
    description?: string | undefined
    logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
    target?:
      | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
      | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
      | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
      | undefined
  }[]
}

export type PartnerItemFieldsFragment = {
  __typename?: 'PartnerItemModelRecord'
  title?: string | undefined
  description?: string | undefined
  logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  target?:
    | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
    | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
    | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
    | undefined
}

export type InspirationSectionFieldsFragment = {
  __typename?: 'InspirationSectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  description?: string | undefined
  items: {
    __typename?: 'InspirationItemModelRecord'
    title?: string | undefined
    description?: string | undefined
    chipText?: string | undefined
    chipColor?: string | undefined
    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
    callToAction?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
  }[]
}

export type InspirationItemFieldsFragment = {
  __typename?: 'InspirationItemModelRecord'
  title?: string | undefined
  description?: string | undefined
  chipText?: string | undefined
  chipColor?: string | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
}

export type IndustrySectionFieldsFragment = {
  __typename?: 'IndustrySectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  industryItems: {
    __typename?: 'FieldHomePageIndustryItemModelRecord'
    title?: string | undefined
    collection?: string | undefined
    industryIcon?: string | undefined
    target?:
      | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
      | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
      | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
      | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
      | undefined
  }[]
}

export type IndustryItemFieldsFragment = {
  __typename?: 'FieldHomePageIndustryItemModelRecord'
  title?: string | undefined
  collection?: string | undefined
  industryIcon?: string | undefined
  target?:
    | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
    | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
    | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
    | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
    | undefined
}

export type CallToActionFieldsFragment = {
  __typename?: 'FieldCallToActionModelRecord'
  title?: string | undefined
  actionType?: string | undefined
  actionValue?: string | undefined
  anchor?:
    | {__typename: 'FieldContentSectionModelRecord'; id: any}
    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
    | {__typename: 'FieldProMapSectionRecord'; id: any}
    | {__typename: 'FieldSignupSectionRecord'; id: any}
    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
    | {__typename: 'IndustrySectionModelRecord'; id: any}
    | {__typename: 'InspirationSectionModelRecord'; id: any}
    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
    | {__typename: 'OfferingSectionModelRecord'; id: any}
    | {__typename: 'PartnerSectionModelRecord'; id: any}
    | {__typename: 'PresentationSectionModelRecord'; id: any}
    | {__typename: 'PromotionBannerModelRecord'; id: any}
    | {__typename: 'ReviewSectionModelRecord'; id: any}
    | {__typename: 'ServicesSectionModelRecord'; id: any}
    | {__typename: 'SupportSectionModelRecord'; id: any}
    | undefined
}

export type HowItWorksFieldsFragment = {
  __typename?: 'HowItWorksItemModelRecord'
  id: any
  title?: string | undefined
  description?: string | undefined
  collection?: string | undefined
  howItWorksIcon?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
}

export type HowItWorksSectionFieldsFragment = {
  __typename?: 'HowItWorksSectionModelRecord'
  id: any
  plot?: string | undefined
  title?: string | undefined
  description?: string | undefined
  actionButtonText?: string | undefined
  activeTab?: string | undefined
  items: {
    __typename?: 'HowItWorksItemModelRecord'
    id: any
    title?: string | undefined
    description?: string | undefined
    collection?: string | undefined
    howItWorksIcon?: string | undefined
    callToAction?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
  }[]
  strengthList: {
    __typename?: 'HowItWorksItemModelRecord'
    id: any
    title?: string | undefined
    description?: string | undefined
    collection?: string | undefined
    howItWorksIcon?: string | undefined
    callToAction?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
  }[]
  contactUsList: {
    __typename?: 'HowItWorksItemModelRecord'
    id: any
    title?: string | undefined
    description?: string | undefined
    collection?: string | undefined
    howItWorksIcon?: string | undefined
    callToAction?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
  }[]
}

type CanonicalPageFields_ArticleModelRecord_Fragment = {__typename: 'ArticleModelRecord'; slug?: string | undefined}

type CanonicalPageFields_CategoryLandingPageModelRecord_Fragment = {
  __typename: 'CategoryLandingPageModelRecord'
  slug?: string | undefined
}

type CanonicalPageFields_EmergencyLandingPageModelRecord_Fragment = {
  __typename: 'EmergencyLandingPageModelRecord'
  slug?: string | undefined
}

type CanonicalPageFields_IndustryOverviewPageModelRecord_Fragment = {
  __typename: 'IndustryOverviewPageModelRecord'
  slug?: string | undefined
}

type CanonicalPageFields_OfferingDetailPageModelRecord_Fragment = {
  __typename: 'OfferingDetailPageModelRecord'
  slug?: string | undefined
}

type CanonicalPageFields_ServiceOverviewPageModelRecord_Fragment = {
  __typename: 'ServiceOverviewPageModelRecord'
  slug?: string | undefined
}

type CanonicalPageFields_TopicOverviewPageModelRecord_Fragment = {
  __typename: 'TopicOverviewPageModelRecord'
  slug?: string | undefined
}

export type CanonicalPageFieldsFragment =
  | CanonicalPageFields_ArticleModelRecord_Fragment
  | CanonicalPageFields_CategoryLandingPageModelRecord_Fragment
  | CanonicalPageFields_EmergencyLandingPageModelRecord_Fragment
  | CanonicalPageFields_IndustryOverviewPageModelRecord_Fragment
  | CanonicalPageFields_OfferingDetailPageModelRecord_Fragment
  | CanonicalPageFields_ServiceOverviewPageModelRecord_Fragment
  | CanonicalPageFields_TopicOverviewPageModelRecord_Fragment

export type PageMetaFieldsFragment = {
  __typename?: 'SeoModelRecord'
  doNotIndexPage: any
  title?: string | undefined
  description?: string | undefined
  canonicalPage?:
    | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
    | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
    | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
    | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
    | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
    | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
    | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
    | undefined
}

export type AssetFieldsFragment = {__typename?: 'FileField'; url: string; title?: string | undefined}

export type AssetWithSizeFieldsFragment = {
  __typename?: 'FileField'
  height?: any | undefined
  width?: any | undefined
  url: string
  title?: string | undefined
}

export type EmergencyOfferingSectionFieldsFragment = {
  __typename?: 'EmergencyOfferingSectionModelRecord'
  plot?: string | undefined
  title?: string | undefined
  description?: string | undefined
  actionButtonText?: string | undefined
  formTitle?: string | undefined
  formDescription?: string | undefined
  typeInputLabel?: string | undefined
  offerings: {
    __typename?: 'EmergencyOfferingModelRecord'
    title?: string | undefined
    collection?: string | undefined
    icon?: string | undefined
  }[]
}

export type EmergencyOfferingFieldsFragment = {
  __typename?: 'EmergencyOfferingModelRecord'
  title?: string | undefined
  collection?: string | undefined
  icon?: string | undefined
}

export type IndustryOverviewPageFieldsFragment = {
  __typename?: 'IndustryOverviewPageModelRecord'
  title?: string | undefined
  breadcrumbTitle?: string | undefined
  description?: string | undefined
  readingTime?: any | undefined
  industrySectionPlot?: string | undefined
  industrySectionTitle?: string | undefined
  proDirectoryIndustries?: string | undefined
  contentSectionPlot?: string | undefined
  contentSectionTitle?: string | undefined
  _locales: SiteLocale[]
  bannerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  offeringSection?:
    | {
        __typename?: 'OfferingSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        items2: (
          | {
              __typename?: 'OfferingDetailPageModelRecord'
              description?: string | undefined
              slug?: string | undefined
              offering?:
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingModelRecord'
              title?: string | undefined
              offeringId?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingSectionItemModelRecord'
              title?: string | undefined
              description?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              target?:
                | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                | undefined
            }
        )[]
      }
    | undefined
  author: {
    __typename?: 'AuthorModelRecord'
    description?: string | undefined
    name?: string | undefined
    partnerLogo?:
      | {
          __typename?: 'FileField'
          height?: any | undefined
          width?: any | undefined
          url: string
          title?: string | undefined
        }
      | undefined
    partnerLogoCta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    cta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    portrait?: {__typename?: 'FileField'; url: string} | undefined
  }[]
  mainCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  secondaryCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  inspirationSection?:
    | {
        __typename?: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | undefined
  supportSection?:
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
    | undefined
  industryItems: {
    __typename?: 'FieldHomePageIndustryItemModelRecord'
    title?: string | undefined
    collection?: string | undefined
    industryIcon?: string | undefined
    target?:
      | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
      | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
      | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
      | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
      | undefined
  }[]
  promotionalBannerSection?:
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | undefined
  contactForm?:
    | {
        __typename?: 'FieldContactFormModelRecord'
        alternativeCallToActionTitle?: string | undefined
        callToAction1?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        callToAction2?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
      }
    | undefined
  promotionalSection?:
    | {
        __typename?: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | undefined
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        changeFrequency?: string | undefined
        doNotIndexPage: any
        sitemapPriority?: string | undefined
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
  knowledgeSection?:
    | {
        __typename?: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | undefined
  content?:
    | {
        __typename?: 'IndustryOverviewPageModelModelContentField'
        value: any
        blocks: (
          | {
              __typename: 'StructuredTextAssetRecord'
              id: any
              file?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
        )[]
        links: (
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'ComponentBulletPointModelRecord'
              id: any
              lead?: string | undefined
              text?: string | undefined
              iconColor?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
            }
          | {
              __typename: 'ComponentButtonModelRecord'
              id: any
              label?: string | undefined
              typeField?: string | undefined
              targetPath?: string | undefined
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
        )[]
      }
    | undefined
  presentationSection?:
    | {
        __typename?: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | undefined
}

export type AuthorIdentityFieldsFragment = {
  __typename?: 'AuthorModelRecord'
  name?: string | undefined
  portrait?: {__typename?: 'FileField'; url: string} | undefined
}

export type AuthorFieldsFragment = {
  __typename?: 'AuthorModelRecord'
  description?: string | undefined
  name?: string | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
  partnerLogoCta?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  cta?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  portrait?: {__typename?: 'FileField'; url: string} | undefined
}

export type ContactFormFieldsFragment = {
  __typename?: 'FieldContactFormModelRecord'
  alternativeCallToActionTitle?: string | undefined
  callToAction1?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  callToAction2?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
}

type IndustryRichTextLinksFields_ComponentAccordionModelRecord_Fragment = {
  __typename: 'ComponentAccordionModelRecord'
  id: any
  name?: string | undefined
  elements: {
    __typename?: 'ComponentAccordionElementModelRecord'
    title?: string | undefined
    content?:
      | {
          __typename?: 'ComponentAccordionElementModelModelContentField'
          json: any
          blocks: {
            __typename: 'StructuredTextAssetRecord'
            id: any
            file?:
              | {
                  __typename?: 'FileField'
                  height?: any | undefined
                  width?: any | undefined
                  url: string
                  title?: string | undefined
                }
              | undefined
          }[]
          links: (
            | {
                __typename: 'ComponentBulletPointModelRecord'
                id: any
                lead?: string | undefined
                text?: string | undefined
                iconColor?: string | undefined
                icon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
                content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
              }
            | {
                __typename: 'ComponentButtonModelRecord'
                id: any
                label?: string | undefined
                typeField?: string | undefined
                targetPath?: string | undefined
              }
            | {
                __typename: 'PromotionBannerModelRecord'
                id: any
                title?: string | undefined
                contentText?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                partnerLogo?:
                  | {
                      __typename?: 'FileField'
                      height?: any | undefined
                      width?: any | undefined
                      url: string
                      title?: string | undefined
                    }
                  | undefined
              }
            | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
          )[]
        }
      | undefined
  }[]
}

type IndustryRichTextLinksFields_ComponentBulletPointModelRecord_Fragment = {
  __typename: 'ComponentBulletPointModelRecord'
  id: any
  lead?: string | undefined
  text?: string | undefined
  iconColor?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
}

type IndustryRichTextLinksFields_ComponentButtonModelRecord_Fragment = {
  __typename: 'ComponentButtonModelRecord'
  id: any
  label?: string | undefined
  typeField?: string | undefined
  targetPath?: string | undefined
}

type IndustryRichTextLinksFields_FieldSignupSectionRecord_Fragment = {
  __typename: 'FieldSignupSectionRecord'
  id: any
  title?: string | undefined
  description?: string | undefined
  formTitle?: string | undefined
  formButtonLabel?: string | undefined
  image?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
  topic?:
    | {
        __typename?: 'FieldMarketingTopicRecord'
        topic?: string | undefined
        sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
      }
    | undefined
}

type IndustryRichTextLinksFields_PromotionBannerModelRecord_Fragment = {
  __typename: 'PromotionBannerModelRecord'
  id: any
  title?: string | undefined
  contentText?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

type IndustryRichTextLinksFields_VideoModelRecord_Fragment = {
  __typename: 'VideoModelRecord'
  youtubeId?: string | undefined
  id: any
}

export type IndustryRichTextLinksFieldsFragment =
  | IndustryRichTextLinksFields_ComponentAccordionModelRecord_Fragment
  | IndustryRichTextLinksFields_ComponentBulletPointModelRecord_Fragment
  | IndustryRichTextLinksFields_ComponentButtonModelRecord_Fragment
  | IndustryRichTextLinksFields_FieldSignupSectionRecord_Fragment
  | IndustryRichTextLinksFields_PromotionBannerModelRecord_Fragment
  | IndustryRichTextLinksFields_VideoModelRecord_Fragment

export type ProDirectoryPageFieldsFragment = {
  __typename?: 'ProDirectoryPageModelRecord'
  content: (
    | {
        __typename: 'FieldSignupSectionRecord'
        id: any
        title?: string | undefined
        description?: string | undefined
        formTitle?: string | undefined
        formButtonLabel?: string | undefined
        image?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
        topic?:
          | {
              __typename?: 'FieldMarketingTopicRecord'
              topic?: string | undefined
              sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
            }
          | undefined
      }
    | {
        __typename?: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename?: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | {
        __typename?: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
  )[]
}

export type HomePageFieldsFragment = {
  __typename?: 'HomePageModelRecord'
  content: (
    | {
        __typename?: 'FieldContentSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        content?:
          | {
              __typename?: 'FieldContentSectionModelModelContentField'
              value: any
              blocks: (
                | {
                    __typename: 'StructuredTextAssetRecord'
                    id: any
                    file?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
              )[]
              links: (
                | {
                    __typename: 'ComponentAccordionModelRecord'
                    id: any
                    name?: string | undefined
                    elements: {
                      __typename?: 'ComponentAccordionElementModelRecord'
                      title?: string | undefined
                      content?:
                        | {
                            __typename?: 'ComponentAccordionElementModelModelContentField'
                            json: any
                            blocks: {
                              __typename: 'StructuredTextAssetRecord'
                              id: any
                              file?:
                                | {
                                    __typename?: 'FileField'
                                    height?: any | undefined
                                    width?: any | undefined
                                    url: string
                                    title?: string | undefined
                                  }
                                | undefined
                            }[]
                            links: (
                              | {
                                  __typename: 'ComponentBulletPointModelRecord'
                                  id: any
                                  lead?: string | undefined
                                  text?: string | undefined
                                  iconColor?: string | undefined
                                  icon?:
                                    | {
                                        __typename?: 'FontAwesomeIconModelRecord'
                                        collection?: string | undefined
                                        name?: string | undefined
                                      }
                                    | undefined
                                  content?:
                                    | {__typename?: 'ComponentBulletPointModelModelContentField'; json: any}
                                    | undefined
                                }
                              | {
                                  __typename: 'ComponentButtonModelRecord'
                                  id: any
                                  label?: string | undefined
                                  typeField?: string | undefined
                                  targetPath?: string | undefined
                                }
                              | {
                                  __typename: 'PromotionBannerModelRecord'
                                  id: any
                                  title?: string | undefined
                                  contentText?: string | undefined
                                  callToAction?:
                                    | {
                                        __typename?: 'FieldCallToActionModelRecord'
                                        title?: string | undefined
                                        actionType?: string | undefined
                                        actionValue?: string | undefined
                                        anchor?:
                                          | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                          | {__typename: 'FieldProMapSectionRecord'; id: any}
                                          | {__typename: 'FieldSignupSectionRecord'; id: any}
                                          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                          | {__typename: 'IndustrySectionModelRecord'; id: any}
                                          | {__typename: 'InspirationSectionModelRecord'; id: any}
                                          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                          | {__typename: 'OfferingSectionModelRecord'; id: any}
                                          | {__typename: 'PartnerSectionModelRecord'; id: any}
                                          | {__typename: 'PresentationSectionModelRecord'; id: any}
                                          | {__typename: 'PromotionBannerModelRecord'; id: any}
                                          | {__typename: 'ReviewSectionModelRecord'; id: any}
                                          | {__typename: 'ServicesSectionModelRecord'; id: any}
                                          | {__typename: 'SupportSectionModelRecord'; id: any}
                                          | undefined
                                      }
                                    | undefined
                                  background?:
                                    | {__typename?: 'FileField'; url: string; title?: string | undefined}
                                    | undefined
                                  partnerLogo?:
                                    | {
                                        __typename?: 'FileField'
                                        height?: any | undefined
                                        width?: any | undefined
                                        url: string
                                        title?: string | undefined
                                      }
                                    | undefined
                                }
                              | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                            )[]
                          }
                        | undefined
                    }[]
                  }
                | {
                    __typename: 'ComponentBulletPointModelRecord'
                    id: any
                    lead?: string | undefined
                    text?: string | undefined
                    iconColor?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                  }
                | {
                    __typename: 'ComponentButtonModelRecord'
                    id: any
                    label?: string | undefined
                    typeField?: string | undefined
                    targetPath?: string | undefined
                  }
                | {
                    __typename: 'FieldSignupSectionRecord'
                    id: any
                    title?: string | undefined
                    description?: string | undefined
                    formTitle?: string | undefined
                    formButtonLabel?: string | undefined
                    image?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                    topic?:
                      | {
                          __typename?: 'FieldMarketingTopicRecord'
                          topic?: string | undefined
                          sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                        }
                      | undefined
                  }
                | {
                    __typename: 'PromotionBannerModelRecord'
                    id: any
                    title?: string | undefined
                    contentText?: string | undefined
                    callToAction?:
                      | {
                          __typename?: 'FieldCallToActionModelRecord'
                          title?: string | undefined
                          actionType?: string | undefined
                          actionValue?: string | undefined
                          anchor?:
                            | {__typename: 'FieldContentSectionModelRecord'; id: any}
                            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                            | {__typename: 'FieldProMapSectionRecord'; id: any}
                            | {__typename: 'FieldSignupSectionRecord'; id: any}
                            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                            | {__typename: 'IndustrySectionModelRecord'; id: any}
                            | {__typename: 'InspirationSectionModelRecord'; id: any}
                            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                            | {__typename: 'OfferingSectionModelRecord'; id: any}
                            | {__typename: 'PartnerSectionModelRecord'; id: any}
                            | {__typename: 'PresentationSectionModelRecord'; id: any}
                            | {__typename: 'PromotionBannerModelRecord'; id: any}
                            | {__typename: 'ReviewSectionModelRecord'; id: any}
                            | {__typename: 'ServicesSectionModelRecord'; id: any}
                            | {__typename: 'SupportSectionModelRecord'; id: any}
                            | undefined
                        }
                      | undefined
                    background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                    partnerLogo?:
                      | {
                          __typename?: 'FileField'
                          height?: any | undefined
                          width?: any | undefined
                          url: string
                          title?: string | undefined
                        }
                      | undefined
                  }
                | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
              )[]
            }
          | undefined
      }
    | {
        __typename?: 'HowItWorksSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        actionButtonText?: string | undefined
        activeTab?: string | undefined
        items: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        strengthList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        contactUsList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename?: 'IndustrySectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        industryItems: {
          __typename?: 'FieldHomePageIndustryItemModelRecord'
          title?: string | undefined
          collection?: string | undefined
          industryIcon?: string | undefined
          target?:
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; slug?: string | undefined; path?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename?: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename?: 'KnowledgeSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        text?: string | undefined
        cta?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        faq?:
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | undefined
      }
    | {
        __typename?: 'PartnerSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PartnerItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          target?:
            | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename?: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | {
        __typename?: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
    | {
        __typename?: 'ServicesSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'ServiceItemModelRecord'
          id: any
          title?: string | undefined
          shortDescription?: string | undefined
          chipText?: string | undefined
          chipColour?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          serviceIcon?:
            | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
            | undefined
        }[]
      }
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
  )[]
}

export type CmsRegionFieldsFragment = {
  __typename?: 'RegionRecord'
  postalCodes?: string | undefined
  slug?: string | undefined
  clientFacingLabel?: string | undefined
  disableRegionSeoIndexing: any
  cmsId: any
}

export type CmsRegionWithExcludedIndustriesFieldsFragment = {
  __typename?: 'RegionRecord'
  postalCodes?: string | undefined
  slug?: string | undefined
  clientFacingLabel?: string | undefined
  disableRegionSeoIndexing: any
  cmsId: any
  excludedIndustries: {__typename?: 'IndustryModelRecord'; id?: any | undefined}[]
}

export type OfferingDetailPageFieldsFragment = {
  __typename?: 'OfferingDetailPageModelRecord'
  title?: string | undefined
  breadcrumbTitle?: string | undefined
  description?: string | undefined
  readingTime?: any | undefined
  contentTitle?: string | undefined
  contentPlot?: string | undefined
  _locales: SiteLocale[]
  offering?:
    | {
        __typename?: 'OfferingModelRecord'
        offeringId?: string | undefined
        title?: string | undefined
        icon?:
          | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
          | undefined
      }
    | undefined
  parentField?:
    | {
        __typename?: 'IndustryOverviewPageModelRecord'
        slug?: string | undefined
        title?: string | undefined
        breadcrumbTitle?: string | undefined
      }
    | {
        __typename?: 'StaticPageModelRecord'
        slug?: string | undefined
        path?: string | undefined
        title?: string | undefined
        breadcrumbTitle?: string | undefined
      }
    | undefined
  mainCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  secondaryCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  bannerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  howItWorksSection?:
    | {
        __typename?: 'HowItWorksSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        actionButtonText?: string | undefined
        activeTab?: string | undefined
        items: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        strengthList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
        contactUsList: {
          __typename?: 'HowItWorksItemModelRecord'
          id: any
          title?: string | undefined
          description?: string | undefined
          collection?: string | undefined
          howItWorksIcon?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | undefined
  relatedOfferingsSection?:
    | {
        __typename?: 'OfferingSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        items2: (
          | {
              __typename?: 'OfferingDetailPageModelRecord'
              description?: string | undefined
              slug?: string | undefined
              offering?:
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingModelRecord'
              title?: string | undefined
              offeringId?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingSectionItemModelRecord'
              title?: string | undefined
              description?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              target?:
                | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                | undefined
            }
        )[]
      }
    | undefined
  inspirationSection?:
    | {
        __typename?: 'InspirationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'InspirationItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          chipText?: string | undefined
          chipColor?: string | undefined
          background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | undefined
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        title?: string | undefined
        doNotIndexPage: any
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
  promotionalBannerSection?:
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | undefined
  supportSection?:
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
    | undefined
  contactForm?:
    | {
        __typename?: 'FieldContactFormModelRecord'
        alternativeCallToActionTitle?: string | undefined
        callToAction1?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        callToAction2?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
      }
    | undefined
  author: {
    __typename?: 'AuthorModelRecord'
    description?: string | undefined
    name?: string | undefined
    partnerLogo?:
      | {
          __typename?: 'FileField'
          height?: any | undefined
          width?: any | undefined
          url: string
          title?: string | undefined
        }
      | undefined
    partnerLogoCta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    cta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    portrait?: {__typename?: 'FileField'; url: string} | undefined
  }[]
  content?:
    | {
        __typename?: 'OfferingDetailPageModelModelContentField'
        value: any
        blocks: (
          | {
              __typename: 'StructuredTextAssetRecord'
              id: any
              file?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
        )[]
        links: (
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'ComponentBulletPointModelRecord'
              id: any
              lead?: string | undefined
              text?: string | undefined
              iconColor?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
            }
          | {
              __typename: 'ComponentButtonModelRecord'
              id: any
              label?: string | undefined
              typeField?: string | undefined
              targetPath?: string | undefined
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
        )[]
      }
    | undefined
  presentationSection?:
    | {
        __typename?: 'PresentationSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PresentationSectionItemModelRecord'
          title?: string | undefined
          youtubeId?: string | undefined
          content?:
            | {
                __typename?: 'PresentationSectionItemModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
              }
            | undefined
          image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          primaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          secondaryCta?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | undefined
}

type OfferingRichTextLinksFields_ComponentAccordionModelRecord_Fragment = {
  __typename: 'ComponentAccordionModelRecord'
  id: any
  name?: string | undefined
  elements: {
    __typename?: 'ComponentAccordionElementModelRecord'
    title?: string | undefined
    content?:
      | {
          __typename?: 'ComponentAccordionElementModelModelContentField'
          json: any
          blocks: {
            __typename: 'StructuredTextAssetRecord'
            id: any
            file?:
              | {
                  __typename?: 'FileField'
                  height?: any | undefined
                  width?: any | undefined
                  url: string
                  title?: string | undefined
                }
              | undefined
          }[]
          links: (
            | {
                __typename: 'ComponentBulletPointModelRecord'
                id: any
                lead?: string | undefined
                text?: string | undefined
                iconColor?: string | undefined
                icon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
                content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
              }
            | {
                __typename: 'ComponentButtonModelRecord'
                id: any
                label?: string | undefined
                typeField?: string | undefined
                targetPath?: string | undefined
              }
            | {
                __typename: 'PromotionBannerModelRecord'
                id: any
                title?: string | undefined
                contentText?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                partnerLogo?:
                  | {
                      __typename?: 'FileField'
                      height?: any | undefined
                      width?: any | undefined
                      url: string
                      title?: string | undefined
                    }
                  | undefined
              }
            | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
          )[]
        }
      | undefined
  }[]
}

type OfferingRichTextLinksFields_ComponentBulletPointModelRecord_Fragment = {
  __typename: 'ComponentBulletPointModelRecord'
  id: any
  lead?: string | undefined
  text?: string | undefined
  iconColor?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
}

type OfferingRichTextLinksFields_ComponentButtonModelRecord_Fragment = {
  __typename: 'ComponentButtonModelRecord'
  id: any
  label?: string | undefined
  typeField?: string | undefined
  targetPath?: string | undefined
}

type OfferingRichTextLinksFields_FieldSignupSectionRecord_Fragment = {
  __typename: 'FieldSignupSectionRecord'
  id: any
  title?: string | undefined
  description?: string | undefined
  formTitle?: string | undefined
  formButtonLabel?: string | undefined
  image?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
  topic?:
    | {
        __typename?: 'FieldMarketingTopicRecord'
        topic?: string | undefined
        sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
      }
    | undefined
}

type OfferingRichTextLinksFields_PromotionBannerModelRecord_Fragment = {
  __typename: 'PromotionBannerModelRecord'
  id: any
  title?: string | undefined
  contentText?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

type OfferingRichTextLinksFields_VideoModelRecord_Fragment = {
  __typename: 'VideoModelRecord'
  youtubeId?: string | undefined
  id: any
}

export type OfferingRichTextLinksFieldsFragment =
  | OfferingRichTextLinksFields_ComponentAccordionModelRecord_Fragment
  | OfferingRichTextLinksFields_ComponentBulletPointModelRecord_Fragment
  | OfferingRichTextLinksFields_ComponentButtonModelRecord_Fragment
  | OfferingRichTextLinksFields_FieldSignupSectionRecord_Fragment
  | OfferingRichTextLinksFields_PromotionBannerModelRecord_Fragment
  | OfferingRichTextLinksFields_VideoModelRecord_Fragment

export type OfferingFieldsFragment = {
  __typename?: 'OfferingModelRecord'
  offeringId?: string | undefined
  title?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
}

export type ServiceOverviewPageFieldsFragment = {
  __typename?: 'ServiceOverviewPageModelRecord'
  plot?: string | undefined
  title?: string | undefined
  description?: string | undefined
  _locales: SiteLocale[]
  servicesSection?:
    | {
        __typename?: 'ServicesSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'ServiceItemModelRecord'
          id: any
          title?: string | undefined
          shortDescription?: string | undefined
          chipText?: string | undefined
          chipColour?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          serviceIcon?:
            | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
            | undefined
        }[]
      }
    | undefined
  supportSection?:
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
    | undefined
  partnerSection?:
    | {
        __typename?: 'PartnerSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PartnerItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          target?:
            | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
            | undefined
        }[]
      }
    | undefined
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        changeFrequency?: string | undefined
        doNotIndexPage: any
        sitemapPriority?: string | undefined
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
}

export type StaticPageFieldsFragment = {
  __typename?: 'StaticPageModelRecord'
  serviceSection?:
    | {
        __typename?: 'ServicesSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        items: {
          __typename?: 'ServiceItemModelRecord'
          id: any
          title?: string | undefined
          shortDescription?: string | undefined
          chipText?: string | undefined
          chipColour?: string | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          serviceIcon?:
            | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
            | undefined
        }[]
      }
    | undefined
  supportSection?:
    | {
        __typename?: 'SupportSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        description?: string | undefined
        phoneNumber?: string | undefined
        email?: string | undefined
        contactTitle?: string | undefined
        contactDescription?: string | undefined
        contactName?: string | undefined
        contactRole?: string | undefined
        image?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
      }
    | undefined
  offeringSection?:
    | {
        __typename?: 'OfferingSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        items2: (
          | {
              __typename?: 'OfferingDetailPageModelRecord'
              description?: string | undefined
              slug?: string | undefined
              offering?:
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingModelRecord'
              title?: string | undefined
              offeringId?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingSectionItemModelRecord'
              title?: string | undefined
              description?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              target?:
                | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                | undefined
            }
        )[]
      }
    | undefined
  partnerSection?:
    | {
        __typename?: 'PartnerSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        items: {
          __typename?: 'PartnerItemModelRecord'
          title?: string | undefined
          description?: string | undefined
          logo?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
          target?:
            | {__typename?: 'ArticleModelRecord'; slug?: string | undefined}
            | {__typename?: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
            | {__typename?: 'StaticPageModelRecord'; path?: string | undefined}
            | undefined
        }[]
      }
    | undefined
  reviewSection?:
    | {
        __typename?: 'ReviewSectionModelRecord'
        id: any
        plot?: string | undefined
        title?: string | undefined
        description?: string | undefined
        callToActionTitle?: string | undefined
        reviews: {
          __typename?: 'ClientReviewModelRecord'
          author?: string | undefined
          comment?: string | undefined
          platform?: string | undefined
          rating?: any | undefined
        }[]
      }
    | undefined
  content?:
    | {
        __typename?: 'StaticPageModelModelContentField'
        value: any
        blocks: (
          | {
              __typename: 'StructuredTextAssetRecord'
              id: any
              file?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
        )[]
        links: (
          | {
              __typename: 'ComponentBulletPointModelRecord'
              id: any
              lead?: string | undefined
              text?: string | undefined
              iconColor?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
            }
          | {
              __typename: 'ComponentButtonModelRecord'
              id: any
              label?: string | undefined
              typeField?: string | undefined
              targetPath?: string | undefined
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
        )[]
      }
    | undefined
  promotionalBannerSection?:
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | undefined
}

type StaticPageRichTextLinksFields_ComponentBulletPointModelRecord_Fragment = {
  __typename: 'ComponentBulletPointModelRecord'
  id: any
  lead?: string | undefined
  text?: string | undefined
  iconColor?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
}

type StaticPageRichTextLinksFields_ComponentButtonModelRecord_Fragment = {
  __typename: 'ComponentButtonModelRecord'
  id: any
  label?: string | undefined
  typeField?: string | undefined
  targetPath?: string | undefined
}

type StaticPageRichTextLinksFields_FieldSignupSectionRecord_Fragment = {
  __typename: 'FieldSignupSectionRecord'
  id: any
  title?: string | undefined
  description?: string | undefined
  formTitle?: string | undefined
  formButtonLabel?: string | undefined
  image?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
  topic?:
    | {
        __typename?: 'FieldMarketingTopicRecord'
        topic?: string | undefined
        sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
      }
    | undefined
}

type StaticPageRichTextLinksFields_PromotionBannerModelRecord_Fragment = {
  __typename: 'PromotionBannerModelRecord'
  id: any
  title?: string | undefined
  contentText?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

export type StaticPageRichTextLinksFieldsFragment =
  | StaticPageRichTextLinksFields_ComponentBulletPointModelRecord_Fragment
  | StaticPageRichTextLinksFields_ComponentButtonModelRecord_Fragment
  | StaticPageRichTextLinksFields_FieldSignupSectionRecord_Fragment
  | StaticPageRichTextLinksFields_PromotionBannerModelRecord_Fragment

export type ArticlePageFieldsFragment = {
  __typename: 'ArticleModelRecord'
  id: any
  _publishedAt?: any | undefined
  _locales: SiteLocale[]
  release?: any | undefined
  slug?: string | undefined
  title?: string | undefined
  breadcrumbTitle?: string | undefined
  preview?: string | undefined
  readingTime?: any | undefined
  parentField?:
    | {
        __typename?: 'ServiceOverviewPageModelRecord'
        slug?: string | undefined
        title?: string | undefined
        breadcrumbTitle?: string | undefined
      }
    | undefined
  headerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  content?:
    | {
        __typename?: 'ArticleModelModelContentField'
        value: any
        blocks: (
          | {
              __typename: 'StructuredTextAssetRecord'
              id: any
              file?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'StructuredTextPlotRecord'; id: any; title?: string | undefined}
          | {__typename: 'TableRecord'; id: any; tableContent?: any | undefined}
        )[]
        links: (
          | {
              __typename: 'ComponentAccordionModelRecord'
              id: any
              name?: string | undefined
              elements: {
                __typename?: 'ComponentAccordionElementModelRecord'
                title?: string | undefined
                content?:
                  | {
                      __typename?: 'ComponentAccordionElementModelModelContentField'
                      json: any
                      blocks: {
                        __typename: 'StructuredTextAssetRecord'
                        id: any
                        file?:
                          | {
                              __typename?: 'FileField'
                              height?: any | undefined
                              width?: any | undefined
                              url: string
                              title?: string | undefined
                            }
                          | undefined
                      }[]
                      links: (
                        | {
                            __typename: 'ComponentBulletPointModelRecord'
                            id: any
                            lead?: string | undefined
                            text?: string | undefined
                            iconColor?: string | undefined
                            icon?:
                              | {
                                  __typename?: 'FontAwesomeIconModelRecord'
                                  collection?: string | undefined
                                  name?: string | undefined
                                }
                              | undefined
                            content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                          }
                        | {
                            __typename: 'ComponentButtonModelRecord'
                            id: any
                            label?: string | undefined
                            typeField?: string | undefined
                            targetPath?: string | undefined
                          }
                        | {
                            __typename: 'PromotionBannerModelRecord'
                            id: any
                            title?: string | undefined
                            contentText?: string | undefined
                            callToAction?:
                              | {
                                  __typename?: 'FieldCallToActionModelRecord'
                                  title?: string | undefined
                                  actionType?: string | undefined
                                  actionValue?: string | undefined
                                  anchor?:
                                    | {__typename: 'FieldContentSectionModelRecord'; id: any}
                                    | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                                    | {__typename: 'FieldProMapSectionRecord'; id: any}
                                    | {__typename: 'FieldSignupSectionRecord'; id: any}
                                    | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                                    | {__typename: 'IndustrySectionModelRecord'; id: any}
                                    | {__typename: 'InspirationSectionModelRecord'; id: any}
                                    | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                                    | {__typename: 'OfferingSectionModelRecord'; id: any}
                                    | {__typename: 'PartnerSectionModelRecord'; id: any}
                                    | {__typename: 'PresentationSectionModelRecord'; id: any}
                                    | {__typename: 'PromotionBannerModelRecord'; id: any}
                                    | {__typename: 'ReviewSectionModelRecord'; id: any}
                                    | {__typename: 'ServicesSectionModelRecord'; id: any}
                                    | {__typename: 'SupportSectionModelRecord'; id: any}
                                    | undefined
                                }
                              | undefined
                            background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                            partnerLogo?:
                              | {
                                  __typename?: 'FileField'
                                  height?: any | undefined
                                  width?: any | undefined
                                  url: string
                                  title?: string | undefined
                                }
                              | undefined
                          }
                        | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                      )[]
                    }
                  | undefined
              }[]
            }
          | {
              __typename: 'ComponentBulletPointModelRecord'
              id: any
              lead?: string | undefined
              text?: string | undefined
              iconColor?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
            }
          | {
              __typename: 'ComponentButtonModelRecord'
              id: any
              label?: string | undefined
              typeField?: string | undefined
              targetPath?: string | undefined
            }
          | {
              __typename: 'FieldSignupSectionRecord'
              id: any
              title?: string | undefined
              description?: string | undefined
              formTitle?: string | undefined
              formButtonLabel?: string | undefined
              image?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
              topic?:
                | {
                    __typename?: 'FieldMarketingTopicRecord'
                    topic?: string | undefined
                    sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
                  }
                | undefined
            }
          | {
              __typename: 'PromotionBannerModelRecord'
              id: any
              title?: string | undefined
              contentText?: string | undefined
              callToAction?:
                | {
                    __typename?: 'FieldCallToActionModelRecord'
                    title?: string | undefined
                    actionType?: string | undefined
                    actionValue?: string | undefined
                    anchor?:
                      | {__typename: 'FieldContentSectionModelRecord'; id: any}
                      | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                      | {__typename: 'FieldProMapSectionRecord'; id: any}
                      | {__typename: 'FieldSignupSectionRecord'; id: any}
                      | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                      | {__typename: 'IndustrySectionModelRecord'; id: any}
                      | {__typename: 'InspirationSectionModelRecord'; id: any}
                      | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                      | {__typename: 'OfferingSectionModelRecord'; id: any}
                      | {__typename: 'PartnerSectionModelRecord'; id: any}
                      | {__typename: 'PresentationSectionModelRecord'; id: any}
                      | {__typename: 'PromotionBannerModelRecord'; id: any}
                      | {__typename: 'ReviewSectionModelRecord'; id: any}
                      | {__typename: 'ServicesSectionModelRecord'; id: any}
                      | {__typename: 'SupportSectionModelRecord'; id: any}
                      | undefined
                  }
                | undefined
              background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
              partnerLogo?:
                | {
                    __typename?: 'FileField'
                    height?: any | undefined
                    width?: any | undefined
                    url: string
                    title?: string | undefined
                  }
                | undefined
            }
          | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
        )[]
      }
    | undefined
  author: {
    __typename?: 'AuthorModelRecord'
    description?: string | undefined
    name?: string | undefined
    partnerLogo?:
      | {
          __typename?: 'FileField'
          height?: any | undefined
          width?: any | undefined
          url: string
          title?: string | undefined
        }
      | undefined
    partnerLogoCta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    cta?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    portrait?: {__typename?: 'FileField'; url: string} | undefined
  }[]
  topic?:
    | {
        __typename?: 'TopicOverviewPageModelRecord'
        _locales: SiteLocale[]
        slug?: string | undefined
        title?: string | undefined
        breadcrumbTitle?: string | undefined
      }
    | undefined
  mainCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  secondaryCallToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  seo?:
    | {
        __typename?: 'SeoModelRecord'
        doNotIndexPage: any
        title?: string | undefined
        description?: string | undefined
        canonicalPage?:
          | {__typename: 'ArticleModelRecord'; slug?: string | undefined}
          | {__typename: 'CategoryLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'EmergencyLandingPageModelRecord'; slug?: string | undefined}
          | {__typename: 'IndustryOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
          | {__typename: 'ServiceOverviewPageModelRecord'; slug?: string | undefined}
          | {__typename: 'TopicOverviewPageModelRecord'; slug?: string | undefined}
          | undefined
      }
    | undefined
}

type ArticleRichTextLinksFields_ComponentAccordionModelRecord_Fragment = {
  __typename: 'ComponentAccordionModelRecord'
  id: any
  name?: string | undefined
  elements: {
    __typename?: 'ComponentAccordionElementModelRecord'
    title?: string | undefined
    content?:
      | {
          __typename?: 'ComponentAccordionElementModelModelContentField'
          json: any
          blocks: {
            __typename: 'StructuredTextAssetRecord'
            id: any
            file?:
              | {
                  __typename?: 'FileField'
                  height?: any | undefined
                  width?: any | undefined
                  url: string
                  title?: string | undefined
                }
              | undefined
          }[]
          links: (
            | {
                __typename: 'ComponentBulletPointModelRecord'
                id: any
                lead?: string | undefined
                text?: string | undefined
                iconColor?: string | undefined
                icon?:
                  | {
                      __typename?: 'FontAwesomeIconModelRecord'
                      collection?: string | undefined
                      name?: string | undefined
                    }
                  | undefined
                content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
              }
            | {
                __typename: 'ComponentButtonModelRecord'
                id: any
                label?: string | undefined
                typeField?: string | undefined
                targetPath?: string | undefined
              }
            | {
                __typename: 'PromotionBannerModelRecord'
                id: any
                title?: string | undefined
                contentText?: string | undefined
                callToAction?:
                  | {
                      __typename?: 'FieldCallToActionModelRecord'
                      title?: string | undefined
                      actionType?: string | undefined
                      actionValue?: string | undefined
                      anchor?:
                        | {__typename: 'FieldContentSectionModelRecord'; id: any}
                        | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                        | {__typename: 'FieldProMapSectionRecord'; id: any}
                        | {__typename: 'FieldSignupSectionRecord'; id: any}
                        | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                        | {__typename: 'IndustrySectionModelRecord'; id: any}
                        | {__typename: 'InspirationSectionModelRecord'; id: any}
                        | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                        | {__typename: 'OfferingSectionModelRecord'; id: any}
                        | {__typename: 'PartnerSectionModelRecord'; id: any}
                        | {__typename: 'PresentationSectionModelRecord'; id: any}
                        | {__typename: 'PromotionBannerModelRecord'; id: any}
                        | {__typename: 'ReviewSectionModelRecord'; id: any}
                        | {__typename: 'ServicesSectionModelRecord'; id: any}
                        | {__typename: 'SupportSectionModelRecord'; id: any}
                        | undefined
                    }
                  | undefined
                background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                partnerLogo?:
                  | {
                      __typename?: 'FileField'
                      height?: any | undefined
                      width?: any | undefined
                      url: string
                      title?: string | undefined
                    }
                  | undefined
              }
            | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
          )[]
        }
      | undefined
  }[]
}

type ArticleRichTextLinksFields_ComponentBulletPointModelRecord_Fragment = {
  __typename: 'ComponentBulletPointModelRecord'
  id: any
  lead?: string | undefined
  text?: string | undefined
  iconColor?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
}

type ArticleRichTextLinksFields_ComponentButtonModelRecord_Fragment = {
  __typename: 'ComponentButtonModelRecord'
  id: any
  label?: string | undefined
  typeField?: string | undefined
  targetPath?: string | undefined
}

type ArticleRichTextLinksFields_FieldSignupSectionRecord_Fragment = {
  __typename: 'FieldSignupSectionRecord'
  id: any
  title?: string | undefined
  description?: string | undefined
  formTitle?: string | undefined
  formButtonLabel?: string | undefined
  image?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
  topic?:
    | {
        __typename?: 'FieldMarketingTopicRecord'
        topic?: string | undefined
        sendgridListIds: {__typename?: 'SingleLineStringRecord'; value?: string | undefined}[]
      }
    | undefined
}

type ArticleRichTextLinksFields_PromotionBannerModelRecord_Fragment = {
  __typename: 'PromotionBannerModelRecord'
  id: any
  title?: string | undefined
  contentText?: string | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  partnerLogo?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
}

type ArticleRichTextLinksFields_VideoModelRecord_Fragment = {
  __typename: 'VideoModelRecord'
  youtubeId?: string | undefined
  id: any
}

export type ArticleRichTextLinksFieldsFragment =
  | ArticleRichTextLinksFields_ComponentAccordionModelRecord_Fragment
  | ArticleRichTextLinksFields_ComponentBulletPointModelRecord_Fragment
  | ArticleRichTextLinksFields_ComponentButtonModelRecord_Fragment
  | ArticleRichTextLinksFields_FieldSignupSectionRecord_Fragment
  | ArticleRichTextLinksFields_PromotionBannerModelRecord_Fragment
  | ArticleRichTextLinksFields_VideoModelRecord_Fragment

export type ArticleCtaFieldsFragment = {
  __typename?: 'ArticleCtaCardModelRecord'
  buttonText?: string | undefined
  title?: string | undefined
  typeField?: string | undefined
  options: {__typename?: 'ArticleCtaCardItemModelRecord'; text?: string | undefined; path?: string | undefined}[]
}

export type ContactFormCardFieldsFragment = {
  __typename?: 'FieldContactFormModelRecord'
  alternativeCallToActionTitle?: string | undefined
  callToAction1?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  callToAction2?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
}

export type TopicOverviewPageFieldsFragment = {
  __typename?: 'TopicOverviewPageModelRecord'
  title?: string | undefined
  slug?: string | undefined
  breadcrumbTitle?: string | undefined
  description?: string | undefined
  _locales: SiteLocale[]
}

export type TopicArticleFieldsFragment = {
  __typename?: 'ArticleModelRecord'
  title?: string | undefined
  slug?: string | undefined
}

export type TopicListItemFieldsFragment = {
  __typename?: 'TopicOverviewPageModelRecord'
  id: any
  slug?: string | undefined
  title?: string | undefined
  breadcrumbTitle?: string | undefined
}

export type ComponentDropdownMenuFieldsFragment = {
  __typename?: 'ComponentDropdownMenuModelRecord'
  slug?: string | undefined
  title?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  childrenField: {
    __typename?: 'ComponentDropdownMenuItemModelRecord'
    title?: string | undefined
    icon?:
      | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
      | undefined
    link?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    childrenField: {
      __typename?: 'ComponentDropdownMenuItemModelRecord'
      title?: string | undefined
      icon?:
        | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
        | undefined
      link?:
        | {
            __typename?: 'FieldCallToActionModelRecord'
            title?: string | undefined
            actionType?: string | undefined
            actionValue?: string | undefined
            anchor?:
              | {__typename: 'FieldContentSectionModelRecord'; id: any}
              | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
              | {__typename: 'FieldProMapSectionRecord'; id: any}
              | {__typename: 'FieldSignupSectionRecord'; id: any}
              | {__typename: 'HowItWorksSectionModelRecord'; id: any}
              | {__typename: 'IndustrySectionModelRecord'; id: any}
              | {__typename: 'InspirationSectionModelRecord'; id: any}
              | {__typename: 'KnowledgeSectionModelRecord'; id: any}
              | {__typename: 'OfferingSectionModelRecord'; id: any}
              | {__typename: 'PartnerSectionModelRecord'; id: any}
              | {__typename: 'PresentationSectionModelRecord'; id: any}
              | {__typename: 'PromotionBannerModelRecord'; id: any}
              | {__typename: 'ReviewSectionModelRecord'; id: any}
              | {__typename: 'ServicesSectionModelRecord'; id: any}
              | {__typename: 'SupportSectionModelRecord'; id: any}
              | undefined
          }
        | undefined
    }[]
  }[]
}

export type ComponentDropdownMenuItemFieldsFragment = {
  __typename?: 'ComponentDropdownMenuItemModelRecord'
  title?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  link?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  childrenField: {
    __typename?: 'ComponentDropdownMenuItemModelRecord'
    title?: string | undefined
    icon?:
      | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
      | undefined
    link?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
  }[]
}

export type AnnouncementBannerFieldsFragment = {
  __typename?: 'AnnouncementBannerV2ModelRecord'
  id: any
  location?: string | undefined
  textMessage?: string | undefined
  linkText?: string | undefined
  link?: string | undefined
}

export type IndustryItemSummaryFieldsFragment = {
  __typename?: 'FieldHomePageIndustryItemModelRecord'
  title?: string | undefined
  icon?: string | undefined
  collection?: string | undefined
  target?:
    | {__typename?: 'CategoryLandingPageModelRecord'}
    | {__typename?: 'EmergencyLandingPageModelRecord'}
    | {
        __typename?: 'IndustryOverviewPageModelRecord'
        slug?: string | undefined
        offeringSection?:
          | {
              __typename?: 'OfferingSectionModelRecord'
              id: any
              title?: string | undefined
              plot?: string | undefined
              items2: (
                | {
                    __typename?: 'OfferingDetailPageModelRecord'
                    description?: string | undefined
                    slug?: string | undefined
                    offering?:
                      | {
                          __typename?: 'OfferingModelRecord'
                          title?: string | undefined
                          icon?:
                            | {
                                __typename?: 'FontAwesomeIconModelRecord'
                                collection?: string | undefined
                                name?: string | undefined
                              }
                            | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    offeringId?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | {
                    __typename?: 'OfferingSectionItemModelRecord'
                    title?: string | undefined
                    description?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                    target?:
                      | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                      | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                      | undefined
                  }
              )[]
            }
          | undefined
      }
    | {__typename?: 'StaticPageModelRecord'}
    | undefined
}

export type IndustryOverviewSummaryFieldsFragment = {
  __typename?: 'IndustryOverviewPageModelRecord'
  slug?: string | undefined
  offeringSection?:
    | {
        __typename?: 'OfferingSectionModelRecord'
        id: any
        title?: string | undefined
        plot?: string | undefined
        items2: (
          | {
              __typename?: 'OfferingDetailPageModelRecord'
              description?: string | undefined
              slug?: string | undefined
              offering?:
                | {
                    __typename?: 'OfferingModelRecord'
                    title?: string | undefined
                    icon?:
                      | {
                          __typename?: 'FontAwesomeIconModelRecord'
                          collection?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingModelRecord'
              title?: string | undefined
              offeringId?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
            }
          | {
              __typename?: 'OfferingSectionItemModelRecord'
              title?: string | undefined
              description?: string | undefined
              icon?:
                | {
                    __typename?: 'FontAwesomeIconModelRecord'
                    collection?: string | undefined
                    name?: string | undefined
                  }
                | undefined
              target?:
                | {__typename?: 'OfferingDetailPageModelRecord'; slug?: string | undefined}
                | {__typename?: 'OfferingModelRecord'; offeringId?: string | undefined}
                | undefined
            }
        )[]
      }
    | undefined
}

export type ProRegisterPlanPageFieldsFragment = {
  __typename?: 'ProRegisterPlanPageModelRecord'
  slug?: string | undefined
  title?: string | undefined
  description?: string | undefined
  headerImage?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
  sellingPoints?:
    | {
        __typename?: 'ComponentTabModelRecord'
        slug?: string | undefined
        elements: {
          __typename?: 'ComponentTabElementModelRecord'
          title?: string | undefined
          headline?: string | undefined
          text?: string | undefined
          icon?:
            | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
            | undefined
          image?:
            | {
                __typename?: 'FileField'
                height?: any | undefined
                width?: any | undefined
                url: string
                title?: string | undefined
              }
            | undefined
          callToAction?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
          callToAction2?:
            | {
                __typename?: 'FieldCallToActionModelRecord'
                title?: string | undefined
                actionType?: string | undefined
                actionValue?: string | undefined
                anchor?:
                  | {__typename: 'FieldContentSectionModelRecord'; id: any}
                  | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                  | {__typename: 'FieldProMapSectionRecord'; id: any}
                  | {__typename: 'FieldSignupSectionRecord'; id: any}
                  | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                  | {__typename: 'IndustrySectionModelRecord'; id: any}
                  | {__typename: 'InspirationSectionModelRecord'; id: any}
                  | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                  | {__typename: 'OfferingSectionModelRecord'; id: any}
                  | {__typename: 'PartnerSectionModelRecord'; id: any}
                  | {__typename: 'PresentationSectionModelRecord'; id: any}
                  | {__typename: 'PromotionBannerModelRecord'; id: any}
                  | {__typename: 'ReviewSectionModelRecord'; id: any}
                  | {__typename: 'ServicesSectionModelRecord'; id: any}
                  | {__typename: 'SupportSectionModelRecord'; id: any}
                  | undefined
              }
            | undefined
        }[]
      }
    | undefined
  faq?:
    | {
        __typename: 'ComponentAccordionModelRecord'
        id: any
        name?: string | undefined
        elements: {
          __typename?: 'ComponentAccordionElementModelRecord'
          title?: string | undefined
          content?:
            | {
                __typename?: 'ComponentAccordionElementModelModelContentField'
                json: any
                blocks: {
                  __typename: 'StructuredTextAssetRecord'
                  id: any
                  file?:
                    | {
                        __typename?: 'FileField'
                        height?: any | undefined
                        width?: any | undefined
                        url: string
                        title?: string | undefined
                      }
                    | undefined
                }[]
                links: (
                  | {
                      __typename: 'ComponentBulletPointModelRecord'
                      id: any
                      lead?: string | undefined
                      text?: string | undefined
                      iconColor?: string | undefined
                      icon?:
                        | {
                            __typename?: 'FontAwesomeIconModelRecord'
                            collection?: string | undefined
                            name?: string | undefined
                          }
                        | undefined
                      content?: {__typename?: 'ComponentBulletPointModelModelContentField'; json: any} | undefined
                    }
                  | {
                      __typename: 'ComponentButtonModelRecord'
                      id: any
                      label?: string | undefined
                      typeField?: string | undefined
                      targetPath?: string | undefined
                    }
                  | {
                      __typename: 'PromotionBannerModelRecord'
                      id: any
                      title?: string | undefined
                      contentText?: string | undefined
                      callToAction?:
                        | {
                            __typename?: 'FieldCallToActionModelRecord'
                            title?: string | undefined
                            actionType?: string | undefined
                            actionValue?: string | undefined
                            anchor?:
                              | {__typename: 'FieldContentSectionModelRecord'; id: any}
                              | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                              | {__typename: 'FieldProMapSectionRecord'; id: any}
                              | {__typename: 'FieldSignupSectionRecord'; id: any}
                              | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                              | {__typename: 'IndustrySectionModelRecord'; id: any}
                              | {__typename: 'InspirationSectionModelRecord'; id: any}
                              | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                              | {__typename: 'OfferingSectionModelRecord'; id: any}
                              | {__typename: 'PartnerSectionModelRecord'; id: any}
                              | {__typename: 'PresentationSectionModelRecord'; id: any}
                              | {__typename: 'PromotionBannerModelRecord'; id: any}
                              | {__typename: 'ReviewSectionModelRecord'; id: any}
                              | {__typename: 'ServicesSectionModelRecord'; id: any}
                              | {__typename: 'SupportSectionModelRecord'; id: any}
                              | undefined
                          }
                        | undefined
                      background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
                      partnerLogo?:
                        | {
                            __typename?: 'FileField'
                            height?: any | undefined
                            width?: any | undefined
                            url: string
                            title?: string | undefined
                          }
                        | undefined
                    }
                  | {__typename: 'VideoModelRecord'; youtubeId?: string | undefined; id: any}
                )[]
              }
            | undefined
        }[]
      }
    | undefined
  promotionalBanner?:
    | {
        __typename: 'PromotionBannerModelRecord'
        id: any
        title?: string | undefined
        contentText?: string | undefined
        callToAction?:
          | {
              __typename?: 'FieldCallToActionModelRecord'
              title?: string | undefined
              actionType?: string | undefined
              actionValue?: string | undefined
              anchor?:
                | {__typename: 'FieldContentSectionModelRecord'; id: any}
                | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
                | {__typename: 'FieldProMapSectionRecord'; id: any}
                | {__typename: 'FieldSignupSectionRecord'; id: any}
                | {__typename: 'HowItWorksSectionModelRecord'; id: any}
                | {__typename: 'IndustrySectionModelRecord'; id: any}
                | {__typename: 'InspirationSectionModelRecord'; id: any}
                | {__typename: 'KnowledgeSectionModelRecord'; id: any}
                | {__typename: 'OfferingSectionModelRecord'; id: any}
                | {__typename: 'PartnerSectionModelRecord'; id: any}
                | {__typename: 'PresentationSectionModelRecord'; id: any}
                | {__typename: 'PromotionBannerModelRecord'; id: any}
                | {__typename: 'ReviewSectionModelRecord'; id: any}
                | {__typename: 'ServicesSectionModelRecord'; id: any}
                | {__typename: 'SupportSectionModelRecord'; id: any}
                | undefined
            }
          | undefined
        background?: {__typename?: 'FileField'; url: string; title?: string | undefined} | undefined
        partnerLogo?:
          | {
              __typename?: 'FileField'
              height?: any | undefined
              width?: any | undefined
              url: string
              title?: string | undefined
            }
          | undefined
      }
    | undefined
}

export type ComponentTabFieldsFragment = {
  __typename?: 'ComponentTabModelRecord'
  slug?: string | undefined
  elements: {
    __typename?: 'ComponentTabElementModelRecord'
    title?: string | undefined
    headline?: string | undefined
    text?: string | undefined
    icon?:
      | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
      | undefined
    image?:
      | {
          __typename?: 'FileField'
          height?: any | undefined
          width?: any | undefined
          url: string
          title?: string | undefined
        }
      | undefined
    callToAction?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
    callToAction2?:
      | {
          __typename?: 'FieldCallToActionModelRecord'
          title?: string | undefined
          actionType?: string | undefined
          actionValue?: string | undefined
          anchor?:
            | {__typename: 'FieldContentSectionModelRecord'; id: any}
            | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
            | {__typename: 'FieldProMapSectionRecord'; id: any}
            | {__typename: 'FieldSignupSectionRecord'; id: any}
            | {__typename: 'HowItWorksSectionModelRecord'; id: any}
            | {__typename: 'IndustrySectionModelRecord'; id: any}
            | {__typename: 'InspirationSectionModelRecord'; id: any}
            | {__typename: 'KnowledgeSectionModelRecord'; id: any}
            | {__typename: 'OfferingSectionModelRecord'; id: any}
            | {__typename: 'PartnerSectionModelRecord'; id: any}
            | {__typename: 'PresentationSectionModelRecord'; id: any}
            | {__typename: 'PromotionBannerModelRecord'; id: any}
            | {__typename: 'ReviewSectionModelRecord'; id: any}
            | {__typename: 'ServicesSectionModelRecord'; id: any}
            | {__typename: 'SupportSectionModelRecord'; id: any}
            | undefined
        }
      | undefined
  }[]
}

export type ComponentTabElementFieldsFragment = {
  __typename?: 'ComponentTabElementModelRecord'
  title?: string | undefined
  headline?: string | undefined
  text?: string | undefined
  icon?:
    | {__typename?: 'FontAwesomeIconModelRecord'; collection?: string | undefined; name?: string | undefined}
    | undefined
  image?:
    | {
        __typename?: 'FileField'
        height?: any | undefined
        width?: any | undefined
        url: string
        title?: string | undefined
      }
    | undefined
  callToAction?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
  callToAction2?:
    | {
        __typename?: 'FieldCallToActionModelRecord'
        title?: string | undefined
        actionType?: string | undefined
        actionValue?: string | undefined
        anchor?:
          | {__typename: 'FieldContentSectionModelRecord'; id: any}
          | {__typename: 'FieldDynamicProDirectorySectionModelRecord'; id: any}
          | {__typename: 'FieldProMapSectionRecord'; id: any}
          | {__typename: 'FieldSignupSectionRecord'; id: any}
          | {__typename: 'HowItWorksSectionModelRecord'; id: any}
          | {__typename: 'IndustrySectionModelRecord'; id: any}
          | {__typename: 'InspirationSectionModelRecord'; id: any}
          | {__typename: 'KnowledgeSectionModelRecord'; id: any}
          | {__typename: 'OfferingSectionModelRecord'; id: any}
          | {__typename: 'PartnerSectionModelRecord'; id: any}
          | {__typename: 'PresentationSectionModelRecord'; id: any}
          | {__typename: 'PromotionBannerModelRecord'; id: any}
          | {__typename: 'ReviewSectionModelRecord'; id: any}
          | {__typename: 'ServicesSectionModelRecord'; id: any}
          | {__typename: 'SupportSectionModelRecord'; id: any}
          | undefined
      }
    | undefined
}

export type GetArticleAuthorNamesQueryVariables = Exact<Record<string, never>>

export type GetArticleAuthorNamesQuery = {
  __typename?: 'Query'
  allAuthorModels: {__typename?: 'AuthorModelRecord'; name?: string | undefined}[]
}

export type ArticleAuthorFieldsFragment = {__typename?: 'AuthorModelRecord'; name?: string | undefined}

export type GetAllRegionsQueryVariables = Exact<{
  locale: SiteLocale
}>

export type GetAllRegionsQuery = {
  __typename?: 'Query'
  allRegions: {
    __typename?: 'RegionRecord'
    postalCodes?: string | undefined
    slug?: string | undefined
    clientFacingLabel?: string | undefined
    disableRegionSeoIndexing: any
    cmsId: any
  }[]
}

export type IndustryOverviewPageSideNavFieldsFragment = {
  __typename?: 'IndustryOverviewPageModelRecord'
  slug?: string | undefined
  title?: string | undefined
  breadcrumbTitle?: string | undefined
  proDirectoryIndustries?: string | undefined
}

export type ProMapSectionFieldsFragment = {__typename: 'FieldProMapSectionRecord'; id: any; title?: string | undefined}

export const CmsRegionFieldsFragmentDoc = gql`
  fragment CmsRegionFields on RegionRecord {
    cmsId: id
    postalCodes
    slug
    clientFacingLabel
    disableRegionSeoIndexing
  }
`
export const RegionalIndustryFieldsFragmentDoc = gql`
  fragment RegionalIndustryFields on IndustryModelRecord {
    id: idField
    cmsId: id
    slug
    label
    textVariable
    hideInGeneratedPages
  }
`
export const CallToActionFieldsFragmentDoc = gql`
  fragment CallToActionFields on FieldCallToActionModelRecord {
    title
    actionType
    actionValue
    anchor {
      __typename
      ... on RecordInterface {
        id
      }
    }
  }
`
export const AssetFieldsFragmentDoc = gql`
  fragment AssetFields on FileField {
    url
    title
  }
`
export const AssetWithSizeFieldsFragmentDoc = gql`
  fragment AssetWithSizeFields on FileField {
    ...AssetFields
    height
    width
  }
  ${AssetFieldsFragmentDoc}
`
export const PromotionalBannerSectionFieldsFragmentDoc = gql`
  fragment PromotionalBannerSectionFields on PromotionBannerModelRecord {
    __typename
    id
    title
    contentText: content
    callToAction {
      ...CallToActionFields
    }
    background {
      ...AssetFields
    }
    partnerLogo {
      ...AssetWithSizeFields
    }
  }
  ${CallToActionFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${AssetWithSizeFieldsFragmentDoc}
`
export const StructuredTextAssetFieldsFragmentDoc = gql`
  fragment StructuredTextAssetFields on StructuredTextAssetRecord {
    __typename
    id
    file {
      ...AssetWithSizeFields
    }
  }
  ${AssetWithSizeFieldsFragmentDoc}
`
export const FontAwesomeFieldsFragmentDoc = gql`
  fragment FontAwesomeFields on FontAwesomeIconModelRecord {
    collection
    name
  }
`
export const ComponentBulletPointFieldsFragmentDoc = gql`
  fragment ComponentBulletPointFields on ComponentBulletPointModelRecord {
    __typename
    id
    lead
    text
    icon {
      ...FontAwesomeFields
    }
    content {
      json: value
    }
    iconColor
  }
  ${FontAwesomeFieldsFragmentDoc}
`
export const ComponentButtonFieldsFragmentDoc = gql`
  fragment ComponentButtonFields on ComponentButtonModelRecord {
    __typename
    id
    label
    typeField
    targetPath
  }
`
export const VideoFieldsFragmentDoc = gql`
  fragment VideoFields on VideoModelRecord {
    __typename
    youtubeId
    id
  }
`
export const ComponentAccordionElementLinksFieldsFragmentDoc = gql`
  fragment ComponentAccordionElementLinksFields on ComponentAccordionElementModelModelContentLinksField {
    ... on ComponentBulletPointModelRecord {
      ...ComponentBulletPointFields
    }
    ... on ComponentButtonModelRecord {
      ...ComponentButtonFields
    }
    ... on VideoModelRecord {
      ...VideoFields
    }
    ... on PromotionBannerModelRecord {
      ...PromotionalBannerSectionFields
    }
  }
  ${ComponentBulletPointFieldsFragmentDoc}
  ${ComponentButtonFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
`
export const ComponentAccordionFieldsFragmentDoc = gql`
  fragment ComponentAccordionFields on ComponentAccordionModelRecord {
    __typename
    id
    name
    elements {
      ... on ComponentAccordionElementModelRecord {
        title
        content {
          ... on ComponentAccordionElementModelModelContentField {
            json: value
            blocks {
              ... on StructuredTextAssetRecord {
                ...StructuredTextAssetFields
              }
            }
            links {
              ...ComponentAccordionElementLinksFields
            }
          }
        }
      }
    }
  }
  ${StructuredTextAssetFieldsFragmentDoc}
  ${ComponentAccordionElementLinksFieldsFragmentDoc}
`
export const KnowledgeSectionFieldsFragmentDoc = gql`
  fragment KnowledgeSectionFields on KnowledgeSectionModelRecord {
    id
    title
    plot
    text
    cta {
      ...CallToActionFields
    }
    faq {
      ...ComponentAccordionFields
    }
  }
  ${CallToActionFieldsFragmentDoc}
  ${ComponentAccordionFieldsFragmentDoc}
`
export const ClientReviewFieldsFragmentDoc = gql`
  fragment ClientReviewFields on ClientReviewModelRecord {
    author
    comment
    platform
    rating
  }
`
export const ReviewSectionFieldsFragmentDoc = gql`
  fragment ReviewSectionFields on ReviewSectionModelRecord {
    id
    plot
    title
    description
    callToActionTitle
    reviews {
      ...ClientReviewFields
    }
  }
  ${ClientReviewFieldsFragmentDoc}
`
export const InspirationItemFieldsFragmentDoc = gql`
  fragment InspirationItemFields on InspirationItemModelRecord {
    title
    description
    background {
      ...AssetFields
    }
    chipText
    chipColor
    callToAction {
      ...CallToActionFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
`
export const InspirationSectionFieldsFragmentDoc = gql`
  fragment InspirationSectionFields on InspirationSectionModelRecord {
    id
    plot
    title
    description
    items {
      ... on InspirationItemModelRecord {
        ...InspirationItemFields
      }
    }
  }
  ${InspirationItemFieldsFragmentDoc}
`
export const DataTableFieldsFragmentDoc = gql`
  fragment DataTableFields on TableRecord {
    __typename
    id
    tableContent
  }
`
export const SignupSectionFieldsFragmentDoc = gql`
  fragment SignupSectionFields on FieldSignupSectionRecord {
    __typename
    id
    title
    description
    formTitle
    formButtonLabel
    image {
      ...AssetWithSizeFields
    }
    topic {
      topic
      sendgridListIds {
        value
      }
    }
  }
  ${AssetWithSizeFieldsFragmentDoc}
`
export const ContentSectionFieldsFragmentDoc = gql`
  fragment ContentSectionFields on FieldContentSectionModelRecord {
    id
    title
    plot
    content {
      value
      blocks {
        ... on StructuredTextAssetRecord {
          ...StructuredTextAssetFields
        }
        ...DataTableFields
      }
      links {
        ... on ComponentBulletPointModelRecord {
          ...ComponentBulletPointFields
        }
        ... on ComponentButtonModelRecord {
          ...ComponentButtonFields
        }
        ... on VideoModelRecord {
          ...VideoFields
        }
        ... on PromotionBannerModelRecord {
          ...PromotionalBannerSectionFields
        }
        ... on ComponentAccordionModelRecord {
          ...ComponentAccordionFields
        }
        ... on FieldSignupSectionRecord {
          ...SignupSectionFields
        }
      }
    }
  }
  ${StructuredTextAssetFieldsFragmentDoc}
  ${DataTableFieldsFragmentDoc}
  ${ComponentBulletPointFieldsFragmentDoc}
  ${ComponentButtonFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ComponentAccordionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const CraftsmenRegionDirectoryPageFieldsFragmentDoc = gql`
  fragment CraftsmenRegionDirectoryPageFields on CraftsmenRegionDirectoryPageRecord {
    descriptiveTitle
    topRegions {
      ...CmsRegionFields
    }
    excludedIndustries {
      ...RegionalIndustryFields
    }
    content {
      ... on PromotionBannerModelRecord {
        ...PromotionalBannerSectionFields
      }
      ... on KnowledgeSectionModelRecord {
        ...KnowledgeSectionFields
      }
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on InspirationSectionModelRecord {
        ...InspirationSectionFields
      }
      ... on FieldContentSectionModelRecord {
        ...ContentSectionFields
      }
      ... on FieldSignupSectionRecord {
        ...SignupSectionFields
      }
    }
  }
  ${CmsRegionFieldsFragmentDoc}
  ${RegionalIndustryFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${InspirationSectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const CraftsmenRegionPageIndustryFieldsFragmentDoc = gql`
  fragment CraftsmenRegionPageIndustryFields on IndustryModelRecord {
    slug
    label
    hideInGeneratedPages
  }
`
export const SupportSectionFieldsFragmentDoc = gql`
  fragment SupportSectionFields on SupportSectionModelRecord {
    id
    title
    plot
    description
    phoneNumber
    email
    contactTitle
    contactDescription
    contactName
    contactRole
    image {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`
export const PresentationSectionItemFieldsFragmentDoc = gql`
  fragment PresentationSectionItemFields on PresentationSectionItemModelRecord {
    title
    youtubeId
    content {
      json: value
      blocks {
        ... on StructuredTextAssetRecord {
          ...StructuredTextAssetFields
        }
      }
    }
    image {
      ...AssetFields
    }
    primaryCta {
      ...CallToActionFields
    }
    secondaryCta {
      ...CallToActionFields
    }
  }
  ${StructuredTextAssetFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
`
export const PresentationSectionFieldsFragmentDoc = gql`
  fragment PresentationSectionFields on PresentationSectionModelRecord {
    id
    plot
    title
    items {
      ...PresentationSectionItemFields
    }
  }
  ${PresentationSectionItemFieldsFragmentDoc}
`
export const ProMapSectionFieldsFragmentDoc = gql`
  fragment ProMapSectionFields on FieldProMapSectionRecord {
    __typename
    id
    title
  }
`
export const CanonicalPageFieldsFragmentDoc = gql`
  fragment CanonicalPageFields on SeoModelModelCanonicalPageField {
    ... on ArticleModelRecord {
      __typename
      slug
    }
    ... on TopicOverviewPageModelRecord {
      __typename
      slug
    }
    ... on ServiceOverviewPageModelRecord {
      __typename
      slug
    }
    ... on IndustryOverviewPageModelRecord {
      __typename
      slug
    }
    ... on EmergencyLandingPageModelRecord {
      __typename
      slug
    }
    ... on CategoryLandingPageModelRecord {
      __typename
      slug
    }
    ... on OfferingDetailPageModelRecord {
      __typename
      slug
    }
  }
`
export const PageMetaFieldsFragmentDoc = gql`
  fragment PageMetaFields on SeoModelRecord {
    doNotIndexPage
    title
    description
    canonicalPage {
      ...CanonicalPageFields
    }
  }
  ${CanonicalPageFieldsFragmentDoc}
`
export const CraftsmenRegionPageFieldsFragmentDoc = gql`
  fragment CraftsmenRegionPageFields on RegionCraftsmenPageRecord {
    coverTitle
    coverSubtitle
    featuredIndustries {
      ... on IndustryModelRecord {
        ...CraftsmenRegionPageIndustryFields
      }
    }
    content {
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on SupportSectionModelRecord {
        ...SupportSectionFields
      }
      ... on KnowledgeSectionModelRecord {
        ...KnowledgeSectionFields
      }
      ... on PresentationSectionModelRecord {
        ...PresentationSectionFields
      }
      ... on FieldContentSectionModelRecord {
        ...ContentSectionFields
      }
      ... on FieldSignupSectionRecord {
        ...SignupSectionFields
      }
      ... on FieldProMapSectionRecord {
        ...ProMapSectionFields
      }
    }
    seo {
      ...PageMetaFields
    }
  }
  ${CraftsmenRegionPageIndustryFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${PresentationSectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
  ${ProMapSectionFieldsFragmentDoc}
  ${PageMetaFieldsFragmentDoc}
`
export const IndustryRegionPageFieldsFragmentDoc = gql`
  fragment IndustryRegionPageFields on IndustryRegionPageRecord {
    coverTitle
    coverSubtitle
    content {
      ... on PresentationSectionModelRecord {
        ...PresentationSectionFields
      }
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on SupportSectionModelRecord {
        ...SupportSectionFields
      }
      ... on FieldContentSectionModelRecord {
        ...ContentSectionFields
      }
      ... on FieldSignupSectionRecord {
        ...SignupSectionFields
      }
      ... on FieldProMapSectionRecord {
        ...ProMapSectionFields
      }
    }
    seo {
      ...PageMetaFields
    }
  }
  ${PresentationSectionFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
  ${ProMapSectionFieldsFragmentDoc}
  ${PageMetaFieldsFragmentDoc}
`
export const OfferingCoverFieldsFragmentDoc = gql`
  fragment OfferingCoverFields on OfferingPageModelRecord {
    coverTitle
    coverSubtitle
    displayCoverSellingPointBar
  }
`
export const OfferingPageFieldsFragmentDoc = gql`
  fragment OfferingPageFields on OfferingPageModelRecord {
    ...OfferingCoverFields
    content {
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on SupportSectionModelRecord {
        ...SupportSectionFields
      }
      ... on FieldProDirectorySectionModelRecord {
        typeField
      }
      ... on KnowledgeSectionModelRecord {
        ...KnowledgeSectionFields
      }
      ... on PresentationSectionModelRecord {
        ...PresentationSectionFields
      }
      ... on FieldSignupSectionRecord {
        ...SignupSectionFields
      }
      ... on FieldContentSectionModelRecord {
        ...ContentSectionFields
      }
    }
    seo {
      ...PageMetaFields
    }
    _locales
  }
  ${OfferingCoverFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${PresentationSectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${PageMetaFieldsFragmentDoc}
`
export const OfferingDataFieldsFragmentDoc = gql`
  fragment OfferingDataFields on OfferingDataModelRecord {
    id: idField
    cmsId: id
    label
    clientFacingLabel
    icon {
      ...FontAwesomeFields
    }
    industryPage {
      slug
      title
      breadcrumbTitle
    }
  }
  ${FontAwesomeFieldsFragmentDoc}
`
export const HowItWorksFieldsFragmentDoc = gql`
  fragment HowItWorksFields on HowItWorksItemModelRecord {
    id
    title
    description
    collection
    howItWorksIcon: icon
    callToAction {
      ...CallToActionFields
    }
  }
  ${CallToActionFieldsFragmentDoc}
`
export const HowItWorksSectionFieldsFragmentDoc = gql`
  fragment HowItWorksSectionFields on HowItWorksSectionModelRecord {
    id
    plot
    title
    description
    actionButtonText
    items {
      ...HowItWorksFields
    }
    strengthList {
      ...HowItWorksFields
    }
    contactUsList {
      ...HowItWorksFields
    }
    activeTab
  }
  ${HowItWorksFieldsFragmentDoc}
`
export const IndustryItemFieldsFragmentDoc = gql`
  fragment IndustryItemFields on FieldHomePageIndustryItemModelRecord {
    title
    industryIcon: icon
    collection
    target {
      ... on CategoryLandingPageModelRecord {
        slug
      }
      ... on EmergencyLandingPageModelRecord {
        slug
      }
      ... on IndustryOverviewPageModelRecord {
        slug
      }
      ... on StaticPageModelRecord {
        slug
        path
      }
    }
  }
`
export const IndustrySectionFieldsFragmentDoc = gql`
  fragment IndustrySectionFields on IndustrySectionModelRecord {
    id
    plot
    title
    industryItems: items {
      ...IndustryItemFields
    }
  }
  ${IndustryItemFieldsFragmentDoc}
`
export const PartnerItemFieldsFragmentDoc = gql`
  fragment PartnerItemFields on PartnerItemModelRecord {
    title
    description
    logo {
      ...AssetFields
    }
    target {
      ... on ArticleModelRecord {
        slug
      }
      ... on CategoryLandingPageModelRecord {
        slug
      }
      ... on StaticPageModelRecord {
        path
      }
    }
  }
  ${AssetFieldsFragmentDoc}
`
export const PartnerSectionFieldsFragmentDoc = gql`
  fragment PartnerSectionFields on PartnerSectionModelRecord {
    id
    plot
    title
    items {
      ...PartnerItemFields
    }
  }
  ${PartnerItemFieldsFragmentDoc}
`
export const ServicesItemFieldsFragmentDoc = gql`
  fragment ServicesItemFields on ServiceItemModelRecord {
    id
    title
    callToAction {
      ...CallToActionFields
    }
    shortDescription
    chipText
    chipColour
    serviceIcon: icon {
      ...FontAwesomeFields
    }
  }
  ${CallToActionFieldsFragmentDoc}
  ${FontAwesomeFieldsFragmentDoc}
`
export const ServiceSectionFieldsFragmentDoc = gql`
  fragment ServiceSectionFields on ServicesSectionModelRecord {
    id
    plot
    title
    description
    items {
      ...ServicesItemFields
    }
  }
  ${ServicesItemFieldsFragmentDoc}
`
export const DynamicProDirectorySectionFieldsFragmentDoc = gql`
  fragment DynamicProDirectorySectionFields on FieldDynamicProDirectorySectionModelRecord {
    id
    plot
    title
    description
    industries
    offerings {
      id: idField
    }
    location
    distance
  }
`
export const EmergencyOfferingFieldsFragmentDoc = gql`
  fragment EmergencyOfferingFields on EmergencyOfferingModelRecord {
    title
    collection
    icon
  }
`
export const EmergencyOfferingSectionFieldsFragmentDoc = gql`
  fragment EmergencyOfferingSectionFields on EmergencyOfferingSectionModelRecord {
    plot
    title
    description
    offerings {
      ...EmergencyOfferingFields
    }
    actionButtonText
    formTitle
    formDescription
    typeInputLabel
  }
  ${EmergencyOfferingFieldsFragmentDoc}
`
export const EmergencyLandingPageFieldsFragmentDoc = gql`
  fragment EmergencyLandingPageFields on EmergencyLandingPageModelRecord {
    title
    description
    hint
    showPhoneNumber
    phoneNumber
    offering {
      idField
    }
    sections {
      __typename
      ... on HowItWorksSectionModelRecord {
        ...HowItWorksSectionFields
      }
      ... on IndustrySectionModelRecord {
        ...IndustrySectionFields
      }
      ... on InspirationSectionModelRecord {
        ...InspirationSectionFields
      }
      ... on PartnerSectionModelRecord {
        ...PartnerSectionFields
      }
      ... on PromotionBannerModelRecord {
        ...PromotionalBannerSectionFields
      }
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on ServicesSectionModelRecord {
        ...ServiceSectionFields
      }
      ... on SupportSectionModelRecord {
        ...SupportSectionFields
      }
      ... on KnowledgeSectionModelRecord {
        ...KnowledgeSectionFields
      }
      ... on PresentationSectionModelRecord {
        ...PresentationSectionFields
      }
      ... on FieldContentSectionModelRecord {
        ...ContentSectionFields
      }
      ... on FieldDynamicProDirectorySectionModelRecord {
        ...DynamicProDirectorySectionFields
      }
      ... on EmergencyOfferingSectionModelRecord {
        ...EmergencyOfferingSectionFields
      }
      ... on FieldSignupSectionRecord {
        ...SignupSectionFields
      }
    }
    seo {
      doNotIndexPage
      canonicalPage {
        ...CanonicalPageFields
      }
    }
    _locales
  }
  ${HowItWorksSectionFieldsFragmentDoc}
  ${IndustrySectionFieldsFragmentDoc}
  ${InspirationSectionFieldsFragmentDoc}
  ${PartnerSectionFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${ServiceSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${PresentationSectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${DynamicProDirectorySectionFieldsFragmentDoc}
  ${EmergencyOfferingSectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
  ${CanonicalPageFieldsFragmentDoc}
`
export const OfferingsSectionItemCollectionFragmentDoc = gql`
  fragment OfferingsSectionItemCollection on OfferingSectionModelModelItems2Field {
    ... on OfferingModelRecord {
      title
      offeringId
      icon {
        ...FontAwesomeFields
      }
    }
    ... on OfferingDetailPageModelRecord {
      description
      slug
      offering {
        title
        icon {
          ...FontAwesomeFields
        }
      }
    }
    ... on OfferingSectionItemModelRecord {
      title
      description
      icon {
        ...FontAwesomeFields
      }
      target {
        ... on OfferingModelRecord {
          offeringId
        }
        ... on OfferingDetailPageModelRecord {
          slug
        }
      }
    }
  }
  ${FontAwesomeFieldsFragmentDoc}
`
export const OfferingsSectionFieldsFragmentDoc = gql`
  fragment OfferingsSectionFields on OfferingSectionModelRecord {
    id
    title
    plot
    items2 {
      ...OfferingsSectionItemCollection
    }
  }
  ${OfferingsSectionItemCollectionFragmentDoc}
`
export const CategoryLandingPageFieldsFragmentDoc = gql`
  fragment CategoryLandingPageFields on CategoryLandingPageModelRecord {
    title
    description
    primaryCallToAction {
      ...CallToActionFields
    }
    secondaryCallToAction {
      ...CallToActionFields
    }
    bannerImage {
      ...AssetFields
    }
    showPartnershipSection
    partnershipImage {
      ...AssetFields
    }
    seo {
      ...PageMetaFields
    }
    sections {
      __typename
      ... on HowItWorksSectionModelRecord {
        ...HowItWorksSectionFields
      }
      ... on IndustrySectionModelRecord {
        ...IndustrySectionFields
      }
      ... on InspirationSectionModelRecord {
        ...InspirationSectionFields
      }
      ... on PartnerSectionModelRecord {
        ...PartnerSectionFields
      }
      ... on PromotionBannerModelRecord {
        ...PromotionalBannerSectionFields
      }
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on ServicesSectionModelRecord {
        ...ServiceSectionFields
      }
      ... on SupportSectionModelRecord {
        ...SupportSectionFields
      }
      ... on KnowledgeSectionModelRecord {
        ...KnowledgeSectionFields
      }
      ... on PresentationSectionModelRecord {
        ...PresentationSectionFields
      }
      ... on OfferingSectionModelRecord {
        ...OfferingsSectionFields
      }
      ... on FieldContentSectionModelRecord {
        ...ContentSectionFields
      }
      ... on FieldDynamicProDirectorySectionModelRecord {
        ...DynamicProDirectorySectionFields
      }
      ... on FieldSignupSectionRecord {
        ...SignupSectionFields
      }
    }
    _locales
  }
  ${CallToActionFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${PageMetaFieldsFragmentDoc}
  ${HowItWorksSectionFieldsFragmentDoc}
  ${IndustrySectionFieldsFragmentDoc}
  ${InspirationSectionFieldsFragmentDoc}
  ${PartnerSectionFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${ServiceSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${PresentationSectionFieldsFragmentDoc}
  ${OfferingsSectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
  ${DynamicProDirectorySectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const ContentSectionLinksFieldsFragmentDoc = gql`
  fragment ContentSectionLinksFields on FieldContentSectionModelModelContentLinksField {
    ... on ComponentBulletPointModelRecord {
      ...ComponentBulletPointFields
    }
    ... on ComponentButtonModelRecord {
      ...ComponentButtonFields
    }
    ... on VideoModelRecord {
      ...VideoFields
    }
    ... on PromotionBannerModelRecord {
      ...PromotionalBannerSectionFields
    }
    ... on ComponentAccordionModelRecord {
      ...ComponentAccordionFields
    }
  }
  ${ComponentBulletPointFieldsFragmentDoc}
  ${ComponentButtonFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ComponentAccordionFieldsFragmentDoc}
`
export const AuthorIdentityFieldsFragmentDoc = gql`
  fragment AuthorIdentityFields on AuthorModelRecord {
    name
    portrait {
      url
    }
  }
`
export const AuthorFieldsFragmentDoc = gql`
  fragment AuthorFields on AuthorModelRecord {
    ...AuthorIdentityFields
    description
    partnerLogo {
      ...AssetWithSizeFields
    }
    partnerLogoCta {
      ...CallToActionFields
    }
    cta {
      ...CallToActionFields
    }
  }
  ${AuthorIdentityFieldsFragmentDoc}
  ${AssetWithSizeFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
`
export const ContactFormFieldsFragmentDoc = gql`
  fragment ContactFormFields on FieldContactFormModelRecord {
    alternativeCallToActionTitle
    callToAction1 {
      ...CallToActionFields
    }
    callToAction2 {
      ...CallToActionFields
    }
  }
  ${CallToActionFieldsFragmentDoc}
`
export const IndustryRichTextLinksFieldsFragmentDoc = gql`
  fragment IndustryRichTextLinksFields on IndustryOverviewPageModelModelContentLinksField {
    ... on ComponentBulletPointModelRecord {
      ...ComponentBulletPointFields
    }
    ... on ComponentButtonModelRecord {
      ...ComponentButtonFields
    }
    ... on VideoModelRecord {
      ...VideoFields
    }
    ... on PromotionBannerModelRecord {
      ...PromotionalBannerSectionFields
    }
    ... on ComponentAccordionModelRecord {
      ...ComponentAccordionFields
    }
    ... on FieldSignupSectionRecord {
      ...SignupSectionFields
    }
  }
  ${ComponentBulletPointFieldsFragmentDoc}
  ${ComponentButtonFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ComponentAccordionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const IndustryOverviewPageFieldsFragmentDoc = gql`
  fragment IndustryOverviewPageFields on IndustryOverviewPageModelRecord {
    title
    breadcrumbTitle
    description
    bannerImage {
      ...AssetFields
    }
    offeringSection {
      ...OfferingsSectionFields
    }
    readingTime
    author {
      ...AuthorFields
    }
    mainCallToAction {
      ...CallToActionFields
    }
    secondaryCallToAction {
      ...CallToActionFields
    }
    inspirationSection {
      ...InspirationSectionFields
    }
    supportSection {
      ...SupportSectionFields
    }
    industrySectionPlot
    industrySectionTitle
    industryItems {
      ...IndustryItemFields
    }
    promotionalBannerSection {
      ...PromotionalBannerSectionFields
    }
    contactForm {
      ...ContactFormFields
    }
    promotionalSection {
      ...InspirationSectionFields
    }
    proDirectoryIndustries
    seo {
      changeFrequency
      doNotIndexPage
      sitemapPriority
      canonicalPage {
        ...CanonicalPageFields
      }
    }
    knowledgeSection {
      ...KnowledgeSectionFields
    }
    contentSectionPlot
    contentSectionTitle
    content {
      value
      blocks {
        ...StructuredTextAssetFields
        ...DataTableFields
      }
      links {
        ...IndustryRichTextLinksFields
      }
    }
    presentationSection {
      ...PresentationSectionFields
    }
    _locales
  }
  ${AssetFieldsFragmentDoc}
  ${OfferingsSectionFieldsFragmentDoc}
  ${AuthorFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
  ${InspirationSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${IndustryItemFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ContactFormFieldsFragmentDoc}
  ${CanonicalPageFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${StructuredTextAssetFieldsFragmentDoc}
  ${DataTableFieldsFragmentDoc}
  ${IndustryRichTextLinksFieldsFragmentDoc}
  ${PresentationSectionFieldsFragmentDoc}
`
export const ProDirectoryPageFieldsFragmentDoc = gql`
  fragment ProDirectoryPageFields on ProDirectoryPageModelRecord {
    content {
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on KnowledgeSectionModelRecord {
        ...KnowledgeSectionFields
      }
      ... on PromotionBannerModelRecord {
        ...PromotionalBannerSectionFields
      }
      ... on InspirationSectionModelRecord {
        ...InspirationSectionFields
      }
      ... on FieldSignupSectionRecord {
        ...SignupSectionFields
      }
    }
  }
  ${ReviewSectionFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${InspirationSectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const HomePageFieldsFragmentDoc = gql`
  fragment HomePageFields on HomePageModelRecord {
    content {
      ... on HowItWorksSectionModelRecord {
        ...HowItWorksSectionFields
      }
      ... on IndustrySectionModelRecord {
        ...IndustrySectionFields
      }
      ... on InspirationSectionModelRecord {
        ...InspirationSectionFields
      }
      ... on PartnerSectionModelRecord {
        ...PartnerSectionFields
      }
      ... on PromotionBannerModelRecord {
        ...PromotionalBannerSectionFields
      }
      ... on ReviewSectionModelRecord {
        ...ReviewSectionFields
      }
      ... on ServicesSectionModelRecord {
        ...ServiceSectionFields
      }
      ... on SupportSectionModelRecord {
        ...SupportSectionFields
      }
      ... on KnowledgeSectionModelRecord {
        ...KnowledgeSectionFields
      }
      ... on PresentationSectionModelRecord {
        ...PresentationSectionFields
      }
      ... on FieldContentSectionModelRecord {
        ...ContentSectionFields
      }
    }
  }
  ${HowItWorksSectionFieldsFragmentDoc}
  ${IndustrySectionFieldsFragmentDoc}
  ${InspirationSectionFieldsFragmentDoc}
  ${PartnerSectionFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${ServiceSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
  ${PresentationSectionFieldsFragmentDoc}
  ${ContentSectionFieldsFragmentDoc}
`
export const CmsRegionWithExcludedIndustriesFieldsFragmentDoc = gql`
  fragment CmsRegionWithExcludedIndustriesFields on RegionRecord {
    ...CmsRegionFields
    excludedIndustries {
      id: idField
    }
  }
  ${CmsRegionFieldsFragmentDoc}
`
export const OfferingFieldsFragmentDoc = gql`
  fragment OfferingFields on OfferingModelRecord {
    offeringId
    title
    icon {
      collection
      name
    }
  }
`
export const OfferingRichTextLinksFieldsFragmentDoc = gql`
  fragment OfferingRichTextLinksFields on OfferingDetailPageModelModelContentLinksField {
    ... on ComponentBulletPointModelRecord {
      ...ComponentBulletPointFields
    }
    ... on ComponentButtonModelRecord {
      ...ComponentButtonFields
    }
    ... on VideoModelRecord {
      ...VideoFields
    }
    ... on PromotionBannerModelRecord {
      ...PromotionalBannerSectionFields
    }
    ... on ComponentAccordionModelRecord {
      ...ComponentAccordionFields
    }
    ... on FieldSignupSectionRecord {
      ...SignupSectionFields
    }
  }
  ${ComponentBulletPointFieldsFragmentDoc}
  ${ComponentButtonFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ComponentAccordionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const OfferingDetailPageFieldsFragmentDoc = gql`
  fragment OfferingDetailPageFields on OfferingDetailPageModelRecord {
    title
    breadcrumbTitle
    offering {
      ...OfferingFields
    }
    parentField {
      ... on IndustryOverviewPageModelRecord {
        slug
        title
        breadcrumbTitle
      }
      ... on StaticPageModelRecord {
        slug
        path
        title
        breadcrumbTitle
      }
    }
    mainCallToAction {
      ...CallToActionFields
    }
    secondaryCallToAction {
      ...CallToActionFields
    }
    description
    bannerImage {
      ...AssetFields
    }
    howItWorksSection {
      ...HowItWorksSectionFields
    }
    relatedOfferingsSection {
      ...OfferingsSectionFields
    }
    inspirationSection {
      ...InspirationSectionFields
    }
    seo {
      title
      doNotIndexPage
      canonicalPage {
        ...CanonicalPageFields
      }
    }
    promotionalBannerSection {
      ...PromotionalBannerSectionFields
    }
    supportSection {
      ...SupportSectionFields
    }
    contactForm {
      alternativeCallToActionTitle
      callToAction1 {
        ...CallToActionFields
      }
      callToAction2 {
        ...CallToActionFields
      }
    }
    readingTime
    author {
      ...AuthorFields
    }
    contentTitle
    contentPlot
    content {
      value
      blocks {
        ...StructuredTextAssetFields
        ...DataTableFields
      }
      links {
        ...OfferingRichTextLinksFields
      }
    }
    presentationSection {
      ...PresentationSectionFields
    }
    _locales
  }
  ${OfferingFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${HowItWorksSectionFieldsFragmentDoc}
  ${OfferingsSectionFieldsFragmentDoc}
  ${InspirationSectionFieldsFragmentDoc}
  ${CanonicalPageFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${AuthorFieldsFragmentDoc}
  ${StructuredTextAssetFieldsFragmentDoc}
  ${DataTableFieldsFragmentDoc}
  ${OfferingRichTextLinksFieldsFragmentDoc}
  ${PresentationSectionFieldsFragmentDoc}
`
export const ServiceOverviewPageFieldsFragmentDoc = gql`
  fragment ServiceOverviewPageFields on ServiceOverviewPageModelRecord {
    plot
    title
    description
    servicesSection {
      ...ServiceSectionFields
    }
    supportSection {
      ...SupportSectionFields
    }
    partnerSection {
      ...PartnerSectionFields
    }
    seo {
      changeFrequency
      doNotIndexPage
      sitemapPriority
      canonicalPage {
        ...CanonicalPageFields
      }
    }
    _locales
  }
  ${ServiceSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${PartnerSectionFieldsFragmentDoc}
  ${CanonicalPageFieldsFragmentDoc}
`
export const StaticPageRichTextLinksFieldsFragmentDoc = gql`
  fragment StaticPageRichTextLinksFields on StaticPageModelModelContentLinksField {
    ... on ComponentBulletPointModelRecord {
      ...ComponentBulletPointFields
    }
    ... on ComponentButtonModelRecord {
      ...ComponentButtonFields
    }
    ... on PromotionBannerModelRecord {
      ...PromotionalBannerSectionFields
    }
    ... on FieldSignupSectionRecord {
      ...SignupSectionFields
    }
  }
  ${ComponentBulletPointFieldsFragmentDoc}
  ${ComponentButtonFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const StaticPageFieldsFragmentDoc = gql`
  fragment StaticPageFields on StaticPageModelRecord {
    serviceSection {
      ...ServiceSectionFields
    }
    supportSection {
      ...SupportSectionFields
    }
    offeringSection {
      ...OfferingsSectionFields
    }
    partnerSection {
      ...PartnerSectionFields
    }
    reviewSection {
      ...ReviewSectionFields
    }
    content {
      value
      blocks {
        ...StructuredTextAssetFields
        ...DataTableFields
      }
      links {
        ...StaticPageRichTextLinksFields
      }
    }
    promotionalBannerSection {
      ...PromotionalBannerSectionFields
    }
  }
  ${ServiceSectionFieldsFragmentDoc}
  ${SupportSectionFieldsFragmentDoc}
  ${OfferingsSectionFieldsFragmentDoc}
  ${PartnerSectionFieldsFragmentDoc}
  ${ReviewSectionFieldsFragmentDoc}
  ${StructuredTextAssetFieldsFragmentDoc}
  ${DataTableFieldsFragmentDoc}
  ${StaticPageRichTextLinksFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
`
export const StructuredTextPlotFieldsFragmentDoc = gql`
  fragment StructuredTextPlotFields on StructuredTextPlotRecord {
    __typename
    id
    title
  }
`
export const ArticleRichTextLinksFieldsFragmentDoc = gql`
  fragment ArticleRichTextLinksFields on ArticleModelModelContentLinksField {
    ... on ComponentBulletPointModelRecord {
      ...ComponentBulletPointFields
    }
    ... on ComponentButtonModelRecord {
      ...ComponentButtonFields
    }
    ... on VideoModelRecord {
      ...VideoFields
    }
    ... on PromotionBannerModelRecord {
      ...PromotionalBannerSectionFields
    }
    ... on ComponentAccordionModelRecord {
      ...ComponentAccordionFields
    }
    ... on FieldSignupSectionRecord {
      ...SignupSectionFields
    }
  }
  ${ComponentBulletPointFieldsFragmentDoc}
  ${ComponentButtonFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
  ${ComponentAccordionFieldsFragmentDoc}
  ${SignupSectionFieldsFragmentDoc}
`
export const ArticlePageFieldsFragmentDoc = gql`
  fragment ArticlePageFields on ArticleModelRecord {
    __typename
    id
    _publishedAt
    _locales
    release
    slug
    parentField {
      slug
      title
      breadcrumbTitle
    }
    title
    breadcrumbTitle
    preview
    headerImage {
      url
      title
    }
    readingTime
    content {
      value
      blocks {
        ...StructuredTextAssetFields
        ...DataTableFields
        ...StructuredTextPlotFields
      }
      links {
        ...ArticleRichTextLinksFields
      }
    }
    author {
      ...AuthorFields
    }
    topic {
      _locales
      slug
      title
      breadcrumbTitle
    }
    mainCallToAction {
      ...CallToActionFields
    }
    secondaryCallToAction {
      ...CallToActionFields
    }
    seo {
      ...PageMetaFields
    }
  }
  ${StructuredTextAssetFieldsFragmentDoc}
  ${DataTableFieldsFragmentDoc}
  ${StructuredTextPlotFieldsFragmentDoc}
  ${ArticleRichTextLinksFieldsFragmentDoc}
  ${AuthorFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
  ${PageMetaFieldsFragmentDoc}
`
export const ArticleCtaFieldsFragmentDoc = gql`
  fragment ArticleCtaFields on ArticleCtaCardModelRecord {
    buttonText
    title
    typeField
    options {
      text
      path
    }
  }
`
export const ContactFormCardFieldsFragmentDoc = gql`
  fragment ContactFormCardFields on FieldContactFormModelRecord {
    alternativeCallToActionTitle
    callToAction1 {
      ...CallToActionFields
    }
    callToAction2 {
      ...CallToActionFields
    }
  }
  ${CallToActionFieldsFragmentDoc}
`
export const TopicOverviewPageFieldsFragmentDoc = gql`
  fragment TopicOverviewPageFields on TopicOverviewPageModelRecord {
    title
    slug
    breadcrumbTitle
    description
    _locales
  }
`
export const TopicArticleFieldsFragmentDoc = gql`
  fragment TopicArticleFields on ArticleModelRecord {
    title
    slug
  }
`
export const TopicListItemFieldsFragmentDoc = gql`
  fragment TopicListItemFields on TopicOverviewPageModelRecord {
    id
    slug
    title
    breadcrumbTitle
  }
`
export const ComponentDropdownMenuItemFieldsFragmentDoc = gql`
  fragment ComponentDropdownMenuItemFields on ComponentDropdownMenuItemModelRecord {
    icon {
      ...FontAwesomeFields
    }
    title
    link {
      ...CallToActionFields
    }
    childrenField {
      icon {
        ...FontAwesomeFields
      }
      title
      link {
        ...CallToActionFields
      }
    }
  }
  ${FontAwesomeFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
`
export const ComponentDropdownMenuFieldsFragmentDoc = gql`
  fragment ComponentDropdownMenuFields on ComponentDropdownMenuModelRecord {
    slug
    title
    icon {
      ...FontAwesomeFields
    }
    childrenField {
      ...ComponentDropdownMenuItemFields
    }
  }
  ${FontAwesomeFieldsFragmentDoc}
  ${ComponentDropdownMenuItemFieldsFragmentDoc}
`
export const AnnouncementBannerFieldsFragmentDoc = gql`
  fragment AnnouncementBannerFields on AnnouncementBannerV2ModelRecord {
    id
    location
    textMessage
    linkText
    link
  }
`
export const IndustryOverviewSummaryFieldsFragmentDoc = gql`
  fragment IndustryOverviewSummaryFields on IndustryOverviewPageModelRecord {
    slug
    offeringSection {
      ...OfferingsSectionFields
    }
  }
  ${OfferingsSectionFieldsFragmentDoc}
`
export const IndustryItemSummaryFieldsFragmentDoc = gql`
  fragment IndustryItemSummaryFields on FieldHomePageIndustryItemModelRecord {
    title
    icon
    collection
    target {
      ...IndustryOverviewSummaryFields
    }
  }
  ${IndustryOverviewSummaryFieldsFragmentDoc}
`
export const ComponentTabElementFieldsFragmentDoc = gql`
  fragment ComponentTabElementFields on ComponentTabElementModelRecord {
    title
    icon {
      collection
      name
    }
    image {
      ...AssetWithSizeFields
    }
    headline
    text
    callToAction {
      ...CallToActionFields
    }
    callToAction2 {
      ...CallToActionFields
    }
  }
  ${AssetWithSizeFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
`
export const ComponentTabFieldsFragmentDoc = gql`
  fragment ComponentTabFields on ComponentTabModelRecord {
    slug
    elements {
      ...ComponentTabElementFields
    }
  }
  ${ComponentTabElementFieldsFragmentDoc}
`
export const ProRegisterPlanPageFieldsFragmentDoc = gql`
  fragment ProRegisterPlanPageFields on ProRegisterPlanPageModelRecord {
    slug
    title
    headerImage {
      ...AssetFields
    }
    description
    sellingPoints {
      ...ComponentTabFields
    }
    faq {
      ...ComponentAccordionFields
    }
    promotionalBanner {
      ...PromotionalBannerSectionFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${ComponentTabFieldsFragmentDoc}
  ${ComponentAccordionFieldsFragmentDoc}
  ${PromotionalBannerSectionFieldsFragmentDoc}
`
export const ArticleAuthorFieldsFragmentDoc = gql`
  fragment ArticleAuthorFields on AuthorModelRecord {
    name
  }
`
export const IndustryOverviewPageSideNavFieldsFragmentDoc = gql`
  fragment IndustryOverviewPageSideNavFields on IndustryOverviewPageModelRecord {
    slug
    title
    breadcrumbTitle
    proDirectoryIndustries
  }
`
export const GetAnnouncementBannersByLocationDocument = gql`
  query GetAnnouncementBannersByLocation($locale: SiteLocale!, $location: String!) {
    announcementBannerV2Model(filter: {location: {eq: $location}}, locale: $locale, fallbackLocales: [de]) {
      ...AnnouncementBannerFields
    }
  }
  ${AnnouncementBannerFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetAnnouncementBannersByLocationGql extends Apollo.Query<
  GetAnnouncementBannersByLocationQuery,
  GetAnnouncementBannersByLocationQueryVariables
> {
  document = GetAnnouncementBannersByLocationDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetIndustryItemsDocument = gql`
  query GetIndustryItems($locale: SiteLocale!) {
    allFieldHomePageIndustryItemModels(
      filter: {target: {exists: true}}
      first: 30
      locale: $locale
      fallbackLocales: [de]
    ) {
      ...IndustryItemSummaryFields
    }
  }
  ${IndustryItemSummaryFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetIndustryItemsGql extends Apollo.Query<GetIndustryItemsQuery, GetIndustryItemsQueryVariables> {
  document = GetIndustryItemsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetRegisterProPageDocument = gql`
  query GetRegisterProPage($slug: String!, $locale: SiteLocale!) {
    proRegisterPlanPageModel(filter: {slug: {eq: $slug}}, locale: $locale, fallbackLocales: [de]) {
      ...ProRegisterPlanPageFields
    }
  }
  ${ProRegisterPlanPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetRegisterProPageGql extends Apollo.Query<GetRegisterProPageQuery, GetRegisterProPageQueryVariables> {
  document = GetRegisterProPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetTopicOverviewPageDocument = gql`
  query GetTopicOverviewPage($pageSlug: String!, $locale: SiteLocale!, $moreTopicCount: IntType!) {
    topicOverviewPageModel(filter: {slug: {eq: $pageSlug}, _locales: {allIn: [$locale]}}, locale: $locale) {
      ...TopicOverviewPageFields
    }
    moreTopics: allTopicOverviewPageModels(
      filter: {showInOverview: {eq: true}, _locales: {allIn: [$locale]}}
      first: $moreTopicCount
      locale: $locale
      orderBy: [rank_ASC]
    ) {
      ...TopicListItemFields
    }
  }
  ${TopicOverviewPageFieldsFragmentDoc}
  ${TopicListItemFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetTopicOverviewPageGql extends Apollo.Query<
  GetTopicOverviewPageQuery,
  GetTopicOverviewPageQueryVariables
> {
  document = GetTopicOverviewPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetArticlePageDocument = gql`
  query GetArticlePage($pageSlug: String!, $locale: SiteLocale!) {
    articleModel(filter: {slug: {eq: $pageSlug}}, locale: $locale) {
      ...ArticlePageFields
    }
  }
  ${ArticlePageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetArticlePageGql extends Apollo.Query<GetArticlePageQuery, GetArticlePageQueryVariables> {
  document = GetArticlePageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetArticlePageAvailableLocalesDocument = gql`
  query GetArticlePageAvailableLocales($pageSlug: String!) {
    articleModel(filter: {slug: {eq: $pageSlug}}) {
      _locales
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetArticlePageAvailableLocalesGql extends Apollo.Query<
  GetArticlePageAvailableLocalesQuery,
  GetArticlePageAvailableLocalesQueryVariables
> {
  document = GetArticlePageAvailableLocalesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetTopicListDocument = gql`
  query GetTopicList($locale: SiteLocale!, $count: IntType!) {
    allTopicOverviewPageModels(
      filter: {showInOverview: {eq: true}, _locales: {allIn: [$locale]}}
      first: $count
      locale: $locale
      orderBy: [rank_ASC]
    ) {
      ...TopicListItemFields
    }
  }
  ${TopicListItemFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetTopicListGql extends Apollo.Query<GetTopicListQuery, GetTopicListQueryVariables> {
  document = GetTopicListDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetStaticPageDocument = gql`
  query GetStaticPage($pageSlug: String!, $locale: SiteLocale!) {
    staticPageModel(filter: {slug: {eq: $pageSlug}}, locale: $locale, fallbackLocales: [de]) {
      ...StaticPageFields
    }
  }
  ${StaticPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetStaticPageGql extends Apollo.Query<GetStaticPageQuery, GetStaticPageQueryVariables> {
  document = GetStaticPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetIndustryOverviewPageSlugsDocument = gql`
  query GetIndustryOverviewPageSlugs($locale: SiteLocale!) {
    allIndustryOverviewPageModels(filter: {proDirectoryIndustries: {exists: true}}, locale: $locale, first: 40) {
      slug
      proDirectoryIndustries
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetIndustryOverviewPageSlugsGql extends Apollo.Query<
  GetIndustryOverviewPageSlugsQuery,
  GetIndustryOverviewPageSlugsQueryVariables
> {
  document = GetIndustryOverviewPageSlugsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetServiceOverviewPageDocument = gql`
  query GetServiceOverviewPage($pageSlug: String!, $locale: SiteLocale!) {
    serviceOverviewPageModel(filter: {slug: {eq: $pageSlug}}, locale: $locale, fallbackLocales: [de]) {
      ...ServiceOverviewPageFields
    }
  }
  ${ServiceOverviewPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetServiceOverviewPageGql extends Apollo.Query<
  GetServiceOverviewPageQuery,
  GetServiceOverviewPageQueryVariables
> {
  document = GetServiceOverviewPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCategoryLandingPageDocument = gql`
  query GetCategoryLandingPage($locale: SiteLocale!, $pageSlug: String!) {
    categoryLandingPageModel(filter: {slug: {eq: $pageSlug}}, locale: $locale, fallbackLocales: [de]) {
      ...CategoryLandingPageFields
    }
  }
  ${CategoryLandingPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCategoryLandingPageGql extends Apollo.Query<
  GetCategoryLandingPageQuery,
  GetCategoryLandingPageQueryVariables
> {
  document = GetCategoryLandingPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetEmergencyLandingPageDocument = gql`
  query GetEmergencyLandingPage($locale: SiteLocale!, $pageSlug: String!) {
    emergencyLandingPageModel(filter: {slug: {eq: $pageSlug}}, locale: $locale, fallbackLocales: [de]) {
      ...EmergencyLandingPageFields
    }
  }
  ${EmergencyLandingPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetEmergencyLandingPageGql extends Apollo.Query<
  GetEmergencyLandingPageQuery,
  GetEmergencyLandingPageQueryVariables
> {
  document = GetEmergencyLandingPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetOfferingByIdForPageDocument = gql`
  query GetOfferingByIdForPage($locale: SiteLocale!, $offeringId: IntType!) {
    offering: offeringDataModel(filter: {idField: {eq: $offeringId}}, locale: $locale) {
      ...OfferingDataFields
    }
  }
  ${OfferingDataFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetOfferingByIdForPageGql extends Apollo.Query<
  GetOfferingByIdForPageQuery,
  GetOfferingByIdForPageQueryVariables
> {
  document = GetOfferingByIdForPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetOfferingBySlugForPageDocument = gql`
  query GetOfferingBySlugForPage($locale: SiteLocale!, $slug: String!) {
    offering: offeringDataModel(filter: {slug: {eq: $slug}}, locale: $locale) {
      ...OfferingDataFields
    }
  }
  ${OfferingDataFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetOfferingBySlugForPageGql extends Apollo.Query<
  GetOfferingBySlugForPageQuery,
  GetOfferingBySlugForPageQueryVariables
> {
  document = GetOfferingBySlugForPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetOfferingPageDocument = gql`
  query GetOfferingPage($locale: SiteLocale!, $offeringCmsId: ItemId!) {
    page: offeringPageModel(
      filter: {AND: [{typeField: {eq: "Override"}}, {offering: {eq: $offeringCmsId}}]}
      locale: $locale
    ) {
      ...OfferingPageFields
    }
    defaultPage: offeringPageModel(
      filter: {AND: [{typeField: {eq: "Default"}}, {offering: {exists: false}}]}
      locale: $locale
    ) {
      ...OfferingPageFields
    }
    offering: offeringDataModel(filter: {id: {eq: $offeringCmsId}}, locale: $locale) {
      knowledgeSection {
        ...KnowledgeSectionFields
      }
    }
  }
  ${OfferingPageFieldsFragmentDoc}
  ${KnowledgeSectionFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetOfferingPageGql extends Apollo.Query<GetOfferingPageQuery, GetOfferingPageQueryVariables> {
  document = GetOfferingPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetIndustryOverviewPageDocument = gql`
  query GetIndustryOverviewPage($pageSlug: String!, $locale: SiteLocale!) {
    industryOverviewPageModel(filter: {slug: {eq: $pageSlug}}, locale: $locale, fallbackLocales: [de]) {
      ...IndustryOverviewPageFields
    }
  }
  ${IndustryOverviewPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetIndustryOverviewPageGql extends Apollo.Query<
  GetIndustryOverviewPageQuery,
  GetIndustryOverviewPageQueryVariables
> {
  document = GetIndustryOverviewPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetProDirectoryPageDocument = gql`
  query GetProDirectoryPage($locale: SiteLocale!, $type: String!) {
    proDirectoryPageModel(filter: {typeField: {eq: $type}}, locale: $locale, fallbackLocales: [de]) {
      ...ProDirectoryPageFields
    }
  }
  ${ProDirectoryPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetProDirectoryPageGql extends Apollo.Query<GetProDirectoryPageQuery, GetProDirectoryPageQueryVariables> {
  document = GetProDirectoryPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetHomePageDocument = gql`
  query GetHomePage($locale: SiteLocale!, $type: String!) {
    homePageModel(filter: {typeField: {eq: $type}}, locale: $locale, fallbackLocales: [de]) {
      ...HomePageFields
    }
  }
  ${HomePageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetHomePageGql extends Apollo.Query<GetHomePageQuery, GetHomePageQueryVariables> {
  document = GetHomePageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetOfferingDetailPageDocument = gql`
  query GetOfferingDetailPage($pageSlug: String!, $locale: SiteLocale!) {
    offeringDetailPageModel(filter: {slug: {eq: $pageSlug}}, locale: $locale, fallbackLocales: [de]) {
      ...OfferingDetailPageFields
    }
  }
  ${OfferingDetailPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetOfferingDetailPageGql extends Apollo.Query<
  GetOfferingDetailPageQuery,
  GetOfferingDetailPageQueryVariables
> {
  document = GetOfferingDetailPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCraftsmenRegionDocument = gql`
  query GetCraftsmenRegion($regionSlug: String!, $locale: SiteLocale!) {
    region: region(filter: {slug: {eq: $regionSlug}}, locale: $locale) {
      ...CmsRegionFields
    }
  }
  ${CmsRegionFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCraftsmenRegionGql extends Apollo.Query<GetCraftsmenRegionQuery, GetCraftsmenRegionQueryVariables> {
  document = GetCraftsmenRegionDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCraftsmenIndustryDocument = gql`
  query GetCraftsmenIndustry($industrySlug: String!, $locale: SiteLocale!) {
    industry: industryModel(filter: {slug: {eq: $industrySlug}}, locale: $locale) {
      ...RegionalIndustryFields
    }
  }
  ${RegionalIndustryFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCraftsmenIndustryGql extends Apollo.Query<
  GetCraftsmenIndustryQuery,
  GetCraftsmenIndustryQueryVariables
> {
  document = GetCraftsmenIndustryDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCraftsmenRegionPageDocument = gql`
  query GetCraftsmenRegionPage($regionId: ItemId!, $locale: SiteLocale!) {
    page: regionCraftsmenPage(locale: $locale, filter: {AND: [{isDefault: {eq: false}}, {region: {eq: $regionId}}]}) {
      ...CraftsmenRegionPageFields
    }
    defaultPage: regionCraftsmenPage(locale: $locale, filter: {isDefault: {eq: true}}) {
      ...CraftsmenRegionPageFields
    }
  }
  ${CraftsmenRegionPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCraftsmenRegionPageGql extends Apollo.Query<
  GetCraftsmenRegionPageQuery,
  GetCraftsmenRegionPageQueryVariables
> {
  document = GetCraftsmenRegionPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetIndustryRegionPageDocument = gql`
  query GetIndustryRegionPage($regionId: ItemId, $industryId: ItemId!, $locale: SiteLocale!) {
    page: industryRegionPage(
      locale: $locale
      filter: {AND: [{isDefault: {eq: false}}, {region: {eq: $regionId}}, {industry: {eq: $industryId}}]}
    ) {
      ...IndustryRegionPageFields
    }
    defaultPage: industryRegionPage(locale: $locale, filter: {isDefault: {eq: true}}) {
      ...IndustryRegionPageFields
    }
  }
  ${IndustryRegionPageFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetIndustryRegionPageGql extends Apollo.Query<
  GetIndustryRegionPageQuery,
  GetIndustryRegionPageQueryVariables
> {
  document = GetIndustryRegionPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCraftsmenRegionDirectoryPageDocument = gql`
  query GetCraftsmenRegionDirectoryPage($locale: SiteLocale!, $regionPageSize: IntType!) {
    page: craftsmenRegionDirectoryPage(locale: $locale) {
      ...CraftsmenRegionDirectoryPageFields
    }
    regions: allRegions(locale: $locale, first: $regionPageSize, orderBy: [clientFacingLabel_ASC]) {
      ...CmsRegionWithExcludedIndustriesFields
    }
    regionsMeta: _allRegionsMeta(locale: $locale) {
      count
    }
    industries: allIndustryModels(locale: $locale, first: 50, orderBy: [label_ASC]) {
      ...RegionalIndustryFields
    }
    sideNavIndustries: allIndustryOverviewPageModels(
      locale: $locale
      first: 50
      orderBy: [breadcrumbTitle_ASC, slug_ASC]
    ) {
      ...IndustryOverviewPageSideNavFields
    }
  }
  ${CraftsmenRegionDirectoryPageFieldsFragmentDoc}
  ${CmsRegionWithExcludedIndustriesFieldsFragmentDoc}
  ${RegionalIndustryFieldsFragmentDoc}
  ${IndustryOverviewPageSideNavFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCraftsmenRegionDirectoryPageGql extends Apollo.Query<
  GetCraftsmenRegionDirectoryPageQuery,
  GetCraftsmenRegionDirectoryPageQueryVariables
> {
  document = GetCraftsmenRegionDirectoryPageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCraftsmenRegionDirectoryRegionsDocument = gql`
  query GetCraftsmenRegionDirectoryRegions($locale: SiteLocale!, $pageSize: IntType!, $offset: IntType!) {
    regions: allRegions(locale: $locale, first: $pageSize, skip: $offset, orderBy: [clientFacingLabel_ASC]) {
      ...CmsRegionWithExcludedIndustriesFields
    }
    regionsMeta: _allRegionsMeta(locale: $locale) {
      count
    }
  }
  ${CmsRegionWithExcludedIndustriesFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCraftsmenRegionDirectoryRegionsGql extends Apollo.Query<
  GetCraftsmenRegionDirectoryRegionsQuery,
  GetCraftsmenRegionDirectoryRegionsQueryVariables
> {
  document = GetCraftsmenRegionDirectoryRegionsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetArticleAuthorNamesDocument = gql`
  query GetArticleAuthorNames {
    allAuthorModels(filter: {_status: {in: [published, updated]}}) {
      ...ArticleAuthorFields
    }
  }
  ${ArticleAuthorFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetArticleAuthorNamesGql extends Apollo.Query<
  GetArticleAuthorNamesQuery,
  GetArticleAuthorNamesQueryVariables
> {
  document = GetArticleAuthorNamesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetAllRegionsDocument = gql`
  query GetAllRegions($locale: SiteLocale!) {
    allRegions(locale: $locale) {
      ...CmsRegionFields
    }
  }
  ${CmsRegionFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetAllRegionsGql extends Apollo.Query<GetAllRegionsQuery, GetAllRegionsQueryVariables> {
  document = GetAllRegionsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}

export interface PossibleTypesResultData {
  possibleTypes: Record<string, string[]>
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ArticleModelModelContentBlocksField: ['StructuredTextAssetRecord', 'StructuredTextPlotRecord', 'TableRecord'],
    ArticleModelModelContentLinksField: [
      'ComponentAccordionModelRecord',
      'ComponentBulletPointModelRecord',
      'ComponentButtonModelRecord',
      'FieldSignupSectionRecord',
      'PromotionBannerModelRecord',
      'VideoModelRecord',
    ],
    CategoryLandingPageModelModelSectionsField: [
      'FieldContentSectionModelRecord',
      'FieldDynamicProDirectorySectionModelRecord',
      'FieldSignupSectionRecord',
      'HowItWorksSectionModelRecord',
      'IndustrySectionModelRecord',
      'InspirationSectionModelRecord',
      'KnowledgeSectionModelRecord',
      'OfferingSectionModelRecord',
      'PartnerSectionModelRecord',
      'PresentationSectionModelRecord',
      'PromotionBannerModelRecord',
      'ReviewSectionModelRecord',
      'ServicesSectionModelRecord',
      'SupportSectionModelRecord',
    ],
    ComponentAccordionElementModelModelContentLinksField: [
      'ComponentBulletPointModelRecord',
      'ComponentButtonModelRecord',
      'PromotionBannerModelRecord',
      'VideoModelRecord',
    ],
    CraftsmenRegionDirectoryPageModelContentField: [
      'FieldContentSectionModelRecord',
      'FieldSignupSectionRecord',
      'InspirationSectionModelRecord',
      'KnowledgeSectionModelRecord',
      'PromotionBannerModelRecord',
      'ReviewSectionModelRecord',
    ],
    EmergencyLandingPageModelModelSectionsField: [
      'EmergencyOfferingSectionModelRecord',
      'FieldContentSectionModelRecord',
      'FieldDynamicProDirectorySectionModelRecord',
      'FieldSignupSectionRecord',
      'HowItWorksSectionModelRecord',
      'IndustrySectionModelRecord',
      'InspirationSectionModelRecord',
      'KnowledgeSectionModelRecord',
      'OfferingSectionModelRecord',
      'PartnerSectionModelRecord',
      'PresentationSectionModelRecord',
      'PromotionBannerModelRecord',
      'ReviewSectionModelRecord',
      'ServicesSectionModelRecord',
      'SupportSectionModelRecord',
    ],
    FieldCallToActionModelModelAnchorField: [
      'FieldContentSectionModelRecord',
      'FieldDynamicProDirectorySectionModelRecord',
      'FieldProMapSectionRecord',
      'FieldSignupSectionRecord',
      'HowItWorksSectionModelRecord',
      'IndustrySectionModelRecord',
      'InspirationSectionModelRecord',
      'KnowledgeSectionModelRecord',
      'OfferingSectionModelRecord',
      'PartnerSectionModelRecord',
      'PresentationSectionModelRecord',
      'PromotionBannerModelRecord',
      'ReviewSectionModelRecord',
      'ServicesSectionModelRecord',
      'SupportSectionModelRecord',
    ],
    FieldContentSectionModelModelContentBlocksField: ['StructuredTextAssetRecord', 'TableRecord'],
    FieldContentSectionModelModelContentLinksField: [
      'ComponentAccordionModelRecord',
      'ComponentBulletPointModelRecord',
      'ComponentButtonModelRecord',
      'FieldSignupSectionRecord',
      'PromotionBannerModelRecord',
      'VideoModelRecord',
    ],
    FieldHomePageIndustryItemModelModelTargetField: [
      'CategoryLandingPageModelRecord',
      'EmergencyLandingPageModelRecord',
      'IndustryOverviewPageModelRecord',
      'StaticPageModelRecord',
    ],
    FileFieldInterface: ['FileField'],
    HomePageModelModelContentField: [
      'FieldContentSectionModelRecord',
      'HowItWorksSectionModelRecord',
      'IndustrySectionModelRecord',
      'InspirationSectionModelRecord',
      'KnowledgeSectionModelRecord',
      'PartnerSectionModelRecord',
      'PresentationSectionModelRecord',
      'PromotionBannerModelRecord',
      'ReviewSectionModelRecord',
      'ServicesSectionModelRecord',
      'SupportSectionModelRecord',
    ],
    IndustryOverviewPageModelModelContentBlocksField: ['StructuredTextAssetRecord', 'TableRecord'],
    IndustryOverviewPageModelModelContentLinksField: [
      'ComponentAccordionModelRecord',
      'ComponentBulletPointModelRecord',
      'ComponentButtonModelRecord',
      'FieldSignupSectionRecord',
      'PromotionBannerModelRecord',
      'VideoModelRecord',
    ],
    IndustryRegionPageModelContentField: [
      'FieldContentSectionModelRecord',
      'FieldProMapSectionRecord',
      'FieldRegionPageProDirectoryRecord',
      'FieldSignupSectionRecord',
      'PresentationSectionModelRecord',
      'ReviewSectionModelRecord',
      'SupportSectionModelRecord',
    ],
    OfferingDetailPageModelModelContentBlocksField: ['StructuredTextAssetRecord', 'TableRecord'],
    OfferingDetailPageModelModelContentLinksField: [
      'ComponentAccordionModelRecord',
      'ComponentBulletPointModelRecord',
      'ComponentButtonModelRecord',
      'FieldSignupSectionRecord',
      'PromotionBannerModelRecord',
      'VideoModelRecord',
    ],
    OfferingDetailPageModelModelParentFieldField: ['IndustryOverviewPageModelRecord', 'StaticPageModelRecord'],
    OfferingPageModelModelContentField: [
      'FieldContentSectionModelRecord',
      'FieldProDirectorySectionModelRecord',
      'FieldSignupSectionRecord',
      'KnowledgeSectionModelRecord',
      'PresentationSectionModelRecord',
      'ReviewSectionModelRecord',
      'SupportSectionModelRecord',
    ],
    OfferingSectionItemModelModelTargetField: ['OfferingDetailPageModelRecord', 'OfferingModelRecord'],
    OfferingSectionModelModelItems2Field: [
      'OfferingDetailPageModelRecord',
      'OfferingModelRecord',
      'OfferingSectionItemModelRecord',
    ],
    PartnerItemModelModelTargetField: ['ArticleModelRecord', 'CategoryLandingPageModelRecord', 'StaticPageModelRecord'],
    ProDirectoryPageModelModelContentField: [
      'FieldSignupSectionRecord',
      'InspirationSectionModelRecord',
      'KnowledgeSectionModelRecord',
      'PromotionBannerModelRecord',
      'ReviewSectionModelRecord',
    ],
    RecordInterface: [
      'AnnouncementBannerV2ModelRecord',
      'AnswerModelRecord',
      'ArticleCtaCardItemModelRecord',
      'ArticleCtaCardModelRecord',
      'ArticleModelRecord',
      'AuthorModelRecord',
      'AutomaticArticleSectionModelRecord',
      'CalculatorModelRecord',
      'CategoryLandingPageModelRecord',
      'ClientReviewModelRecord',
      'ComponentAccordionElementModelRecord',
      'ComponentAccordionModelRecord',
      'ComponentBulletPointModelRecord',
      'ComponentButtonModelRecord',
      'ComponentDropdownMenuItemModelRecord',
      'ComponentDropdownMenuModelRecord',
      'ComponentTabElementModelRecord',
      'ComponentTabModelRecord',
      'CraftsmenRegionDirectoryPageRecord',
      'EmergencyLandingPageModelRecord',
      'EmergencyOfferingModelRecord',
      'EmergencyOfferingSectionModelRecord',
      'FieldCallToActionModelRecord',
      'FieldContactFormModelRecord',
      'FieldContentSectionModelRecord',
      'FieldDynamicProDirectorySectionModelRecord',
      'FieldHomePageIndustryItemModelRecord',
      'FieldMarketingTopicRecord',
      'FieldProDirectorySectionModelRecord',
      'FieldProMapSectionRecord',
      'FieldRegionPageProDirectoryRecord',
      'FieldRegionPageProMapRecord',
      'FieldSignupSectionRecord',
      'FontAwesomeIconModelRecord',
      'HomePageModelRecord',
      'HowItWorksItemModelRecord',
      'HowItWorksSectionModelRecord',
      'IndustryModelRecord',
      'IndustryOverviewPageModelRecord',
      'IndustryRegionPageRecord',
      'IndustrySectionModelRecord',
      'InspirationItemModelRecord',
      'InspirationSectionModelRecord',
      'IntentionModelRecord',
      'KnowledgeSectionModelRecord',
      'OfferingCategoryModelRecord',
      'OfferingDataModelRecord',
      'OfferingDetailPageModelRecord',
      'OfferingModelRecord',
      'OfferingPageModelRecord',
      'OfferingSectionItemModelRecord',
      'OfferingSectionModelRecord',
      'PartnerItemModelRecord',
      'PartnerSectionModelRecord',
      'PresentationSectionItemModelRecord',
      'PresentationSectionModelRecord',
      'ProDirectoryPageModelRecord',
      'ProRegisterPlanPageModelRecord',
      'PromotionBannerModelRecord',
      'QuestionModelRecord',
      'RedirectionModelRecord',
      'RegionCraftsmenPageRecord',
      'RegionRecord',
      'ReviewSectionModelRecord',
      'SeoModelRecord',
      'ServiceItemModelRecord',
      'ServiceModelRecord',
      'ServiceOverviewPageModelRecord',
      'ServicesSectionModelRecord',
      'SingleLineStringRecord',
      'SkillModelRecord',
      'StaticPageModelRecord',
      'StructuredTextAssetRecord',
      'StructuredTextPlotRecord',
      'SupportSectionModelRecord',
      'TableRecord',
      'TopicOverviewPageModelRecord',
      'VideoModelRecord',
      'VoucherPriceBasedTermModelRecord',
      'VoucherServiceFeeTermModelRecord',
    ],
    RegionCraftsmenPageModelContentField: [
      'FieldContentSectionModelRecord',
      'FieldProMapSectionRecord',
      'FieldRegionPageProDirectoryRecord',
      'FieldSignupSectionRecord',
      'KnowledgeSectionModelRecord',
      'PresentationSectionModelRecord',
      'ReviewSectionModelRecord',
      'SupportSectionModelRecord',
    ],
    SeoModelModelCanonicalPageField: [
      'ArticleModelRecord',
      'CategoryLandingPageModelRecord',
      'EmergencyLandingPageModelRecord',
      'IndustryOverviewPageModelRecord',
      'OfferingDetailPageModelRecord',
      'ServiceOverviewPageModelRecord',
      'TopicOverviewPageModelRecord',
    ],
    StaticPageModelModelContentBlocksField: ['StructuredTextAssetRecord', 'TableRecord'],
    StaticPageModelModelContentLinksField: [
      'ComponentBulletPointModelRecord',
      'ComponentButtonModelRecord',
      'FieldSignupSectionRecord',
      'PromotionBannerModelRecord',
    ],
  },
}
export default result
